import styled from 'styled-components';

import colors from '~/styles/colors';

export const Container = styled.div`
  background: ${colors.gray800};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid ${colors.gray800};
  border-radius: 4px;
  padding: 0 10px;

  /* &:hover {
    box-shadow: 0 0 1px ${colors.inputBorderHover};
    border: 1px solid ${colors.blue500};
  } */

  input {
    background: transparent;
    border: 0;
    outline: 0;
    color: ${colors.gray100};
    padding: 10px;
    flex: 1;

    transition: width 0.5s;

    /* &:focus {
      width: 600px;
    } */

    &::placeholder {
      color: ${colors.gray500};
    }
  }
`;
