import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import {
  Field,
  FieldGroup,
  FieldInlineWrapper,
} from '../../../../components/Forms';
import { AsyncCombo } from '../../../../components/AsyncCombo';
import IntegerInput from '../../../../components/Inputs/IntegerInput';
import Input from '../../../../components/Input';
import FloatInput from '../../../../components/Inputs/FloatInput';

// import { Container } from './styles';

const floatFormatter = new Intl.NumberFormat('pt-BR', { style: 'decimal' });

function TransfBonificada({ onChange, comDesagio }) {
  const [state, setState] = useState({
    data_credito_bonus: format(new Date(), 'yyyy-MM-dd'),
    ...(comDesagio ? { paridade_desagio: 1 } : { paridade_desagio: undefined }),
  });

  const paridadeStr = useMemo(() => {
    const paridade =
      state && state.paridade_desagio
        ? floatFormatter.format(state.paridade_desagio)
        : '0';

    return `(${paridade} para 1)`;
  }, [state]);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleChangeTitular = (titular_id) => {
    setState((prev) => {
      return { ...prev, titular_id };
    });
  };

  const handleChangeProgrmaOrigem = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_origem_id: id };
    });
  };

  const handleChangeProgrmaDestino = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_destino_id: id };
    });
  };

  const handleChangePontos = (pontos) => {
    setState((prev) => {
      return { ...prev, pontos };
    });
  };

  const handleChangeParidadeDesagio = (paridade_desagio) => {
    setState((prev) => {
      return { ...prev, paridade_desagio };
    });
  };

  const handleChangeBonus = (bonus) => {
    const newBonus = bonus && bonus > 0 ? bonus : undefined;

    setState((prev) => {
      return { ...prev, bonus: newBonus };
    });
  };

  const handleChangeDataCreditoBonus = (data_credito_bonus) => {
    setState((prev) => {
      return { ...prev, data_credito_bonus };
    });
  };

  return (
    <>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="titular"
            label="Titular"
            route="titulares"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeTitular}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="progrma_origem"
            label="Programa de Origem"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaOrigem}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
        <Field>
          <AsyncCombo
            name="progrma_destino"
            label="Programa de Destino"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaDestino}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="pontos"
            label="Pontos"
            value={state ? state.pontos : 0}
            onChange={handleChangePontos}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="bonus"
            label="Bônus (%)"
            maxLength={3}
            value={state ? state.bonus : 0}
            onChange={handleChangeBonus}
          />
        </Field>

        <Field flex="none">
          <Input
            name="data_credito_bonus"
            label="Data de Crédito do Bônus"
            type="date"
            autoComplete="off"
            value={state ? state.data_credito_bonus : ''}
            onChange={(e) => handleChangeDataCreditoBonus(e.target.value)}
            required
          />
        </Field>
      </FieldGroup>

      {comDesagio && (
        <FieldGroup>
          <Field flex="none">
            <label htmlFor="paridade_desagio">Paridade</label>
            <FieldInlineWrapper>
              <FloatInput
                name="paridade_desagio"
                // label="Paridade (desagio)"
                maxLength={4}
                precision={1}
                value={state ? state.paridade_desagio : 0}
                onChange={handleChangeParidadeDesagio}
              />
              <label htmlFor="paridade_desagio">
                <span style={{ padding: 8 }}>{paridadeStr}</span>
              </label>
            </FieldInlineWrapper>
          </Field>
        </FieldGroup>
      )}
    </>
  );
}

TransfBonificada.defaultProps = {
  comDesagio: false,
};

TransfBonificada.propTypes = {
  onChange: PropTypes.func.isRequired,
  comDesagio: PropTypes.bool,
};

export default TransfBonificada;
