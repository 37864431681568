import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Field, FieldGroup } from '../../../../components/Forms';
import { AsyncCombo } from '../../../../components/AsyncCombo';
import CurrencyInput from '../../../../components/Inputs/CurrencyInput';
import IntegerInput from '../../../../components/Inputs/IntegerInput';
import Input from '../../../../components/Input';

// import { Container } from './styles';

function EntradaDePontos({ onChange, showDataCreditoPontos }) {
  const [state, setState] = useState({
    custo: 0,
    data_credito_pontos: showDataCreditoPontos
      ? format(new Date(), 'yyyy-MM-dd')
      : undefined,
  });

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleChangeTitular = (titular_id) => {
    setState((prev) => {
      return { ...prev, titular_id };
    });
  };

  const handleChangeProgrmaFidel = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_id: id };
    });
  };

  const handleChangePontos = (pontos) => {
    setState((prev) => {
      return { ...prev, pontos };
    });
  };

  const handleChangeCusto = (custo) => {
    setState((prev) => {
      return { ...prev, custo };
    });
  };

  const handleChangeDataCreditoPontos = (data_credito_pontos) => {
    setState((prev) => {
      return { ...prev, data_credito_pontos };
    });
  };

  return (
    <>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="titular"
            label="Titular"
            route="titulares"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeTitular}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="progrma_fidel"
            label="Programa de Fidelidade"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaFidel}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="pontos"
            label="Pontos"
            value={state ? state.pontos : 0}
            onChange={handleChangePontos}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex="none">
          <CurrencyInput
            name="custo"
            label="Custo dos Pontos"
            value={state ? state.custo : 0}
            onChange={handleChangeCusto}
          />
        </Field>
      </FieldGroup>

      {showDataCreditoPontos ? (
        <FieldGroup>
          <Field flex="none">
            <Input
              name="data_credito_pontos"
              label="Data de Crédito dos Pontos"
              type="date"
              autoComplete="off"
              value={state ? state.data_credito_pontos : ''}
              onChange={(e) => handleChangeDataCreditoPontos(e.target.value)}
              required
            />
          </Field>
        </FieldGroup>
      ) : null}
    </>
  );
}

EntradaDePontos.defaultProps = {
  showDataCreditoPontos: false,
};

EntradaDePontos.propTypes = {
  onChange: PropTypes.func.isRequired,
  showDataCreditoPontos: PropTypes.bool,
};

export default EntradaDePontos;
