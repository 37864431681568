import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  // MdEdit,
  // MdVisibility,
  // MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
// import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import { Header } from './styles';

function PaisList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [paises, setPaises] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'nome', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadPaises(f) {
      try {
        setLoading(true);
        const response = await api.get('paises', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((pais) => {
          return {
            ...pais,
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setPaises(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'paises:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadPaises(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/paises/create');
  };

  // function reload() {
  //   setFilter(' ');
  //   setFilter('');
  // }

  // function handleEdit(id) {
  //   const cidadeToEdit = paises.find((pais) => pais.id === id);
  //   if (cidadeToEdit) {
  //     history.push({
  //       pathname: '/paises/edit',
  //       pais: cidadeToEdit,
  //     });
  //   }
  // }

  // async function handleDelete(id) {
  //   if (!window.confirm('Deseja mesmo excluir o país?')) {
  //     return;
  //   }

  //   try {
  //     await api.delete(`paises/${id}`);
  //     toast.success('País excluído com sucesso!');
  //     reload();
  //   } catch (err) {
  //     toast.error(
  //       <div>
  //         Falha ao excluir país! <br /> <br />
  //         {getErrorMessage(err)}
  //       </div>
  //     );
  //   }
  // }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Países {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, continente"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="paises:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Continente"
                showOrder={order.field === 'continente'}
                asc={order.asc}
                onClick={() => handleChangeOrder('continente')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {paises.map((pais) => (
            <tr key={pais.id}>
              <td>{pais.nome}</td>
              <td>{pais.continente}</td>
              {/* <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="paises:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(pais.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(pais.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="paises:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(pais.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && paises.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default PaisList;
