import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdDeleteForever,
  MdRefresh,
  MdInfoOutline,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import ReactTooltip from 'react-tooltip';
import { BaseContainer } from '../../../components/BaseContainer';

import {
  TooltipContainer,
  DetalhesLabel,
  DetalhesRow,
  DetalhesValor,
  Header,
  IconContainer,
  InlineContainer,
} from './styles';

import {
  currencyPrecision4ToString,
  currencyToString,
  integerToString,
} from '../../../lib/formataNumero';
import { showError, showSuccess } from '../../../lib/toastHelper';

function OperacoesList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [operacoes, setOperacoes] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'data', asc: false });
  const [refresh, setRefresh] = useState(true);

  const handleFormatarDetalhes = (operacao) => {
    if (!operacao) return undefined;

    const { data, obs, detalhes } = operacao;

    if (!detalhes) return undefined;

    const dataFormated = format(parseISO(data), 'dd/MM/yyyy');

    const legendas = {
      bonus: 'Bônus',
      pontos: 'Pontos',
      paridade_desagio: 'Paridade',
      data_credito_bonus: 'Crédito do Bônus',
      data_credito_pontos: 'Crédito dos Pontos',
      bonus_destino: 'Bônus no Destino',
      bonus_origem: 'Bônus na Origem',
      data_credito_bonus_destino: 'Crédito do Bônus no Destino',
      data_credito_bonus_origem: 'Crédito do Bônus na Origem',
      custo: 'Custo dos Pontos',
      valor_compra: 'Valor da Compra',
      qtde_cpf: 'CPFs',
      // titular_id: '',
      // programa_fidel_origem_id: '',
      // programa_fidel_destino_id: '',
    };

    const detalhesArray = Object.keys(detalhes)
      .map((key) => {
        const label = legendas[key];
        const value = detalhes[key];

        if (!label || !value) return undefined;

        let valueFormatted = value;
        switch (key) {
          case 'valor_compra':
            valueFormatted = `${currencyToString(value)}`;
            break;
          case 'bonus':
            valueFormatted = `${value}%`;
            break;
          case 'bonus_destino':
            valueFormatted = `${value}%`;
            break;
          case 'bonus_origem':
            valueFormatted = `${value}%`;
            break;
          case 'qtde_cpf':
            valueFormatted = `${integerToString(value)}`;
            break;
          case 'pontos':
            valueFormatted = `${integerToString(value)}`;
            break;
          case 'custo':
            valueFormatted = `${currencyToString(value)}`;
            break;
          case 'paridade_desagio':
            valueFormatted = `${value}:1`;
            break;
          case 'data_credito_bonus':
            valueFormatted = format(parseISO(value), 'dd/MM/yyyy');
            break;
          case 'data_credito_pontos':
            valueFormatted = format(parseISO(value), 'dd/MM/yyyy');
            break;
          case 'data_credito_bonus_destino':
            valueFormatted = format(parseISO(value), 'dd/MM/yyyy');
            break;
          case 'data_credito_bonus_origem':
            valueFormatted = format(parseISO(value), 'dd/MM/yyyy');
            break;
          default:
            break;
        }

        return (
          <DetalhesRow>
            <DetalhesLabel>{label}</DetalhesLabel>
            <DetalhesValor>{valueFormatted}</DetalhesValor>
          </DetalhesRow>
        );
      })
      .filter((key) => !!key);

    return [
      <DetalhesRow>
        <DetalhesLabel>Data</DetalhesLabel>
        <DetalhesValor>{dataFormated}</DetalhesValor>
      </DetalhesRow>,

      detalhesArray,

      <DetalhesRow>
        <DetalhesLabel>Observações</DetalhesLabel>
        <DetalhesValor>{obs}</DetalhesValor>
      </DetalhesRow>,
    ];
  };

  useEffect(() => {
    async function loadOperacoes(f) {
      try {
        setLoading(true);
        const response = await api.get('operacoes', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((operacao) => {
          return {
            ...operacao,
            dataFormatted:
              operacao.data && format(parseISO(operacao.data), 'dd/MM/yyyy'),
            custoFormatted: currencyToString(operacao.custo),
            custoUnitarioFormatted: currencyPrecision4ToString(
              operacao.custoUnitario
            ),
            custoMilheiroFormatted: currencyToString(
              operacao.custoUnitario * 1000
            ),
            detalhesFormatted: handleFormatarDetalhes(operacao),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setOperacoes(data);
        setLoading(false);
      } catch (err) {
        showError('Falha ao carregar dados!', err);
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'operacoes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadOperacoes(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/operacoes/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  // function handleEdit(id) {
  //   const movToEdit = operacoes.find((mov) => mov.id === id);
  //   if (movToEdit) {
  //     history.push({
  //       pathname: '/operacoes/edit',
  //       mov: movToEdit,
  //     });
  //   }
  // }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir a operação?')) {
      return;
    }

    try {
      await api.delete(`operacoes/${id}`);
      showSuccess('Operação excluída com sucesso!');
      reload();
    } catch (err) {
      showError('Falha ao excluir a operação!', err);
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Operações {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="observações"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="operacoes:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Data"
                showOrder={order.field === 'data'}
                asc={order.asc}
                onClick={() => handleChangeOrder('data')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Operação"
                showOrder={order.field === 'tipo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('tipo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Observações"
                showOrder={order.field === 'obs'}
                asc={order.asc}
                onClick={() => handleChangeOrder('obs')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {operacoes.map((operacao) => (
            <tr key={operacao.id}>
              <td>{operacao.dataFormatted}</td>

              <td>
                <InlineContainer>
                  {operacao.tipoNome}
                  {operacao.detalhesFormatted ? (
                    <>
                      <IconContainer>
                        <MdInfoOutline
                          color={colors.gray300}
                          size={22}
                          data-tip
                          data-for={`operacao_${operacao.id}`}
                        />
                      </IconContainer>
                      <ReactTooltip
                        id={`operacao_${operacao.id}`}
                        textColor={colors.gray100}
                        backgroundColor={colors.gray700}>
                        <TooltipContainer>
                          {operacao.detalhesFormatted}
                        </TooltipContainer>
                      </ReactTooltip>
                    </>
                  ) : null}
                </InlineContainer>
              </td>

              <td>{operacao.obs}</td>
              <td>
                <Actions>
                  {/* <Can
                    funcao={funcao}
                    perform="operacoes:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(mov.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(mov.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  /> */}

                  <Can
                    funcao={funcao}
                    perform="operacoes:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(operacao.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && operacoes.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default OperacoesList;
