import { lighten } from 'polished';
import styled from 'styled-components';
import colors from '~/styles/colors';

export const Button = styled.button`
  background: ${(props) =>
    props.primary
      ? `linear-gradient(45deg, ${colors.blue500}, ${lighten(
          0.1,
          colors.blue500
        )})`
      : `linear-gradient(45deg, ${colors.gray600}, ${lighten(
          0.1,
          colors.gray600
        )})`};

  border: 0;
  /* border-width: thin; */
  padding: ${(props) => (props.padding ? props.padding : '8px 30px')};
  /* height: 36px; */
  border-radius: 18px;
  color: ${colors.gray100};
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  svg {
    margin: ${(props) => (props.iconSpace ? props.iconSpace : '0 5px')};
  }

  transition: all 0.3s ease 0s;
  /* box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1); */

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.5 : 0.9)};
  }

  &:active {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;
