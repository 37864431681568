const classesVoo = {
  ec: {
    codigo: 'ec',
    nomeCompleto: 'Classe Econômica',
    nomeCurto: 'Econômica',
  },
  ex: {
    codigo: 'ex',
    nomeCompleto: 'Classe Executiva',
    nomeCurto: 'Executiva',
  },
  pc: {
    codigo: 'pc',
    nomeCompleto: 'Primeira Classe',
    nomeCurto: 'Primeira Classe',
  },
};

const classesVooArray = () => {
  const results = [];
  Object.keys(classesVoo).forEach((classe) => {
    results.push({
      codigo: classesVoo[classe].codigo,
      nomeCompleto: classesVoo[classe].nomeCompleto,
      nomeCurto: classesVoo[classe].nomeCurto,
    });
  });
  return results;
};

export { classesVoo, classesVooArray };
