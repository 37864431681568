import styled from 'styled-components';

export const Container = styled.div`
  /* background: lightcoral; */
  /* padding: 8px; */
  display: flex;
  flex-direction: column;
`;

export const HorizontalContainer = styled.div`
  /* background: lightseagreen; */
  /* padding: 8px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
`;
