import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

import { addDays, addWeeks, format, subWeeks } from 'date-fns';
import { IconContainer, InlineContainer, TooltipContainer } from './styles';
import colors from '../../styles/colors';

export function getSugestaoPesquisaTooltip({
  meses_divulg_voo,
  sugestaoPesquisaFormatted,
  key,
}) {
  return (
    <InlineContainer>
      {meses_divulg_voo}
      <>
        <IconContainer>
          <MdInfoOutline
            color={colors.gray300}
            size={22}
            data-tip
            data-for={`meses_divulg_voo_${key}`}
          />
        </IconContainer>
        <ReactTooltip
          id={`meses_divulg_voo_${key}`}
          textColor={colors.gray100}
          backgroundColor={colors.gray700}>
          <TooltipContainer>
            Janela de Oportunidade
            <br />
            <br />
            <strong>{sugestaoPesquisaFormatted}</strong>
          </TooltipContainer>
        </ReactTooltip>
      </>
    </InlineContainer>
  );
}

export function getSugestaoPesquisaFormatted(dias_divulg_voo) {
  const dataVoo = addDays(new Date(), dias_divulg_voo);
  const dataIni = format(subWeeks(dataVoo, 1), 'dd/MM/yyyy');
  const dataFim = format(addWeeks(dataVoo, 1), 'dd/MM/yyyy');
  const sugestao_pesquisa_voo = `${dataIni} à ${dataFim}`;
  return sugestao_pesquisa_voo;
}
