import React from 'react';
import { CellHeader, GroupRow, VerticalConteiner } from '../styles';

// import { Container } from './styles';

function EsclarecimentoDadosTimeline() {
  return (
    <GroupRow>
      <VerticalConteiner>
        <CellHeader>
          <strong>Esclarecimento sobre os dados apresentados</strong>
        </CellHeader>
        <CellHeader style={{ lineHeight: '1.2rem' }}>
          Os dados que compartilhamos aqui têm como base informações históricas
          de emissões que nossa equipe na traavley mapeou. É importante
          ressaltar que esses dados não se tratam de previsões e não podemos
          garantir que as emissões futuras ocorrerão exatamente nos mesmos
          níveis de preços ou datas. Estamos aqui para fornecer informações e
          insights, mas lembre-se de que o futuro é imprevisível.
        </CellHeader>
      </VerticalConteiner>
      <span>💙</span>
    </GroupRow>
  );
}

export default EsclarecimentoDadosTimeline;
