import styled from 'styled-components';
import { lighten } from 'polished';

import colors from '~/styles/colors';

export const Header = styled.header`
  /* margin-top: 30px; */
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NoDataText = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
`;

export const NoDataTextContainer = styled.div`
  margin-top: 30px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

/**
 * Dashboard
 */
export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;

  @media only screen and (max-width: 992px) {
    display: block;

    > div {
      margin-bottom: 16px;
    }
  }
`;

export const DashboardCard = styled.div`
  /* background: ${colors.gray100}; */
  background: ${colors.orange600};
  /* background: linear-gradient(45deg, #ff6b6b, #ffa07a); */
  /* background: linear-gradient(45deg, ${colors.orange600}, ${colors.orange500}); */
  background: linear-gradient(
    45deg,
    ${colors.orange600},
    ${lighten(0.05, colors.orange600)}
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  flex: 1;
  color: ${colors.white};
`;

export const DashboardTitle = styled.div`
  /* background: lightgreen; */
  font-size: normal;
  font-weight: normal;
  /* color: ${colors.gray800}; */
  text-align: center;
  padding: 4px;
`;

export const DashboardLabelContainer = styled.div`
  /* background: lightgreen; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DashboardLabel = styled.div`
  /* background: lightgreen; */
  font-size: small;
  font-weight: normal;
  /* color: ${colors.gray800}; */
  text-align: center;
  padding: 4px;
`;

export const DashboardValue = styled.div`
  /* background: lightcoral; */
  font-size: 1.5rem;
  font-weight: bold;
  /* color: ${colors.gray800}; */
  text-align: center;
  padding: 8px;
`;

/**
 * List
 */

export const GroupRow = styled.div`
  background: ${colors.gray800};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  /* flex: 1; */

  font-size: 1rem;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
    padding: 8px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* screen width >= 600 pixels  */
  @media only screen and (min-width: 600px) {
    font-size: 0.875rem;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  /* screen width >= 768 pixels  */
  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  /* screen width >= 992 pixels  */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* screen width >= 1200 pixels  */
  @media only screen and (min-width: 1200px) {
  }
`;

export const ResultTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0;
  margin: 3px;

  font-size: 1rem;
  font-weight: bold;
  color: ${colors.gray500};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const GroupCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0;
  margin: 3px;

  font-size: 1.5rem;
  font-weight: bold;
  color: ${colors.gray300};
  text-align: center;
`;

export const Row = styled.div`
  background: ${({ zebrado }) =>
    zebrado ? lighten(0.04, colors.gray800) : colors.gray800};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 4px;
  border-radius: 16px;
`;

export const CellHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 3px;

  color: ${colors.gray500};
  font-weight: normal;
  font-size: 0.875rem;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
`;

export const Cell = styled.div`
  /* background: grey; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 3px;
  color: ${colors.gray300};
  text-align: center;
`;

export const HorizontalConteiner = styled.div`
  /* background: blue; */
  padding: 3px;
  margin: 3px;
  flex: 1;
`;

export const VerticalConteiner = styled.div`
  /* background: lightblue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  /* padding: 3px; */
  /* margin: 3px; */
  flex: 1;
`;

export const Text = styled.div`
  font-weight: normal;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
`;

export const PreventSelectionContainer = styled.div`
  // Impede que o usuário selecione o texto
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
