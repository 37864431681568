import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Field, FieldGroup } from '../../../../components/Forms';
import { AsyncCombo } from '../../../../components/AsyncCombo';
import Input from '../../../../components/Input';
import { LineSeparator } from '../styles';
import CurrencyInput from '../../../../components/Inputs/CurrencyInput';
import IntegerInput from '../../../../components/Inputs/IntegerInput';

// import { Container } from './styles';

function CompraEmParceiro({ onChange }) {
  const [state, setState] = useState({
    data_credito_pontos: format(new Date(), 'yyyy-MM-dd'),
    custo: 0,
  });

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleChangeTitular = (titular_id) => {
    setState((prev) => {
      return { ...prev, titular_id };
    });
  };

  const handleChangeProgrmaFidel = (programa_fidel_id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_id };
    });
  };

  const handleChangeParceiro = (parceiro_id) => {
    setState((prev) => {
      return { ...prev, parceiro_id };
    });
  };

  const handleChangeValorCompra = (valor_compra) => {
    setState((prev) => {
      return { ...prev, valor_compra };
    });
  };

  const handleChangePontos = (pontos) => {
    setState((prev) => {
      return { ...prev, pontos };
    });
  };

  const handleChangeCusto = (custo) => {
    setState((prev) => {
      return { ...prev, custo };
    });
  };

  const handleChangeDataCreditoPontos = (data_credito_pontos) => {
    setState((prev) => {
      return { ...prev, data_credito_pontos };
    });
  };

  return (
    <>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="parceiro"
            label="Parceiro"
            route="parceiros"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeParceiro}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
        <Field flex="none">
          <CurrencyInput
            name="valor_compra"
            label="Valor da Compra"
            value={state ? state.valor_compra : 0}
            onChange={handleChangeValorCompra}
          />
        </Field>
      </FieldGroup>

      <LineSeparator />

      <FieldGroup>
        <Field>
          <AsyncCombo
            name="titular"
            label="Titular"
            route="titulares"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeTitular}
          />
        </Field>
        <Field>
          <AsyncCombo
            name="progrma_fidel"
            label="Programa de Fidelidade"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaFidel}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="pontos"
            label="Pontos"
            value={state ? state.pontos : 0}
            onChange={handleChangePontos}
          />
        </Field>

        <Field flex="none">
          <Input
            name="data_credito_pontos"
            label="Data de Crédito dos Pontos"
            type="date"
            autoComplete="off"
            value={state ? state.data_credito_pontos : ''}
            onChange={(e) => handleChangeDataCreditoPontos(e.target.value)}
            required
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <CurrencyInput
            name="custo"
            label="Custo dos Pontos"
            value={state ? state.custo : 0}
            onChange={handleChangeCusto}
          />
        </Field>
      </FieldGroup>
    </>
  );
}

// CompraEmParceiro.defaultProps = {
//   placeholder: '',
//   idField: 'id',
//   isClearable: false,
//   labelField: 'nome',
//   defaultValue: undefined,
// };

CompraEmParceiro.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CompraEmParceiro;
