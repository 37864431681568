import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* screen width >= 600 pixels  */
  /* @media only screen and (min-width: 600px) {
  } */

  /* Medium devices (landscape tablets, 768px and up) */
  /* screen width >= 768 pixels  */
  /* @media only screen and (min-width: 768px) {
  } */

  /* Large devices (laptops/desktops, 992px and up) */
  /* screen width >= 992 pixels  */
  /* @media only screen and (min-width: 992px) {
  } */

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* screen width >= 1200 pixels  */
  /* @media only screen and (min-width: 1200px) {
  } */
`;

export const PageLimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const SelectContainer = styled.div`
  width: 80px;
`;

export const PageNavigateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const PageNavigateButton = styled.button`
  background: none;
  border: 0;
`;

export const PageNavigateText = styled.span`
  padding: 0 10px;
  color: ${colors.gray300};
`;
