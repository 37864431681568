import { lighten } from 'polished';
import styled from 'styled-components';
import colors from '~/styles/colors';

export const Wrapper = styled.div`
  min-height: 100%;
  /* background: ${colors.gray900}; */
  background: linear-gradient(
    180deg,
    ${lighten(0.02, colors.gray900)},
    ${colors.gray900}
  );
`;
