import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Field, FieldGroup } from '../../../../components/Forms';
import { AsyncCombo } from '../../../../components/AsyncCombo';
import IntegerInput from '../../../../components/Inputs/IntegerInput';
import Input from '../../../../components/Input';

// import { Container } from './styles';

function TransfBumerangue({ onChange }) {
  const [state, setState] = useState({
    data_credito_bonus_origem: format(new Date(), 'yyyy-MM-dd'),
    data_credito_bonus_destino: format(new Date(), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleChangeTitular = (titular_id) => {
    setState((prev) => {
      return { ...prev, titular_id };
    });
  };

  const handleChangeProgrmaOrigem = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_origem_id: id };
    });
  };

  const handleChangeProgrmaDestino = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_destino_id: id };
    });
  };

  const handleChangePontos = (pontos) => {
    setState((prev) => {
      return { ...prev, pontos };
    });
  };

  const handleChangeBonusOrigem = (bonus_origem) => {
    const bonusOrigem =
      bonus_origem && bonus_origem > 0 ? bonus_origem : undefined;

    setState((prev) => {
      return { ...prev, bonus_origem: bonusOrigem };
    });
  };

  const handleChangeBonusDestino = (bonus_destino) => {
    const bonusDestino =
      bonus_destino && bonus_destino > 0 ? bonus_destino : undefined;

    setState((prev) => {
      return { ...prev, bonus_destino: bonusDestino };
    });
  };

  const handleChangeDataCreditoBonusDestino = (data_credito_bonus_destino) => {
    setState((prev) => {
      return { ...prev, data_credito_bonus_destino };
    });
  };

  const handleChangeDataCreditoBonusOrigem = (data_credito_bonus_origem) => {
    setState((prev) => {
      return { ...prev, data_credito_bonus_origem };
    });
  };

  return (
    <>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="titular"
            label="Titular"
            route="titulares"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeTitular}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="progrma_origem"
            label="Programa de Origem"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaOrigem}
          />
        </Field>
        <Field>
          <AsyncCombo
            name="progrma_destino"
            label="Programa de Destino"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaDestino}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="pontos"
            label="Pontos"
            value={state && state.pontos}
            onChange={handleChangePontos}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="bonus_destino"
            label="Bônus no Destino (%)"
            maxLength={3}
            value={state ? state.bonus_destino : 0}
            onChange={handleChangeBonusDestino}
          />
        </Field>
        <Field flex="none">
          <Input
            name="data_credito_bonus_destino"
            label="Data de Crédito do Bônus no Destino"
            type="date"
            autoComplete="off"
            value={state ? state.data_credito_bonus_destino : ''}
            onChange={(e) =>
              handleChangeDataCreditoBonusDestino(e.target.value)
            }
            required
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="bonus_origem"
            label="Bônus na Origem (%)"
            maxLength={3}
            value={state ? state.bonus_origem : 0}
            onChange={handleChangeBonusOrigem}
          />
        </Field>
        <Field flex="none">
          <Input
            name="data_credito_bonus_origem"
            label="Data de Crédito do Bônus na Origem"
            type="date"
            autoComplete="off"
            value={state ? state.data_credito_bonus_origem : ''}
            onChange={(e) => handleChangeDataCreditoBonusOrigem(e.target.value)}
            required
          />
        </Field>
      </FieldGroup>
    </>
  );
}

// TransfBumerangue.defaultProps = {
//   placeholder: '',
//   idField: 'id',
//   isClearable: false,
//   labelField: 'nome',
//   defaultValue: undefined,
// };

TransfBumerangue.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TransfBumerangue;
