import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  /* background: lightcoral; */
  h3 {
    color: ${colors.gray400};
    font-weight: normal;
    font-size: medium;
    text-transform: uppercase;
  }
`;

export const LinkContainer = styled.div`
  padding: 10px;
  margin-top: 10px;

  a {
    color: ${colors.gray500};
  }
`;
