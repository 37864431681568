import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import Checkbox from '../../../components/Checkbox';

// import { } from './styles';

function ParceiroForm({ location }) {
  const [parceiro] = useState(location.parceiro);
  const isEditing = history.location.pathname === '/parceiros/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'parceiros:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !parceiro) {
      history.goBack();
    }
  }, [parceiro, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ nome, ativo }) {
    const payload = { nome, ativo };

    try {
      if (parceiro) {
        await api.put(`parceiros/${parceiro.id}`, payload); // Edit
      } else {
        await api.post('parceiros', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/parceiros');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ nome, ativo }) => {
    if (!isEditing && !checkPermission(funcao, 'parceiros:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'parceiros:edit')) {
      return;
    }

    save({ nome, ativo });
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: parceiro && parceiro.nome,
            ativo: parceiro && parceiro.ativo,
          }}>
          <FormHeader>
            <Title>Cadastro de Parceiro</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'parceiros:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="nome">Nome</label>
              <Input
                name="nome"
                type="text"
                maxLength="100"
                autoComplete="off"
                autoFocus
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <Checkbox name="ativo" label="Ativo" />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

ParceiroForm.propTypes = {
  location: PropTypes.shape(),
};

ParceiroForm.defaultProps = {
  location: null,
};

export default ParceiroForm;
