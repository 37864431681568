import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function ActiveInactiveStatus({ active }) {
  return (
    <Container active={active}>
      <span />
      {active ? 'ATIVO' : 'INATIVO'}
    </Container>
  );
}

ActiveInactiveStatus.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default ActiveInactiveStatus;
