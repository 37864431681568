import colors from '../styles/colors';

const borderRadius = 8;

const theme = (theme) => ({
  ...theme,
  background: colors.gray700,
  colors: {
    ...theme.colors,
    primary: colors.blue500, // menu > selected option bg-color
    // primary75: colors.,
    primary50: colors.gray400, // option mouseDown: color
    primary25: colors.gray500, // menu > option hover: bg-color
    danger: colors.red500,
    dangerLight: colors.red500,
    neutral0: colors.gray300, // menu > selected opction color
    // neutral5: colors.red500,
    // neutral10: colors.red500,
    neutral20: colors.gray800,
    neutral30: colors.gray700, // control > hover border color
    neutral40: colors.gray700, // control > hover menu indicator color
    // neutral50: colors.,
    neutral60: colors.gray300, // defaultOption > active: dropdown indicator color
    // neutral70: colors.,
    neutral80: colors.gray300, // defaultOption > color
    // neutral90: colors.,
  },
});

const styles = {
  control: (base) => ({
    ...base,
    background: colors.gray700,
    borderRadius,
    // borderColor: colors.gray700,
  }),

  option: (styles) => {
    return {
      ...styles,
      color: colors.gray300,
    };
  },

  singleValue: (styles) => {
    return {
      ...styles,
      // color: colors.gray100,
    };
  },

  menu: (styles) => ({
    ...styles,
    backgroundColor: colors.gray700,
    padding: 0,
    borderRadius,
  }),

  menuList: (styles) => ({
    ...styles,
    padding: 0,
    borderRadius,
  }),
};

export { theme, styles };
