import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import api from '~/services/api';
import { theme, styles } from '../../config/reactSelect';
import colors from '../../styles/colors';

// import { Container } from './styles';

function AsyncCombo({
  name,
  label,
  disabled,
  route,
  placeholder,
  idField,
  labelField,
  isClearable,
  defaultValue,
  onChange,
  params,
}) {
  const timer = useRef(null);
  // const [value, setValue] = useState();

  /**
   * Async Select
   */
  async function loadData(filterText) {
    const response = await api.get(route, {
      params: { q: filterText, ...(params && { ...params }) },
    });

    const items = response.data.map((item) => {
      // return { value: item.id, label: item.nome };
      return { value: item[idField], label: item[labelField] };
    });

    return items;
  }

  const loadOptions = (inputValue, callback) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(async () => {
      callback(await loadData(inputValue));
    }, 600);
  };

  const handleChange = (selectedOptions) => {
    const newValue = selectedOptions ? selectedOptions.value : undefined;

    if (onChange) {
      onChange(newValue);
    }

    // setValue(newValue);
  };

  return (
    <>
      {label ? (
        <label htmlFor={name} style={disabled ? { color: colors.gray500 } : {}}>
          {label}
        </label>
      ) : null}

      <AsyncSelect
        // ref={refCiaAerea}
        isClearable={isClearable}
        name={name}
        placeholder={placeholder}
        cacheOptions
        loadOptions={route ? loadOptions : undefined}
        defaultOptions
        defaultValue={defaultValue}
        onChange={handleChange}
        theme={theme}
        styles={styles}
        isDisabled={disabled}
      />
    </>
  );
}

AsyncCombo.defaultProps = {
  label: '',
  disabled: false,
  placeholder: '',
  idField: 'id',
  isClearable: false,
  labelField: 'nome',
  defaultValue: undefined,
  params: undefined,
};

AsyncCombo.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  route: PropTypes.string.isRequired,
  idField: PropTypes.string,
  labelField: PropTypes.string,
  isClearable: PropTypes.bool,
  defaultValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  params: PropTypes.shape(),
};

export { AsyncCombo };
