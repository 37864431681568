const permissoes = {
  master: {
    static: [
      'timeline:visit',
      'curadoria:visit',
      'cadastros:visit',

      'usuarios:visit',
      'usuarios:create',
      'usuarios:edit',
      'usuarios:delete',

      'paises:visit',

      'regioes:visit',
      'regioes:create',
      'regioes:edit',
      'regioes:delete',

      'estados:visit',
      'estados:create',
      'estados:edit',
      'estados:delete',

      'cidades:visit',
      'cidades:create',
      'cidades:edit',
      'cidades:delete',

      'aeroportos:visit',
      'aeroportos:create',
      'aeroportos:edit',
      'aeroportos:delete',

      'cias-aereas:visit',
      'cias-aereas:create',
      'cias-aereas:edit',
      'cias-aereas:delete',

      'programas-fidel:visit',
      'programas-fidel:create',
      'programas-fidel:edit',
      'programas-fidel:delete',

      'emissoes:visit',
      'emissoes:create',
      'emissoes:edit',
      'emissoes:delete',

      'parceiros:visit',
      'parceiros:create',
      'parceiros:edit',
      'parceiros:delete',

      // Multitenance
      'titulares:visit',
      'titulares:create',
      'titulares:edit',
      'titulares:delete',

      'pontos-mov:visit',
      'pontos-mov:create',
      'pontos-mov:edit',
      'pontos-mov:delete',

      'pontos-saldo:visit',
      'pontos-saldo:create',
      'pontos-saldo:edit',
      'pontos-saldo:delete',

      'operacoes:visit',
      'operacoes:create',
      'operacoes:edit',
      'operacoes:delete',

      'promocoes:visit',
      'promocoes:create',
      'promocoes:edit',
      'promocoes:delete',
    ],
    dynamic: {},
  },

  admin: {
    static: [
      'timeline:visit',
      'curadoria:visit',
      'cadastros:visit',

      'usuarios:visit',
      'usuarios:create',
      'usuarios:edit',
      'usuarios:delete',

      'paises:visit',

      'regioes:visit',
      'regioes:create',

      'estados:visit',
      'estados:create',

      'cidades:visit',
      'cidades:create',

      'aeroportos:visit',
      'aeroportos:create',

      'cias-aereas:visit',
      'cias-aereas:create',

      'programas-fidel:visit',
      'programas-fidel:create',

      'emissoes:visit',
      'emissoes:create',
      'emissoes:edit',
      'emissoes:delete',

      'parceiros:visit',
      'parceiros:create',

      // Multitenance
      'titulares:visit',
      'titulares:create',
      'titulares:edit',
      'titulares:delete',

      'pontos-mov:visit',
      'pontos-mov:create',
      'pontos-mov:edit',
      'pontos-mov:delete',

      'pontos-saldo:visit',
      'pontos-saldo:create',
      'pontos-saldo:edit',
      'pontos-saldo:delete',

      'operacoes:visit',
      'operacoes:create',
      'operacoes:edit',
      'operacoes:delete',

      'promocoes:visit',
      'promocoes:create',
      'promocoes:edit',
      'promocoes:delete',
    ],
    dynamic: {},
  },

  integracao: {
    static: [
      'usuarios:visit',
      'usuarios:create',
      'usuarios:edit',
      'usuarios:delete',
    ],
    dynamic: {},
  },

  assinante: {
    static: [
      'timeline:visit',
      'curadoria:visit',
      'promocoes:visit',

      'paises:visit',
      'regioes:visit',
      'estados:visit',
      'cidades:visit',
      'aeroportos:visit',
      'cias-aereas:visit',
      'programas-fidel:visit',
      'emissoes:visit',
      'parceiros:visit',

      // Multitenance
      'titulares:visit',
      'titulares:create',
      'titulares:edit',
      'titulares:delete',

      'pontos-mov:visit',
      'pontos-mov:create',
      'pontos-mov:edit',
      'pontos-mov:delete',

      'pontos-saldo:visit',
      'pontos-saldo:create',
      'pontos-saldo:edit',
      'pontos-saldo:delete',

      'operacoes:visit',
      'operacoes:create',
      'operacoes:edit',
      'operacoes:delete',
    ],
    dynamic: {},
  },
};

export default permissoes;
