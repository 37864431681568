import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { MdDone, MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import { showError, showSuccess } from '~/lib/toastHelper';
import { MainContainer } from '../../../components/MainContainer';

// import { } from './styles';

function TitularForm({ location }) {
  const [titular] = useState(location.titular);
  const isEditing = history.location.pathname === '/titulares/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'titulares:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !titular) {
      history.goBack();
    }
  }, [titular, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ nome }) {
    const payload = { nome };

    try {
      if (titular) {
        await api.put(`titulares/${titular.id}`, payload);
      } else {
        await api.post('titulares', payload);
      }

      showSuccess(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      history.push('/titulares');
    } catch (err) {
      showError('Falha ao salvar!', err);
    }
  }

  const handleSubmit = ({ nome }) => {
    if (!isEditing && !checkPermission(funcao, 'titulares:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'titulares:edit')) {
      return;
    }

    save({ nome });
  };

  return (
    <MainContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: titular && titular.nome,
            codigo_iata: titular && titular.codigo_iata,
          }}>
          <FormHeader>
            <Title>Cadastro de Titular</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'titulares:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <Input
                name="nome"
                label="Nome"
                type="text"
                maxLength="100"
                autoComplete="off"
                autoFocus
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </MainContainer>
  );
}

TitularForm.propTypes = {
  location: PropTypes.shape(),
};

TitularForm.defaultProps = {
  location: null,
};

export default TitularForm;
