import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import colors from '../../styles/colors';

import { HorizontalContainer } from './styles';

function Input({ name, label, disabled, infoTooltip, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label ? (
        <HorizontalContainer>
          <label
            htmlFor={fieldName}
            style={disabled ? { color: colors.gray500 } : {}}>
            {label}
          </label>
          {infoTooltip}
        </HorizontalContainer>
      ) : null}

      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        style={disabled ? { color: colors.gray500 } : {}}
        disabled={disabled}
        {...rest}
      />

      {error && <span className="error">{error}</span>}
    </>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  infoTooltip: PropTypes.element,
};

Input.defaultProps = {
  label: '',
  disabled: false,
  infoTooltip: null,
};

export default Input;
