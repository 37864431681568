import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { theme, styles } from '../../../config/reactSelect';

// import { } from './styles';

function EstadoForm({ location }) {
  const [estado] = useState(location.estado);
  const timerPais = useRef(null);
  const timerRegiao = useRef(null);

  const [paisId, setPaisId] = useState(estado && estado.pais_id);
  const [regiaoId, setRegiaoId] = useState(estado && estado.regiao_id);

  const isEditing = history.location.pathname === '/estados/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'estados:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !estado) {
      history.goBack();
    }
  }, [estado, isEditing, denied]);

  /**
   * Async Select - Países
   */
  async function loadPaises(filter) {
    const response = await api.get('paises', {
      params: { q: filter },
    });

    const paises = response.data.map((pais) => {
      return { value: pais.id, label: pais.nome };
    });

    return paises;
  }

  const loadPaisOptions = (inputValue, callback) => {
    clearTimeout(timerPais.current);
    timerPais.current = setTimeout(async () => {
      callback(await loadPaises(inputValue));
    }, 600);
  };

  const handleChangePais = (selectedOptions) => {
    setPaisId(selectedOptions ? selectedOptions.value : null);
  };

  /**
   * Async Select - Regiões
   */
  async function loadRegioes(filter) {
    const response = await api.get('regioes', {
      params: { q: filter },
    });

    const regioes = response.data.map((regiao) => {
      return { value: regiao.id, label: regiao.nome };
    });

    return regioes;
  }

  const loadRegiaoOptions = (inputValue, callback) => {
    clearTimeout(timerRegiao.current);
    timerRegiao.current = setTimeout(async () => {
      callback(await loadRegioes(inputValue));
    }, 600);
  };

  const handleChangeRegiao = (selectedOptions) => {
    setRegiaoId(selectedOptions ? selectedOptions.value : null);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ pais_id, regiao_id, nome, sigla }) {
    const payload = { pais_id, regiao_id, nome, sigla };

    try {
      if (estado) {
        await api.put(`estados/${estado.id}`, payload); // Edit
      } else {
        await api.post('estados', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/estados');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ nome, sigla }) => {
    if (!isEditing && !checkPermission(funcao, 'estados:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'estados:edit')) {
      return;
    }

    save({ pais_id: paisId, regiao_id: regiaoId, nome, sigla });
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: estado && estado.nome,
            sigla: estado && estado.sigla,
          }}>
          <FormHeader>
            <Title>Cadastro de Estado</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'estados:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="pais">País</label>
              <AsyncSelect
                autoFocus
                isClearable
                name="pais"
                placeholder="Selecione o país..."
                cacheOptions
                loadOptions={loadPaisOptions}
                defaultOptions
                onChange={handleChangePais}
                defaultValue={
                  estado &&
                  estado.pais && {
                    value: estado.pais.id,
                    label: estado.pais.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="pais">Região</label>
              <AsyncSelect
                isClearable
                name="regiao"
                placeholder="Selecione a região..."
                cacheOptions
                loadOptions={loadRegiaoOptions}
                defaultOptions
                onChange={handleChangeRegiao}
                defaultValue={
                  estado &&
                  estado.regiao && {
                    value: estado.regiao.id,
                    label: estado.regiao.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="nome">Nome</label>
              <Input
                name="nome"
                type="text"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <label htmlFor="sigla">Sigla</label>
              <Input
                name="sigla"
                type="text"
                maxLength="3"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

EstadoForm.propTypes = {
  location: PropTypes.shape(),
};

EstadoForm.defaultProps = {
  location: null,
};

export default EstadoForm;
