import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import Input from '~/components/Input';
import Checkbox from '~/components/Checkbox';

import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import {
  FormWrapper,
  FieldGroup,
  Field,
  FieldInlineWrapper,
} from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import { showError, showSuccess } from '../../../lib/toastHelper';
import { theme, styles } from '../../../config/reactSelect';

// import { } from './styles';

function UsuarioForm({ location }) {
  const time = useRef(null);
  const [usuario] = useState(location.usuario);
  const [selectedFuncao, setSelectedFuncao] = useState(
    usuario && usuario.funcao
  );
  const isEditing = history.location.pathname === '/usuarios/edit';
  const [showPass, setShowPass] = useState(false);

  /**
   * Permissions
   */
  const funcaoUsuario = useSelector((state) => state.funcao.funcao);
  const denied =
    !isEditing && !checkPermission(funcaoUsuario, 'usuarios:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !usuario) {
      history.goBack();
    }
  }, [usuario, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(nome, email, funcao, senha, ativo) {
    const payload = {
      nome,
      email,
      funcao,
      senha,
      ativo,
    };

    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Por favor, informe o nome do usuário.'),
        email: Yup.string().required('Por favor, informe o e-mail do usuário.'),
      });
      try {
        await schema.validate(payload, { abortEarly: false });
      } catch (err) {
        return showError('Falha ao salvar', err);
      }

      if (usuario) {
        await api.put(`usuarios/${usuario.id}`, payload); // Edit
      } else {
        await api.post('usuarios', payload); // Create
      }

      showSuccess(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/usuarios');
    } catch (err) {
      return showError('Falha ao salvar!', err);
    }
  }

  const handleSubmit = ({ nome, email, senha, ativo }) => {
    if (!isEditing && !checkPermission(funcaoUsuario, 'usuarios:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcaoUsuario, 'usuarios:edit')) {
      return;
    }

    save(nome, email, selectedFuncao, senha, ativo);
  };

  /**
   * Async Select
   */
  async function loadFuncoes(filter) {
    const response = await api.get('usuarios/funcoes', {
      params: { q: filter },
    });

    const funcoes = response.data.map((funcao) => {
      return { value: funcao, label: funcao };
    });

    return funcoes;
  }

  const loadFuncaoOptions = (inputValue, callback) => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadFuncoes(inputValue));
    }, 600);
  };

  const handleChangeFuncao = (selectedOptions) => {
    setSelectedFuncao(selectedOptions.value);
  };

  return (
    <>
      <BaseContainer>
        <FormWrapper>
          <Form
            onSubmit={handleSubmit}
            initialData={{
              nome: usuario && usuario.nome,
              email: usuario && usuario.email,
              funcao: usuario && usuario.funcao,
              ativo: usuario ? usuario.ativo : true,
            }}>
            <FormHeader>
              <Title>Cadastro de Usuários</Title>
              <div>
                <Button type="button" onClick={handleClickBack}>
                  <MdArrowBack size={24} color={colors.iconLight} />
                  <span>Voltar</span>
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={
                    isEditing &&
                    !checkPermission(funcaoUsuario, 'usuarios:edit')
                  }>
                  <MdDone size={24} color={colors.iconLight} />
                  <span>Salvar</span>
                </Button>
              </div>
            </FormHeader>

            <FieldGroup>
              <Field>
                <label htmlFor="nome">Nome</label>
                <Input
                  name="nome"
                  type="text"
                  maxLength="100"
                  autoFocus
                  autoComplete="off"
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <label htmlFor="email">E-Mail</label>
                <Input
                  name="email"
                  type="email"
                  maxLength="100"
                  autoComplete="off"
                  disabled={isEditing}
                />
              </Field>
            </FieldGroup>

            {!isEditing && (
              <FieldGroup>
                <Field>
                  <label htmlFor="senha">Senha</label>
                  <FieldInlineWrapper>
                    <Input
                      style={{ flex: 1 }}
                      name="senha"
                      type={showPass ? 'text' : 'password'}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <Button
                      style={{ paddingLeft: 10, paddingRight: 5 }}
                      type="button"
                      onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <MdVisibility size={18} color={colors.iconLight} />
                      ) : (
                        <MdVisibilityOff size={18} color={colors.iconLight} />
                      )}
                    </Button>
                  </FieldInlineWrapper>
                  <label htmlFor="senha">
                    <small>
                      * No mínimo 8 caracteres, pelo menos 1 letra maiúscula, 1
                      letra minúscula, 1 número e 1 caractere especial
                    </small>
                  </label>
                </Field>
              </FieldGroup>
            )}

            <FieldGroup>
              <Field>
                <label htmlFor="funcao">Perfil:</label>
                <AsyncSelect
                  name="funcao"
                  placeholder="Selecione um perfil..."
                  cacheOptions
                  loadOptions={loadFuncaoOptions}
                  defaultOptions
                  onChange={handleChangeFuncao}
                  defaultValue={
                    usuario && {
                      value: usuario.funcao,
                      label: usuario.funcao,
                    }
                  }
                  required
                  theme={theme}
                  styles={styles}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field flex="none">
                <Checkbox name="ativo" label="Ativo" />
              </Field>
            </FieldGroup>
          </Form>
        </FormWrapper>

        {/* <Field>
          <Tabs>
            <TabList>
              <Tab>Histórico</Tab>
            </TabList>

            <TabPanel>
              {usuario && (
                <HistoryList
                  id={usuario.id}
                  route="usuarios"
                  maxWidthPercent={100}
                />
              )}
            </TabPanel>
          </Tabs>
        </Field> */}
      </BaseContainer>
    </>
  );
}

UsuarioForm.propTypes = {
  location: PropTypes.shape(),
};

UsuarioForm.defaultProps = {
  location: null,
};

export default UsuarioForm;
