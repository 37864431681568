import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import Input from '~/components/Input';
import { Button } from '~/components/Button';

import Logo from '~/components/Logo';
import { signInRequest } from '~/store/modules/auth/actions';
import { Container, LinkContainer } from './syles';
// import logo from '~/assets/logo.png';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  senha: Yup.string().required('A senha é obrigatória'),
});

function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const handleSubmit = ({ email, senha }) => {
    dispatch(signInRequest(email, senha));
  };

  return (
    <Container>
      {/* <img src={logo} alt="traavley" /> */}
      <Logo />
      <h3>Timeline</h3>
      <Form schema={schema} onSubmit={handleSubmit}>
        <label htmlFor="email">SEU E-MAIL</label>
        <Input name="email" type="email" placeholder="exemplo@e-mail.com" />

        <label htmlFor="senha">SUA SENHA</label>
        <Input name="senha" type="password" placeholder="***************" />

        <Button primary type="submit" style={{ marginLeft: 0, marginTop: 8 }}>
          {loading ? 'Carregando...' : 'Entrar'}
        </Button>
      </Form>
      <LinkContainer>
        <Link to="/reset-password">Esqueci minha senha</Link>
      </LinkContainer>
    </Container>
  );
}

export default SignIn;
