import styled from 'styled-components';
import colors from '~/styles/colors';

export const NoInfoToShow = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;

  padding: 50px 20px;
`;
