import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
  MdFileUpload,
} from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';
import { integerToString } from '~/lib/formataNumero';

import { FieldGroup } from '~/components/Forms';
import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import { Header } from './styles';
import ImportCSVModal from '../../../components/ImportCSVModal';
import Card from '../../../components/Card';

function PromocaoList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [promocoes, setPromocoes] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'data', asc: false });
  const [refresh, setRefresh] = useState(true);

  const [isShowingCSVModal, setIsShowingCSVModal] = useState(false);

  useEffect(() => {
    async function loadPromocoes(f) {
      try {
        setLoading(true);
        const response = await api.get('promocoes', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((promocao) => {
          return {
            ...promocao,
            dataFormatada:
              promocao.data && format(parseISO(promocao.data), 'dd/MM/yyyy'),
            pontosGeralFormatado: integerToString(promocao.pontos_geral),
            pontosClubeFormatado: integerToString(promocao.pontos_clube),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setPromocoes(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'promocoes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadPromocoes(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/promocoes/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleEdit(id) {
    const promocaoToEdit = promocoes.find((promocao) => promocao.id === id);
    if (promocaoToEdit) {
      history.push({
        pathname: '/promocoes/edit',
        promocao: promocaoToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir a promoção?')) {
      return;
    }

    try {
      await api.delete(`promocoes/${id}`);
      toast.success('Promoção excluída com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao excluir promoção! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  const onCloseCSVModal = () => {
    setIsShowingCSVModal(false);
    handleRefresh();
  };

  return (
    <BaseContainer>
      <Title>Promoções {total > 0 && `(${total})`}</Title>

      <ImportCSVModal
        isShowing={isShowingCSVModal}
        onClose={onCloseCSVModal}
        route="promocoes/import-csv"
        csvHeaders="Programa de Fidelidade,Parceiro,Data,Assinantes Clube,Demais Clientes"
      />

      <Header>
        <SearchInput
          placeholder="programa, parceiro"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="promocoes:edit"
            yes={() => (
              <Card dropAlign="right">
                <Can
                  funcao={funcao}
                  perform="promocoes:edit"
                  yes={() => (
                    <li>
                      <MdFileUpload size={20} color={colors.color_2} />
                      <button
                        type="button"
                        onClick={() => setIsShowingCSVModal(true)}>
                        Importar arquivo .CSV
                      </button>
                    </li>
                  )}
                />
              </Card>
            )}
          />

          <Can
            funcao={funcao}
            perform="promocoes:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>

      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Data"
                showOrder={order.field === 'data'}
                asc={order.asc}
                onClick={() => handleChangeOrder('data')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Programa Fidelidade"
                showOrder={order.field === 'programa_fidel.nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('programa_fidel.nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Parceiro"
                showOrder={order.field === 'parceiro.nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('parceiro.nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Pontos Assinantes Clube"
                showOrder={order.field === 'pontos_clube'}
                asc={order.asc}
                onClick={() => handleChangeOrder('pontos_clube')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Pontos Demais Clientes"
                showOrder={order.field === 'pontos'}
                asc={order.asc}
                onClick={() => handleChangeOrder('pontos')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {promocoes.map((promocao) => (
            <tr key={promocao.id}>
              <td>{promocao.dataFormatada}</td>
              <td>{promocao.programa_fidel && promocao.programa_fidel.nome}</td>
              <td>{promocao.parceiro && promocao.parceiro.nome}</td>
              <td>{promocao.pontosClubeFormatado}</td>
              <td>{promocao.pontosGeralFormatado}</td>
              <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="promocoes:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(promocao.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(promocao.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="promocoes:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(promocao.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && promocoes.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default PromocaoList;
