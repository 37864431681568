import React from 'react';
import { House, Share, Calculator, Buildings } from '@phosphor-icons/react';

import history from '~/services/history';

import { Button } from '~/components/Button';
import colors from '~/styles/colors';
import { MainContainer } from '../../../components/MainContainer';

import {
  VisionContainer,
  VisionTitle,
  VisionLegend,
  RowContainer,
  TitleContainer,
  LegendContainer,
  IconContainer,
  ButtonContainer,
  PageTitle,
  Grid,
  Subtitle,
} from './styles';

function TimelineDashboard() {
  const handleNavigate = (route) => {
    history.push(route);
  };

  const renderVision = ({ icon, title, legend, route }) => {
    return (
      <VisionContainer>
        <IconContainer>{icon}</IconContainer>
        <TitleContainer>
          <VisionTitle>{title}</VisionTitle>
        </TitleContainer>
        <LegendContainer>
          <VisionLegend>{legend}</VisionLegend>
        </LegendContainer>
        <ButtonContainer>
          <Button primary onClick={() => handleNavigate(route)}>
            Acessar
          </Button>
        </ButtonContainer>
      </VisionContainer>
    );
  };

  return (
    <MainContainer>
      <RowContainer>
        <PageTitle>
          Bem-vindo ao <strong>Timeline</strong>!
        </PageTitle>
      </RowContainer>
      <RowContainer>
        <Subtitle>
          Confira as análises e descubra os padrões das melhores emissões.
        </Subtitle>
      </RowContainer>

      <Grid>
        {renderVision({
          icon: <House color={colors.orange500} weight="thin" size={42} />,
          title: 'Por Origem',
          legend:
            'Compare diferentes origens e descubra quantas milhas estão sendo praticadas em média.',
          route: '/timeline/por-origem',
        })}

        {renderVision({
          icon: <Share color={colors.orange500} weight="thin" size={42} />,
          title: 'Por Destino',
          legend:
            'Compare diferentes destinos e descubra quantas milhas estão sendo praticadas em média.',
          route: '/timeline/por-destino',
        })}

        {renderVision({
          icon: <Buildings color={colors.orange500} weight="thin" size={42} />,
          title: 'Por Programa de Fidelidade',
          legend:
            'Descubra quanto cada programa de fidelidade tem praticado para diferentes destinos.',
          route: '/timeline/por-programa-fidel',
        })}

        {renderVision({
          icon: <Calculator color={colors.orange500} weight="thin" size={42} />,
          title: 'Saldo de Oportunidades',
          legend:
            'Saiba quantas milhas você deve ter em caixa para aproveitar as melhores oportunidades!',
          route: '/timeline/por-saldo-oportunidade',
        })}
      </Grid>

      <RowContainer>
        <Subtitle>
          Ainda não sabe como funciona? Assista o <a href="/ajuda">tutorial</a>{' '}
          que preparamos.
        </Subtitle>
      </RowContainer>
    </MainContainer>
  );
}

export default TimelineDashboard;
