import styled from 'styled-components';

import colors from '~/styles/colors';

export const Header = styled.header`
  /* margin-top: 30px; */
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NoDataText = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
`;

export const NoDataTextContainer = styled.div`
  margin-top: 30px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ResultTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 0;
  margin: 3px;

  font-size: 1rem;
  font-weight: bold;
  color: ${colors.gray500};
  text-align: center;

  @media only screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const Text = styled.div`
  font-weight: normal;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
`;

export const ChartContainer = styled.div`
  /* background: white; */
  padding: 16px;
  width: 100%;
  height: 800px;

  color: red;

  /* align-self: center; */
`;
