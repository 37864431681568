import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import Input from '~/components/Input';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import { showError, showSuccess } from '~/lib/toastHelper';
import { format, parseISO } from 'date-fns';
import { MainContainer } from '../../../components/MainContainer';
import { AsyncCombo } from '../../../components/AsyncCombo';
import TransfBonificada from './TransfBonificada';
import EntradaDePontos from './EntradaDePontos';
import { LineSeparator } from './styles';
import SaidaDePontos from './SaidaDePontos';
import TransfBumerangue from './TransfBumerangue';
import CompraEmParceiro from './CompraEmParceiro';

// import { } from './styles';

function OperacoesForm({ location }) {
  const [operacao] = useState(location.operacao);

  const isEditing = history.location.pathname === `/operacoes/edit`;

  const [tipoOperacao, setTipoOperacao] = useState(
    operacao ? operacao.tipo : null
  );
  const [data, setData] = useState(
    operacao && operacao.data
      ? format(parseISO(operacao.data), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  );
  const [detalhes, setDetalhes] = useState(operacao ? operacao.detalhes : null);

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'operacoes:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !operacao) {
      history.goBack();
    }
  }, [operacao, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ obs }) {
    const payload = {
      tipo: tipoOperacao,
      data: data || null,
      obs: obs || null,
      detalhes: detalhes || null,
    };

    try {
      if (operacao) {
        await api.put(`operacoes/${operacao.id}`, payload);
      } else {
        await api.post('operacoes', payload);
      }

      showSuccess(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      history.push(`/operacoes`);
    } catch (err) {
      showError('Falha ao salvar!', err);
    }
  }

  const handleSubmit = ({ obs }) => {
    if (!isEditing && !checkPermission(funcao, 'operacoes:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'operacoes:edit')) {
      return;
    }

    save({ obs });
  };

  const handleChanegeDetalhes = (value) => {
    setDetalhes(value);
  };

  const renderDetalhes = useCallback(() => {
    let opComp = null;
    switch (tipoOperacao) {
      case 'ACVOOPAGO':
        opComp = (
          <EntradaDePontos
            onChange={handleChanegeDetalhes}
            showDataCreditoPontos
          />
        );
        break;
      case 'ASSCLUBE':
        opComp = <EntradaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'CCRED':
        opComp = <EntradaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'COMPPARC':
        opComp = <CompraEmParceiro onChange={handleChanegeDetalhes} />;
        break;
      case 'COPTS':
        opComp = <EntradaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'ENTRADA':
        opComp = <EntradaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'EMISS':
        opComp = <SaidaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'SAIDA':
        opComp = <SaidaDePontos onChange={handleChanegeDetalhes} />;
        break;
      case 'TRBON':
        opComp = (
          <TransfBonificada key="transf" onChange={handleChanegeDetalhes} />
        );
        break;
      case 'TRBONBUM':
        opComp = <TransfBumerangue onChange={handleChanegeDetalhes} />;
        break;
      case 'TRDESAG':
        opComp = (
          <TransfBonificada
            key="transf-comDesagio"
            onChange={handleChanegeDetalhes}
            comDesagio
          />
        );
        break;
      default:
        break;
    }

    if (!opComp) return null;

    return (
      <>
        <LineSeparator />

        <FieldGroup>
          <Field flex="none">
            <Input
              name="data"
              label="Data"
              type="date"
              autoComplete="off"
              value={data || ''}
              onChange={(e) => setData(e.target.value)}
              required
            />
          </Field>
        </FieldGroup>

        {opComp}

        <LineSeparator />

        <FieldGroup>
          <Field>
            <Input
              name="obs"
              label="Observação"
              type="text"
              maxLength="255"
              autoComplete="off"
            />
          </Field>
        </FieldGroup>
      </>
    );
  }, [tipoOperacao, data]);

  return (
    <MainContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            obs: operacao && operacao.obs,
          }}>
          <FormHeader>
            <Title>Cadastro de Operação</Title>

            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'operacoes:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <AsyncCombo
                name="tipo_operacao"
                label="Tipo de Operação"
                route="operacoes/tipos"
                placeholder="Selecione um tipo..."
                idField="tipo"
                labelField="nome"
                onChange={setTipoOperacao}
                autoFocus
              />
            </Field>
          </FieldGroup>

          {renderDetalhes()}
        </Form>
      </FormWrapper>
    </MainContainer>
  );
}

OperacoesForm.propTypes = {
  location: PropTypes.shape(),
};

OperacoesForm.defaultProps = {
  location: null,
};

export default OperacoesForm;
