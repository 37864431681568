import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field, FieldGroup } from '../../../../components/Forms';
import { AsyncCombo } from '../../../../components/AsyncCombo';
import IntegerInput from '../../../../components/Inputs/IntegerInput';
import InfoTooltip from '../../../../components/InfoTooltip';
import Checkbox from '../../../../components/Checkbox';

// import { Container } from './styles';

function SaidaDePontos({ onChange }) {
  const [state, setState] = useState({});
  const [controlarCpfs, setControlarCpfs] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state, onChange]);

  const handleChangeTitular = (titular_id) => {
    setState((prev) => {
      return { ...prev, titular_id };
    });
  };

  const handleChangeProgrmaFidel = (id) => {
    setState((prev) => {
      return { ...prev, programa_fidel_id: id };
    });
  };

  const handleChangePontos = (pontos) => {
    setState((prev) => {
      return { ...prev, pontos };
    });
  };

  const handleChangeQtdeCpf = (qtde_cpf) => {
    setState((prev) => {
      return { ...prev, qtde_cpf };
    });
  };

  const handleChangeControlarCpfs = (checked) => {
    setControlarCpfs(checked);
  };

  return (
    <>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="titular"
            label="Titular"
            route="titulares"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeTitular}
          />
        </Field>
      </FieldGroup>
      <FieldGroup>
        <Field>
          <AsyncCombo
            name="progrma_fidel"
            label="Programa de Fidelidade"
            route="programas-fidel"
            placeholder="Selecione..."
            idField="id"
            labelField="nome"
            onChange={handleChangeProgrmaFidel}
            params={{
              pageLimit: 1000,
            }}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <IntegerInput
            name="pontos"
            label="Pontos"
            value={state ? state.pontos : 0}
            onChange={handleChangePontos}
          />
        </Field>
      </FieldGroup>

      <FieldGroup>
        <Field flex="none">
          <Checkbox
            onClick={(e) => handleChangeControlarCpfs(e.target.checked)}
            name="controlar_cpfs"
            label="Controlar CPFs?"
          />
        </Field>
      </FieldGroup>

      {controlarCpfs ? (
        <FieldGroup>
          <Field flex="none">
            <IntegerInput
              name="qtde_cpf"
              label="CPFs a descontar"
              value={state ? state.qtde_cpf : 0}
              onChange={handleChangeQtdeCpf}
              infoTooltip={
                <InfoTooltip
                  id="qtde_cpf_tooltip"
                  title="Quantidade de CPFs a descontar do limite anual de emissões"
                  text={
                    <span>
                      <p>
                        1. Se o titular da conta estiver junto da emissão,
                        desconsidere - ele não conta como CPF.
                      </p>
                      <p>
                        2. Exemplo de como lançar: Se você fez a emissão para 3
                        passageiros e um deles é o titular da conta, informe 2
                        no campo.
                      </p>
                      <p>
                        3. Se a mesma pessoa repetir dentro do período, não
                        precisa contabilizar, portanto, não lance.
                      </p>
                    </span>
                  }
                />
              }
            />
          </Field>
        </FieldGroup>
      ) : null}
    </>
  );
}

SaidaDePontos.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SaidaDePontos;
