import produce from 'immer';

const INITIAL_STATE = {
  funcao: null,
};

export default function funcao(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.funcao = action.payload.usuario.funcao;
        break;
      }

      case '@funcao/REFRESH_FUNCAO_SUCCESS': {
        draft.funcao = action.payload.funcao;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.funcao = null;
        break;
      }

      default:
    }
  });
}
