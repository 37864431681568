import React from 'react';

import history from '~/services/history';
import { Video } from '../../components/Video';
import { PageTitle, RowContainer, VideoContainer } from './styles';
import { MainContainer } from '../../components/MainContainer';

function Ajuda() {
  const videoTimeline = {
    title: 'Ajuda - Timeline',
    video_external_id: '8b8c911d-f9ce-4e3b-8f13-3736dcfae153',
  };
  const videoCustoMedio = {
    title: 'Ajuda - Timeline',
    video_external_id: '24c1c6d6-ed80-4968-8395-82a80c3bd1c4',
  };

  if (!videoCustoMedio && !videoTimeline) {
    history.push('/curadoria');
    return null;
  }

  return (
    <MainContainer>
      <RowContainer>
        <PageTitle>
          Quer utilizar o Timeline do jeito certo? Então assista este vídeo:
        </PageTitle>
      </RowContainer>

      <VideoContainer>
        <Video video={videoTimeline} showVideoInfo={false} />
      </VideoContainer>

      <RowContainer>
        <PageTitle>
          Confira também como utilizar o <strong>Custo Médio</strong> do
          Timeline:
        </PageTitle>
      </RowContainer>

      <VideoContainer>
        <Video video={videoCustoMedio} showVideoInfo={false} />
      </VideoContainer>
    </MainContainer>
  );
}

export default Ajuda;
