import { darken } from 'polished';
/**
 * Cores Ttraavley
 */
const traavleyColors = {
  blueLight: '#14c8eb',
  blue: '#2480c3',
  blueDark: '#1d5a89',
  yelow: '#ffe655',
  orange: '#f9b44f',
  orangeDark: '#f97850',
};

const gray900 = '#18181B';
const gray800 = '#27272A';
const gray700 = '#3F3F46';
const gray600 = '#52525B';
const gray500 = '#71717A';
const gray400 = '#A1A1AA';
const gray300 = '#D4D4D8';
const gray200 = '#E4E4E7';
const gray100 = '#F4F4F5';
const gray50 = '#FAFAFA';
const white = '#FAFAFA';
const blue500 = traavleyColors.blue;
const blue400 = traavleyColors.blueLight;
const red500 = '#F43F5E';
const green500 = '#10B981';
const orange600 = traavleyColors.orangeDark;
const orange500 = traavleyColors.orange;

const colors = {
  background: gray900,

  gray900,
  gray800,
  gray700,
  gray600,
  gray500,
  gray400,
  gray300,
  gray200,
  gray100,
  gray50,
  white,
  blue500,
  blue400,
  red500,
  green500,
  orange600,
  orange500,

  color_5: '#1B1B1E',
  color_4: '#373F51',
  color_3: '#1d5a89',
  color_2: '#A9BCD0',
  color_1: '#D8DBE2',

  primary: '#2480c3',
  neutral: '#D8DBE2',

  textDark: '#373F51',
  textDarker: '#1B1B1E',
  textLight: '#fff',
  textDisabled: '#ccc',
  textError: '#fb6f91',

  statusRed: '#DE3B3B',
  statusYellow: '#C1BC35',

  iconLight: '#fff',

  inputBorder: '#ccc',
  inputBorderHover: darken(0.06, '#ccc'),
  panelBorder: '#ddd',

  panelBackground: '#fff',
  inputBackground: '#fff',
  inputPlaceholder: '#ccc',

  statusGreenStrong: '#2CA42B',
  statusGreenSoft: '#DFF0DF',
  statusBlueStrong: '#4D85EE',
  statusBlueSoft: '#BAD2FF',
  statusYellowStrong: '#C1BC35',
  statusYellowSoft: '#F0F0DF',
  statusRedStrong: '#DE3B3B',
  statusRedSoft: '#FAB0B0',

  linkColor: blue500,
};

// const colorsBkp = {
//   color_5: '#1B1B1E',
//   color_4: '#373F51',
//   color_3: '#1d5a89',
//   color_2: '#A9BCD0',
//   color_1: '#D8DBE2',

//   primary: '#2480c3',
//   neutral: '#D8DBE2',

//   textDark: '#373F51',
//   textDarker: '#1B1B1E',
//   textLight: '#fff',
//   textDisabled: '#ccc',
//   textError: '#fb6f91',

//   statusRed: '#DE3B3B',
//   statusYellow: '#C1BC35',

//   iconLight: '#fff',

//   inputBorder: '#ccc',
//   inputBorderHover: darken(0.06, '#ccc'),
//   panelBorder: '#ddd',

//   background: '#f5f5f5',

//   panelBackground: '#fff',
//   inputBackground: '#fff',
//   inputPlaceholder: '#ccc',

//   statusGreenStrong: '#2CA42B',
//   statusGreenSoft: '#DFF0DF',
//   statusBlueStrong: '#4D85EE',
//   statusBlueSoft: '#BAD2FF',
//   statusYellowStrong: '#C1BC35',
//   statusYellowSoft: '#F0F0DF',
//   statusRedStrong: '#DE3B3B',
//   statusRedSoft: '#FAB0B0',

//   // linkColor: '#0000ee',
//   linkColor: '#0073BB',
// };

export default colors;
