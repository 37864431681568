import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { theme, styles } from '../../../config/reactSelect';

// import { } from './styles';

function AeroportoForm({ location }) {
  const [aeroporto] = useState(location.aeroporto);
  const timerCidade = useRef(null);

  const [cidadeId, setCidadeId] = useState(aeroporto && aeroporto.cidade_id);

  const isEditing = history.location.pathname === '/aeroportos/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'aeroportos:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !aeroporto) {
      history.goBack();
    }
  }, [aeroporto, isEditing, denied]);

  /**
   * Async Select - Cidades
   */
  async function loadCidades(filter) {
    const response = await api.get('cidades', {
      params: { q: filter },
    });

    const cidades = response.data.map((cidade) => {
      return { value: cidade.id, label: cidade.nome };
    });

    return cidades;
  }

  const loadCidadeOptions = (inputValue, callback) => {
    clearTimeout(timerCidade.current);
    timerCidade.current = setTimeout(async () => {
      callback(await loadCidades(inputValue));
    }, 600);
  };

  const handleChangeCidade = (selectedOptions) => {
    setCidadeId(selectedOptions.value);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ cidade_id, nome, codigo_iata }) {
    const payload = { cidade_id, nome, codigo_iata };

    try {
      if (aeroporto) {
        await api.put(`aeroportos/${aeroporto.id}`, payload); // Edit
      } else {
        await api.post('aeroportos', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/aeroportos');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ nome, codigo_iata }) => {
    if (!isEditing && !checkPermission(funcao, 'aeroportos:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'aeroportos:edit')) {
      return;
    }

    save({ cidade_id: cidadeId, nome, codigo_iata });
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: aeroporto && aeroporto.nome,
            codigo_iata: aeroporto && aeroporto.codigo_iata,
          }}>
          <FormHeader>
            <Title>Cadastro de Aeroporto</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'aeroportos:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="cidade">Cidade</label>
              <AsyncSelect
                autoFocus
                name="cidade"
                placeholder="Selecione a cidade..."
                cacheOptions
                loadOptions={loadCidadeOptions}
                defaultOptions
                onChange={handleChangeCidade}
                defaultValue={
                  aeroporto &&
                  aeroporto.cidade && {
                    value: aeroporto.cidade.id,
                    label: aeroporto.cidade.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              {/* <label htmlFor="nome">Nome</label> */}
              <Input
                name="nome"
                label="Nome"
                type="text"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              {/* <label htmlFor="codigo_iata">Código IATA</label> */}
              <Input
                name="codigo_iata"
                label="Código IATA"
                type="text"
                maxLength="3"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

AeroportoForm.propTypes = {
  location: PropTypes.shape(),
};

AeroportoForm.defaultProps = {
  location: null,
};

export default AeroportoForm;
