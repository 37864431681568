import { takeLatest, call, put, all } from 'redux-saga/effects';
import history from '~/services/history';
import api from '~/services/api';
import { showError, showSuccess } from '../../../lib/toastHelper';

import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      nome,
      // email,
      // avatar_id,
      senha_antiga,
      senha,
      senha_confirmacao,
    } = payload.data;

    const profile = {
      nome,
      // email,
      // avatar_id,
      senha_antiga,
      senha,
      senha_confirmacao,
    };

    const response = yield call(api.put, 'usuarios/profile', profile);
    showSuccess('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
    history.push('/');
  } catch (err) {
    showError('Falha ao salvar!', err);
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest('@usuario/UPDATE_PROFILE_REQUEST', updateProfile),
]);
