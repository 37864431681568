import { combineReducers } from 'redux';

import auth from '~/store/modules/auth/reducer';
import funcao from '~/store/modules/funcao/reducer';
import usuario from '~/store/modules/usuario/reducer';

export default combineReducers({
  auth,
  usuario,
  funcao,
});
