import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input';

const FloatInput = ({
  value,
  onChange,
  name,
  label,
  maxLength,
  disabled,
  precision,
}) => {
  // const [isFocused, setIsFocused] = useState(false);

  const floatFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  const divisor = Number('1'.padEnd(1 + precision, '0'));

  const [displayValue, setDisplayValue] = useState(
    floatFormatter.format(value / divisor)
  );

  // Permite alterar value fora do componente
  useEffect(() => {
    setDisplayValue(floatFormatter.format(value));
  }, [value]);

  const handleChange = (rawValue) => {
    const parsedValue = Number(rawValue.replace(/[^\d]/g, '') / divisor);
    const formattedValue = floatFormatter.format(parsedValue);

    setDisplayValue(formattedValue);
    onChange(parsedValue);
  };

  return (
    <Input
      type="text"
      name={name}
      label={label}
      maxLength={maxLength}
      autoComplete="off"
      // onFocus={() => setIsFocused(true)}
      // onBlur={() => setIsFocused(false)}
      value={displayValue}
      onChange={(e) => handleChange(e.target.value)}
      disabled={disabled}
    />
  );
};

FloatInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  precision: PropTypes.number,
};

FloatInput.defaultProps = {
  value: 0,
  label: '',
  maxLength: 18,
  disabled: false,
  precision: 1,
};

export default FloatInput;
