import React, { useState, useEffect } from 'react';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import {
  MdDone,
  MdArrowBack,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import { updateProfileRequest } from '~/store/modules/usuario/actions';
import { showError } from '../../../lib/toastHelper';

// import { } from './styles';

function ProfileForm() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.usuario.profile);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    /**
     * Go back on page refresh
     */
    if (!profile) {
      history.goBack();
    }
  }, [profile]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save(nome, senha_antiga, senha, senha_confirmacao) {
    const payload = {
      nome,
      // email,
      senha_antiga,
      senha,
      senha_confirmacao,
    };

    try {
      dispatch(updateProfileRequest(payload));
    } catch (err) {
      showError('Falha ao salvar!', err);
    }
  }

  const handleSubmit = ({ nome, senha_antiga, senha, senha_confirmacao }) => {
    save(
      nome,
      senha_antiga || undefined,
      senha || undefined,
      senha_confirmacao || undefined
    );
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form initialData={profile} onSubmit={handleSubmit}>
          <FormHeader>
            <Title>Seu Perfil</Title>

            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button primary type="submit">
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <Input name="email" label="E-mail" type="email" disabled />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <Input
                name="nome"
                label="Nome"
                type="text"
                maxLength="100"
                autoFocus
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <Input
                name="senha_antiga"
                label="Senha Antiga"
                type="password"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <Input
                // style={{ flex: 1 }}
                name="senha"
                label="Nova Senha"
                type={showPass ? 'text' : 'password'}
                maxLength="100"
                autoComplete="off"
              />
            </Field>

            <Field flex="none">
              <label style={{ color: 'transparent' }} htmlFor="salvar">
                .
              </label>
              <Button
                name="salvar"
                // style={{ paddingLeft: 10, paddingRight: 5 }}
                type="button"
                onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <>
                    <MdVisibility size={18} color={colors.iconLight} />
                    Ocultar senha
                  </>
                ) : (
                  <>
                    <MdVisibilityOff size={18} color={colors.iconLight} />
                    Exibir senha
                  </>
                )}
              </Button>
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="senha_confirmacao">Confirmação de Senha</label>
              <Input
                name="senha_confirmacao"
                type="password"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="senha_confirmacao">
                <small>No mínimo 8 caracteres</small>
                <br />
                <small>No mínimo 1 letra maiúscula</small>
                <br />
                <small>No mínimo 1 letra minúscula</small>
                <br />
                <small>No mínimo 1 número</small>
                <br />
                <small>No mínimo 1 caractere especial</small>
                <br />
              </label>
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

// ProfileForm.propTypes = {
//   location: PropTypes.shape(),
// };

// ProfileForm.defaultProps = {
//   location: null,
// };

export default ProfileForm;
