import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

export default function Checkbox({ name, label, labelOnTheLeft, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked;
      },
      clearValue: (ref) => {
        ref.current.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {labelOnTheLeft ? (
        <label htmlFor={fieldName} style={{ paddingRight: 8 }}>
          {label}
        </label>
      ) : null}

      <input
        id={fieldName}
        ref={inputRef}
        type="checkbox"
        defaultChecked={defaultValue}
        {...rest}
      />

      {!labelOnTheLeft ? (
        <label htmlFor={fieldName} style={{ paddingLeft: 8 }}>
          {label}
        </label>
      ) : null}

      {error && <span className="error">{error}</span>}
    </>
  );
}
