import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EfetivarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
  max-width: 400px;
`;
