import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 30px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
`;

export const DetalhesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DetalhesLabel = styled.div`
  padding: 8px;
`;

export const DetalhesValor = styled.div`
  padding: 8px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
