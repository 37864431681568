import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function PontosMovTipo({ tipo }) {
  return (
    <Container entrada={tipo > 0}>
      {/* <span /> */}
      {tipo > 0 ? 'Entrada' : 'Saída'}
    </Container>
  );
}

PontosMovTipo.propTypes = {
  tipo: PropTypes.oneOf([-1, 1]).isRequired,
};

export default PontosMovTipo;
