import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import 'react-tabs/style/react-tabs.css';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import Input from '~/components/Input';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

import { theme, styles } from '~/config/reactSelect';
import IntegerInput from '../../../components/Inputs/IntegerInput';

// import { } from './styles';

const optionsControleCpfs = [
  { value: null, label: 'Nenhum' },
  { value: 'ANO', label: 'Ano Civil' },
  { value: 'U12', label: 'Últimos 12 Meses' },
  { value: 'LU', label: 'Limite Único' },
];

function ProgramaFidelForm({ location }) {
  const [programaFidel] = useState(location.programaFidel);
  const isEditing = history.location.pathname === '/programas-fidel/edit';

  const [tipoControleCpfs, setTipoControleCpfs] = useState(
    programaFidel ? programaFidel.tipo_controle_cpfs : null
  );

  const [limiteCpfs, setLimiteCpfs] = useState(
    programaFidel ? programaFidel.limite_cpfs : null
  );

  const [tipoControleCpfsDefaultValue] = useState(
    optionsControleCpfs.find((o) => o.value === tipoControleCpfs)
  );

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied =
    !isEditing && !checkPermission(funcao, 'programas-fidel:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !programaFidel) {
      history.goBack();
    }
  }, [programaFidel, isEditing, denied]);

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ nome }) {
    const payload = {
      nome,
      tipo_controle_cpfs: tipoControleCpfs,
      limite_cpfs: limiteCpfs,
    };

    try {
      if (programaFidel) {
        await api.put(`programas-fidel/${programaFidel.id}`, payload); // Edit
      } else {
        await api.post('programas-fidel', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/programas-fidel');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ nome }) => {
    if (!isEditing && !checkPermission(funcao, 'programas-fidel:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'programas-fidel:edit')) {
      return;
    }

    save({ nome });
  };

  const handleChangeTipoControleCpfs = (selectedOptions) => {
    const tipo = selectedOptions.value;
    setTipoControleCpfs(tipo);

    if (tipo === null) {
      setLimiteCpfs(null);
    }
  };

  const handleChangeLimiteCpfs = (limite) => {
    setLimiteCpfs(limite);
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: programaFidel && programaFidel.nome,
          }}>
          <FormHeader>
            <Title>Cadastro de Programa de Fidelidade</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'programas-fidel:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="nome">Nome</label>
              <Input
                name="nome"
                type="text"
                maxLength="100"
                autoComplete="off"
                autoFocus
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex={0.3}>
              <label htmlFor="tipo">Controle de CPFs</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={tipoControleCpfsDefaultValue}
                name="tipo"
                options={optionsControleCpfs}
                onChange={handleChangeTipoControleCpfs}
                theme={theme}
                styles={styles}
              />
            </Field>

            {tipoControleCpfs !== null ? (
              <Field flex="none">
                <IntegerInput
                  name="limite_cpfs"
                  label="Limite de CPFs"
                  value={limiteCpfs || 0}
                  onChange={handleChangeLimiteCpfs}
                />
              </Field>
            ) : null}
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

ProgramaFidelForm.propTypes = {
  location: PropTypes.shape(),
};

ProgramaFidelForm.defaultProps = {
  location: null,
};

export default ProgramaFidelForm;
