import React from 'react';

function getErrorMessage(err) {
  if (!err) {
    return '';
  }

  if (err.response && err.response.data && err.response.data.errors) {
    const { errors } = err.response.data;

    return errors.map((error) => (
      <div key={`${error}`} style={{ padding: '4px 0' }}>
        {error}
      </div>
    ));
  }

  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.message
  ) {
    return err.response.data.error.message.toString();
  }

  if (err.response && err.response.data && err.response.data.error) {
    return err.response.data.error.toString();
  }

  if (
    err.response &&
    err.response.statusText &&
    err.response.statusText === 'Too Many Requests'
  ) {
    return err.response.data.toString();
  }

  if (err.message) {
    return err.message.toString();
  }

  return err.toString();
}

export default getErrorMessage;
