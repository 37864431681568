import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import { Header } from './styles';

function EstadoList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [estados, setEstados] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'nome', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadEstados(f) {
      try {
        setLoading(true);
        const response = await api.get('estados', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((estado) => {
          return {
            ...estado,
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setEstados(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'estados:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadEstados(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/estados/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleEdit(id) {
    const estadoToEdit = estados.find((estado) => estado.id === id);
    if (estadoToEdit) {
      history.push({
        pathname: '/estados/edit',
        estado: estadoToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir o estado?')) {
      return;
    }

    try {
      await api.delete(`estados/${id}`);
      toast.success('Estado excluído com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao excluir estado! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Estados {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, sigla"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="estados:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Sigla"
                showOrder={order.field === 'sigla'}
                asc={order.asc}
                onClick={() => handleChangeOrder('sigla')}
              />
            </th>
            <th>País</th>
            <th>Região</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {estados.map((estado) => (
            <tr key={estado.id}>
              <td>{estado.nome}</td>
              <td>{estado.sigla}</td>
              <td>{estado.pais && estado.pais.nome}</td>
              <td>{estado.regiao && estado.regiao.nome}</td>
              <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="estados:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(estado.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(estado.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="estados:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(estado.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && estados.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default EstadoList;
