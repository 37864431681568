import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

import { MainContainer } from '../../components/MainContainer';
import api from '../../services/api';

import getErrorMessage from '../../lib/getErrorMessage';
import { Title } from '../../components/Title';
import { NoInfoToShow } from '../../components/NoInfoToShow';
import Pagination from '../../components/Pagination';
import history from '../../services/history';

import {
  VideoGrid,
  VideoWrapper,
  ThumbnailContainer,
  InfoContainer,
} from './styles';

function Curadoria() {
  const [videos, setVideos] = useState([]);
  // const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [order, setOrder] = useState({ field: 'nome', asc: true });
  // const [refresh, setRefresh] = useState(true);
  const COLS = 5;
  const [rows, setRows] = useState(1);

  useEffect(() => {
    async function loadVideos() {
      if (loading) {
        return;
      }

      try {
        setLoading(true);

        const response = await api.get('panda/videos', {
          params: {
            // q: f,
            page,
            pageLimit,
            // order: order.field,
            // direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((video) => {
          return {
            ...video,
            created_at_formatted:
              video.created_at &&
              format(parseISO(video.created_at), 'dd/MM/yyyy'),
          };
        });

        const rowCount = Math.ceil(data.length / COLS);

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setVideos(data);
        setRows(rowCount);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
    }

    /**
     * Check permissions
     */
    // if (funcao && !checkPermission(funcao, 'aeroportos:visit')) {
    //   history.push('/denied');
    //   return;
    // }

    // clearTimeout(time.current);
    // time.current = setTimeout(() => {
    loadVideos();
    // }, 600);
  }, [page, pageLimit]);
  // }, [filter, page, pageLimit, order, funcao, refresh]);

  const showVideo = (video) => {
    if (video) {
      history.push({
        pathname: '/curadoria/video',
        video,
      });
    }
  };

  return (
    <MainContainer>
      <Title>Vídeos {total > 0 && `(${total})`}</Title>

      <VideoGrid rows={rows} cols={5}>
        {videos.map((video) => (
          <VideoWrapper key={video.id}>
            <ThumbnailContainer onClick={() => showVideo(video)}>
              <img src={video.thumbnail} alt="" />
            </ThumbnailContainer>

            <InfoContainer>
              <strong>{video.description || video.title}</strong>
              <span>Publicado em {video.created_at_formatted}</span>
            </InfoContainer>
          </VideoWrapper>
        ))}
      </VideoGrid>

      {!loading && videos.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        !loading &&
        totalPages > 1 && (
          <Pagination
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        )
      )}
    </MainContainer>
  );
}

export default Curadoria;
