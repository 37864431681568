import styled from 'styled-components';

export const MainContainer = styled.main`
  /* display: flex; */
  /* flex-direction: column; */
  padding: 1rem;
  margin: auto;
  max-width: 70rem;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    padding: 0;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* screen width >= 600 pixels  */
  @media only screen and (min-width: 600px) {
  }

  /* Medium devices (landscape tablets, 768px and up) */
  /* screen width >= 768 pixels  */
  @media only screen and (min-width: 768px) {
  }

  /* Large devices (laptops/desktops, 992px and up) */
  /* screen width >= 992 pixels  */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* screen width >= 1200 pixels  */
  @media only screen and (min-width: 1200px) {
  }
`;
