import styled from 'styled-components';
import { darken } from 'polished';

import colors from '~/styles/colors';

export const Container = styled.div`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.7;
  }

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    /* background: lightsalmon; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .modal {
    background: ${colors.gray800};
    z-index: 100;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 8px;
    /* max-width: 500px; */
    padding: 10px;
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .modal-close-button {
    background: ${colors.color_1};
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: ${colors.textDarker};
    opacity: 0.3;
    cursor: pointer;
    border: none;

    &:hover {
      background: ${darken(0.1, colors.color_1)};
      color: ${darken(0.1, colors.textDarker)};
    }

    &:active {
      background: ${darken(0.2, colors.color_1)};
      color: ${darken(0.5, colors.textDarker)};
    }
  }

  button {
    font-size: 0.9rem;
    font-weight: 700;
    border: none;
    border-radius: 8px;
    padding: 0.3rem 1rem;
    margin-left: 0.5rem;
  }
`;
