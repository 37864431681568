import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdFileUpload,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import { FilterPanelEmissoes } from '~/components/FilterPanelEmissoes';
import { Field, FieldGroup } from '~/components/Forms';
import Card from '~/components/Card';
import ImportCSVModal from '~/components/ImportCSVModal';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { Button } from '~/components/Button';
// import { LinkButton } from '~/components/LinkButton';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import { classesVoo } from '~/config/classesVoo';
import { integerToString } from '~/lib/formataNumero';
// import { theme, styles } from '../../../config/reactSelect';

import { Header } from './styles';

function EmissaoList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState({
    q: '',
    origem_pais_id: undefined,
    origem_regiao_id: undefined,
    origem_estado_id: undefined,
    origem_cidade_id: undefined,
    destino_pais_id: undefined,
    destino_regiao_id: undefined,
    destino_estado_id: undefined,
    destino_cidade_id: undefined,
    cia_aerea_id: undefined,
    programa_fidel_id: undefined,
    classe: undefined,
    internacional: undefined,
  });
  const [emissoes, setEmissoes] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'data_divulgacao', asc: false });
  const [refresh, setRefresh] = useState(true);

  const [isShowingCSVModal, setIsShowingCSVModal] = useState(false);

  useEffect(() => {
    async function loadEmissoes({
      q,
      origem_pais_id,
      origem_regiao_id,
      origem_estado_id,
      origem_cidade_id,
      destino_pais_id,
      destino_regiao_id,
      destino_estado_id,
      destino_cidade_id,
      cia_aerea_id,
      programa_fidel_id,
      classe,
      internacional,
    }) {
      try {
        setLoading(true);
        const response = await api.get('emissoes', {
          params: {
            q,
            origem_pais_id,
            origem_regiao_id,
            origem_estado_id,
            origem_cidade_id,
            destino_pais_id,
            destino_regiao_id,
            destino_estado_id,
            destino_cidade_id,
            cia_aerea_id,
            programa_fidel_id,
            classe,
            internacional,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((emissao) => {
          return {
            ...emissao,

            origem_pais:
              emissao.origem_aeroporto &&
              emissao.origem_aeroporto.cidade &&
              emissao.origem_aeroporto.cidade.pais &&
              emissao.origem_aeroporto.cidade.pais.nome,
            origem_regiao:
              emissao.origem_aeroporto &&
              emissao.origem_aeroporto.cidade &&
              emissao.origem_aeroporto.cidade.estado &&
              emissao.origem_aeroporto.cidade.estado.regiao &&
              emissao.origem_aeroporto.cidade.estado.regiao.nome,
            origem_estado:
              emissao.origem_aeroporto &&
              emissao.origem_aeroporto.cidade &&
              emissao.origem_aeroporto.cidade.estado &&
              (emissao.origem_aeroporto.cidade.estado.sigla ||
                emissao.origem_aeroporto.cidade.estado.nome),
            origem_cidade:
              emissao.origem_aeroporto &&
              emissao.origem_aeroporto.cidade &&
              emissao.origem_aeroporto.cidade.nome,

            destino_pais:
              emissao.destino_aeroporto &&
              emissao.destino_aeroporto.cidade &&
              emissao.destino_aeroporto.cidade.pais &&
              emissao.destino_aeroporto.cidade.pais.nome,
            destino_regiao:
              emissao.destino_aeroporto &&
              emissao.destino_aeroporto.cidade &&
              emissao.destino_aeroporto.cidade.estado &&
              emissao.destino_aeroporto.cidade.estado.regiao &&
              emissao.destino_aeroporto.cidade.estado.regiao.nome,
            destino_estado:
              emissao.destino_aeroporto &&
              emissao.destino_aeroporto.cidade &&
              emissao.destino_aeroporto.cidade.estado &&
              (emissao.destino_aeroporto.cidade.estado.sigla ||
                emissao.destino_aeroporto.cidade.estado.nome),
            destino_cidade:
              emissao.destino_aeroporto &&
              emissao.destino_aeroporto.cidade &&
              emissao.destino_aeroporto.cidade.nome,

            data_divulgacao_formatted:
              emissao.data_divulgacao &&
              format(parseISO(emissao.data_divulgacao), 'dd/MM/yyyy'),
            data_voo_formatted:
              emissao.data_voo &&
              format(parseISO(emissao.data_voo), 'dd/MM/yyyy'),
            milhas_trecho_formatted: integerToString(emissao.milhas_trecho),
            classe_formatted: classesVoo[emissao.classe].nomeCurto,
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setEmissoes(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'emissoes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadEmissoes(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  const handleCreate = () => {
    history.push('/emissoes/create');
  };

  function reload() {
    setFilter({ q: ' ', pais_id: undefined });
    setFilter({ q: '', pais_id: undefined });
  }

  function handleEdit(id) {
    const emissaoToEdit = emissoes.find((emissao) => emissao.id === id);
    if (emissaoToEdit) {
      history.push({
        pathname: '/emissoes/edit',
        emissao: emissaoToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir a emissão?')) {
      return;
    }

    try {
      await api.delete(`emissoes/${id}`);
      toast.success('Emissão excluída com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao excluir emissão! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  const onCloseCSVModal = () => {
    setIsShowingCSVModal(false);
    handleRefresh();
  };

  return (
    <BaseContainer>
      <Title>Emissões {total > 0 && `(${total})`}</Title>

      <ImportCSVModal
        isShowing={isShowingCSVModal}
        onClose={onCloseCSVModal}
        route="emissoes/import-csv"
        csvHeaders="Aeroporto de Origem,Aeroporto de Destino,Cia Aérea,Programa de Fidelidade,Data da Divulgação,Data do Voo,Milhas por Trecho,Classe,Tipo de Emissão,Dias,Meses"
      />

      <Header>
        <Field />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="emissoes:edit"
            yes={() => (
              <Card dropAlign="right">
                <Can
                  funcao={funcao}
                  perform="emissoes:edit"
                  yes={() => (
                    <li>
                      <MdFileUpload size={20} color={colors.color_2} />
                      <button
                        type="button"
                        onClick={() => setIsShowingCSVModal(true)}>
                        Importar arquivo .CSV
                      </button>
                    </li>
                  )}
                />
              </Card>
            )}
          />

          <Can
            funcao={funcao}
            perform="emissoes:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>

      {/* Filtros */}
      <FilterPanelEmissoes
        // showPaisOrigem={false}
        // showRegiaoOrigem={false}
        // showEstadoOrigem={false}
        // showCidadeOrigem={false}
        // showPaisDestino={false}
        // showRegiaoDestino={false}
        // showEstadoDestino={false}
        // showCidadeDestino={false}
        // showCiaAerea={false}
        // showProgramaFidel={false}
        onFilterChange={setFilter}
      />

      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="País Origem"
                showOrder={order.field === 'origem_pais'}
                asc={order.asc}
                onClick={() => handleChangeOrder('origem_pais')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Região Origem"
                showOrder={order.field === 'origem_regiao'}
                asc={order.asc}
                onClick={() => handleChangeOrder('origem_regiao')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Estado Origem"
                showOrder={order.field === 'origem_estado'}
                asc={order.asc}
                onClick={() => handleChangeOrder('origem_estado')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Cidade Origem"
                showOrder={order.field === 'origem_cidade'}
                asc={order.asc}
                onClick={() => handleChangeOrder('origem_cidade')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Aeroporto Origem"
                showOrder={order.field === 'origem_aeroporto'}
                asc={order.asc}
                onClick={() => handleChangeOrder('origem_aeroporto')}
              />
            </th>
            <th>
              <ColumnHeader
                label="País Destino"
                showOrder={order.field === 'destino_pais'}
                asc={order.asc}
                onClick={() => handleChangeOrder('destino_pais')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Região Destino"
                showOrder={order.field === 'destino_regiao'}
                asc={order.asc}
                onClick={() => handleChangeOrder('destino_regiao')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Estado Destino"
                showOrder={order.field === 'destino_estado'}
                asc={order.asc}
                onClick={() => handleChangeOrder('destino_estado')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Cidade Destino"
                showOrder={order.field === 'destino_cidade'}
                asc={order.asc}
                onClick={() => handleChangeOrder('destino_cidade')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Aeroporto Destino"
                showOrder={order.field === 'destino_aeroporto'}
                asc={order.asc}
                onClick={() => handleChangeOrder('destino_aeroporto')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Cia. Aérea"
                showOrder={order.field === 'cia_aerea'}
                asc={order.asc}
                onClick={() => handleChangeOrder('cia_aerea')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Programa Fidelidade"
                showOrder={order.field === 'programa_fidel'}
                asc={order.asc}
                onClick={() => handleChangeOrder('programa_fidel')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Data Divulgação"
                showOrder={order.field === 'data_divulgacao'}
                asc={order.asc}
                onClick={() => handleChangeOrder('data_divulgacao')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Data Voo"
                showOrder={order.field === 'data_voo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('data_voo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Número Voo"
                showOrder={order.field === 'numero_voo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('numero_voo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Milhas Trecho"
                showOrder={order.field === 'milhas_trecho'}
                asc={order.asc}
                onClick={() => handleChangeOrder('milhas_trecho')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Classe"
                showOrder={order.field === 'classe'}
                asc={order.asc}
                onClick={() => handleChangeOrder('classe')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Dias"
                showOrder={order.field === 'dias_divulg_voo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('dias_divulg_voo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Meses"
                showOrder={order.field === 'meses_divulg_voo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('meses_divulg_voo')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {emissoes.map((emissao) => (
            <tr key={emissao.id}>
              <td>{emissao.origem_pais}</td>
              <td>{emissao.origem_regiao}</td>
              <td>{emissao.origem_estado}</td>
              <td>{emissao.origem_cidade}</td>
              <td>
                {emissao.origem_aeroporto &&
                  emissao.origem_aeroporto.codigo_iata}
              </td>
              <td>{emissao.destino_pais}</td>
              <td>{emissao.destino_regiao}</td>
              <td>{emissao.destino_estado}</td>
              <td>{emissao.destino_cidade}</td>
              <td>
                {emissao.destino_aeroporto &&
                  emissao.destino_aeroporto.codigo_iata}
              </td>
              <td>{emissao.cia_aerea && emissao.cia_aerea.nome}</td>
              <td>{emissao.programa_fidel && emissao.programa_fidel.nome}</td>
              <td>{emissao.data_divulgacao_formatted}</td>
              <td>{emissao.data_voo_formatted}</td>
              <td>{emissao.numero_voo}</td>
              <td>{emissao.milhas_trecho_formatted}</td>
              <td>{emissao.classe_formatted}</td>
              <td>{emissao.dias_divulg_voo}</td>
              <td>{emissao.meses_divulg_voo}</td>
              <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="emissoes:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(emissao.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(emissao.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="emissoes:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(emissao.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && emissoes.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default EmissaoList;
