import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input';

const currencyFormatterBR = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

const CurrencyInput = ({
  value,
  onChange,
  name,
  label,
  maxLength,
  disabled,
}) => {
  // const [isFocused, setIsFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    currencyFormatterBR.format(value / 100)
  );

  // Permite alterar value fora do componente
  useEffect(() => {
    setDisplayValue(currencyFormatterBR.format(value));
  }, [value]);

  const handleChange = (rawValue) => {
    const parsedValue = Number(rawValue.replace(/[^\d]/g, '') / 100);
    const formattedValue = currencyFormatterBR.format(parsedValue);

    setDisplayValue(formattedValue);
    onChange(parsedValue);
  };

  return (
    <Input
      type="text"
      name={name}
      label={label}
      maxLength={maxLength}
      autoComplete="off"
      // onFocus={() => setIsFocused(true)}
      // onBlur={() => setIsFocused(false)}
      value={displayValue}
      onChange={(e) => handleChange(e.target.value)}
      disabled={disabled}
    />
  );
};

CurrencyInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  value: 0,
  label: '',
  maxLength: 18,
  disabled: false,
};

export default CurrencyInput;
