import styled from 'styled-components';
import colors from '~/styles/colors';

export const FormWrapper = styled.div`
  /* border: 1px solid ${colors.panelBorder}; */
  /* border-radius: 4px; */

  input {
    /* border: 1px solid ${colors.inputBorder}; */
    background: ${colors.gray700};
    padding: 10px;
    border-radius: 8px;
    border: 0;
    color: ${colors.gray300};
    border: 1px solid ${colors.gray700};
  }

  textArea {
    /* border: 1px solid ${colors.inputBorder}; */
    background: ${colors.gray700};
    padding: 10px;
    border-radius: 8px;
    border: 0;
  }

  label {
    padding: 5px 0;
    color: ${colors.gray300};
  }

  form {
    background: ${colors.gray800};
    border-radius: 18px;
    padding: 16px;
  }
`;

export const LabelCheckBox = styled.label`
  padding: 10px 10px 10px 10px;
  /* padding: 1px 0; */
  color: ${colors.gray300};
  /* margin-right: 20px; */
  /* margin-bottom: 20px; */
  flex: 1;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 992px) {
    display: block;

    div button {
      margin-left: 0;
      /* padding-left: 0; */
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 4px;
  margin-right: 4px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  flex: ${(props) => (props.flex ? props.flex : '1')};

  > input {
    border: 2px solid transparent;
  }

  > input:focus {
    border: 2px solid ${colors.blue500};
  }
`;

export const InlineField = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 4px;
  margin-right: 4px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  flex: ${(props) => (props.flex ? props.flex : '1')};

  > input {
    border: 2px solid transparent;
  }

  > input:focus {
    border: 2px solid ${colors.blue500};
  }
`;

export const FieldCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
  margin: 5px;
  flex: ${(props) => (props.flex ? props.flex : '1')};

  > input:focus {
    /* box-shadow: 0 0 3px ${colors.primary}; */
    border: 1px solid ${colors.blue500};
  }
`;

export const FieldInlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
