import React, { useState, useEffect, useRef } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import checkPermission from '~/lib/checkPermission';

import Pagination from '~/components/Pagination';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import { BaseContainer } from '../../../components/BaseContainer';

import {
  currencyPrecision4ToString,
  currencyToString,
  integerToString,
} from '../../../lib/formataNumero';

import { Header } from './styles';
import { showError } from '../../../lib/toastHelper';

function PontosSaldoList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter] = useState('');
  const [saldos, setSaldos] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'titular_id', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadSaldos(f) {
      try {
        setLoading(true);
        const response = await api.get('pontos-saldo', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((saldo) => {
          return {
            ...saldo,
            custoFormatted: currencyToString(saldo.custo),
            custoUnitarioFormatted: currencyPrecision4ToString(
              saldo.custoUnitario
            ),
            custoMilheiroFormatted: currencyToString(
              saldo.custoUnitario * 1000
            ),
            qtdeFormatted: integerToString(saldo.qtde),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setSaldos(data);
        setLoading(false);
      } catch (err) {
        showError('Falha ao carregar dados!', err);
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'pontos-saldo:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadSaldos(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  // function handleFilterChange(value) {
  //   setPage(1);
  //   setFilter(value);
  // }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Saldos {total > 0 && `(${total})`}</Title>

      <Header>
        <div />
        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Titular"
                showOrder={order.field === 'titular_id'}
                asc={order.asc}
                onClick={() => handleChangeOrder('titular_id')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Programa de Fidelidade"
                showOrder={order.field === 'programa_fidel_id'}
                asc={order.asc}
                onClick={() => handleChangeOrder('programa_fidel_id')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Saldo de Pontos"
                showOrder={order.field === 'qtde'}
                asc={order.asc}
                onClick={() => handleChangeOrder('qtde')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Custo Médio (milheiro)"
                showOrder={order.field === 'custo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('custo')}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {saldos.map((saldo) => (
            <tr
              key={`${saldo.conta_id}-${saldo.titular_id}-${saldo.programa_fidel_id}`}>
              <td>{saldo.titular && saldo.titular.nome}</td>
              <td>{saldo.programa_fidel && saldo.programa_fidel.nome}</td>
              <td>{saldo.qtdeFormatted}</td>
              <td>{saldo.custoMilheiroFormatted}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && saldos.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default PontosSaldoList;
