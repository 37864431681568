import React, { useState, useEffect, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ResponsiveBar } from '@nivo/bar';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { ThreeDots } from 'react-loader-spinner';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import { Field, FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import { AsyncCombo } from '~/components/AsyncCombo';

import { format, parseISO, subMonths } from 'date-fns';
import { MainContainer } from '../../../components/MainContainer';
import { theme, styles } from '../../../config/reactSelect';

import {
  Header,
  NoDataTextContainer,
  NoDataText,
  ResultTitle,
  ChartContainer,
} from './styles';

const chatTheme = {
  background: 'transparent',
  text: {
    fontSize: 12,
    fill: '#333333',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  axis: {
    domain: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 18,
        fill: colors.gray400,
        outlineWidth: 0,
        outlineColor: 'transparent',
      },
    },
    ticks: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fill: colors.gray400,
        outlineWidth: 0,
        outlineColor: 'transparent',
      },
    },
  },

  grid: {
    line: {
      stroke: '#dddddd',
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: colors.gray400,
        outlineWidth: 0,
        outlineColor: 'transparent',
      },
    },
    text: {
      fontSize: 11,
      fill: colors.gray400,
      outlineWidth: 0,
      outlineColor: 'transparent',
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: colors.gray400,
        outlineWidth: 0,
        outlineColor: 'transparent',
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: colors.gray400,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    outline: {
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: '#ffffff',
      color: colors.gray400,
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

function PromocaoHistorico() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [promocoes, setPromocoes] = useState([]);
  const time = useRef(null);
  // const [totalPages, setTotalPages] = useState(1);
  // const [page, setPage] = useState(1);
  // const [pageLimit, setPageLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [order] = useState({ field: 'cidade_origem', asc: true });
  const [refresh, setRefresh] = useState(true);
  const [consultasRealizadas, setConsultasRealizadas] = useState(0);
  const [programaFidelId, setProgramaFidelId] = useState();
  const [parceiroId, setParceiroId] = useState();
  const [periodoEmMeses, setPeriodoEmMeses] = useState(6);

  const filtrosPreenchidos = useMemo(() => {
    return !!programaFidelId && !!parceiroId && !!periodoEmMeses;
  }, [programaFidelId, parceiroId, periodoEmMeses]);

  const dataIni = useMemo(() => {
    if (!periodoEmMeses) return undefined;
    return format(subMonths(new Date(), periodoEmMeses), 'yyyy-MM-dd');
  }, [periodoEmMeses]);

  useEffect(() => {
    async function loadPromocoes() {
      if (!filtrosPreenchidos) {
        return false;
      }

      try {
        setLoading(true);
        const response = await api.get('promocoes', {
          params: {
            programa_fidel_id: programaFidelId,
            parceiro_id: parceiroId,
            data_ini: dataIni,
            // page,
            pageLimit: 50,
            // order: order.field,
            // direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((promocao) => {
          return {
            ...promocao,
            'Pontos Clube': promocao.pontos_clube,
            'Pontos Geral': promocao.pontos_geral,
            dataFormatted:
              promocao.data && format(parseISO(promocao.data), 'dd/MM/yyyy'),
          };
        });

        // setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setPromocoes(data);
        setLoading(false);
        setConsultasRealizadas((prev) => prev + 1);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'promocoes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadPromocoes();
    }, 600);
  }, [order, funcao, refresh]);

  const handleExecFilter = () => {
    if (!filtrosPreenchidos) {
      toast.warn('Por favor, preencha todos os filtros.');
      return;
    }
    setLoading(true);
    setRefresh(!refresh);
  };

  const renderChartBar = () => (
    <ChartContainer
      style={{
        minHeight: 500,
        height: promocoes ? promocoes.length * 50 : {},
      }}>
      <ResponsiveBar
        data={promocoes}
        theme={chatTheme}
        layout="horizontal"
        enableGridX={false}
        enableGridY={false}
        groupMode="grouped"
        // keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
        keys={['Pontos Clube', 'Pontos Geral']}
        indexBy="dataFormatted"
        margin={{ top: 10, right: 50, bottom: 100, left: 100 }}
        padding={0.3}
        // innerPadding={0}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }}
        colors={[colors.blue500, colors.gray400]}
        axisTop={null}
        axisRight={null}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Pontos',
          legendPosition: 'middle',
          legendOffset: 40,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Datas',
          legendPosition: 'middle',
          legendOffset: -90,
          truncateTickAt: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 100,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        // role="application"
        // ariaLabel="Timeline"
        // barAriaLabel={(e) =>
        //   `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`
        // }
      />
    </ChartContainer>
  );

  return (
    <MainContainer>
      <Title>Histórico de Promoções</Title>

      <Header>
        <FieldGroup style={{ flex: 1 }}>
          <Field>
            <AsyncCombo
              name="programa_fidel"
              route="programas-fidel"
              placeholder="Programas de Fidelidade"
              onChange={setProgramaFidelId}
              params={{
                pageLimit: 1000,
              }}
            />
          </Field>
          <Field>
            <AsyncCombo
              name="parceiro"
              route="parceiros"
              placeholder="Parceiros"
              onChange={setParceiroId}
              params={{
                pageLimit: 1000,
              }}
            />
          </Field>

          <Field flex={0.7}>
            <Select
              name="periodoEmMeses"
              className="basic-single"
              classNamePrefix="select"
              placeholder="Histórico"
              options={[
                { value: 6, label: '6 meses' },
                { value: 12, label: '1 ano' },
                { value: 24, label: '2 anos' },
              ]}
              onChange={(selectedOption) =>
                setPeriodoEmMeses(
                  selectedOption ? selectedOption.value : undefined
                )
              }
              theme={theme}
              styles={styles}
            />
          </Field>
          <Field>
            <Button
              primary
              type="button"
              onClick={handleExecFilter}
              disabled={!filtrosPreenchidos || loading}>
              {loading ? (
                <ThreeDots
                  height="22"
                  width="22"
                  radius="9"
                  color={colors.textLight}
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible
                />
              ) : (
                <MagnifyingGlass
                  color={colors.textLight}
                  weight="light"
                  size={22}
                />
              )}
              {/* <span>{loading ? 'Consultando...' : 'Consultar'}</span> */}
              <span>Consultar</span>
            </Button>
          </Field>
        </FieldGroup>
      </Header>

      {promocoes.length === 0 ? (
        <NoDataTextContainer>
          {consultasRealizadas > 0 ? (
            <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
          ) : (
            <NoDataText>
              Preencha os filtros acima para consultar os dados.
            </NoDataText>
          )}
        </NoDataTextContainer>
      ) : (
        <>
          {total && <ResultTitle>{`Promoções: ${total}`}</ResultTitle>}

          {renderChartBar()}
        </>
      )}
    </MainContainer>
  );
}

export default PromocaoHistorico;
