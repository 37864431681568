import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { Form } from '@unform/web';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

import { theme, styles } from '~/config/reactSelect';

import Input from '~/components/Input';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';
import IntegerInput from '../../../components/Inputs/IntegerInput';

// import { } from './styles';

function PromocaoForm({ location }) {
  const timerProgramaFidel = useRef(null);
  const timerParceiro = useRef(null);

  const [promocao] = useState(location.promocao);

  const [programaFidelId, setProgramaFidelId] = useState(
    promocao && promocao.programa_fidel_id
  );
  const [parceiroId, setParceirolId] = useState(
    promocao && promocao.parceiro_id
  );
  const [data, setData] = useState(
    promocao && promocao.data
      ? format(parseISO(promocao.data), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  );
  const [pontosClube, setPontosClube] = useState(
    promocao ? promocao.pontos_clube : 0
  );
  const [pontosGeral, setPontosGeral] = useState(
    promocao ? promocao.pontos_geral : 0
  );

  const isEditing = history.location.pathname === '/promocoes/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'promocoes:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !promocao) {
      history.goBack();
    }
  }, [promocao, isEditing, denied]);

  /**
   * Async Select - Parceiros
   */
  async function loadParceiros(filter) {
    const response = await api.get('parceiros', {
      params: { q: filter },
    });

    const parceiros = response.data.map((parceiro) => {
      return { value: parceiro.id, label: parceiro.nome };
    });

    return parceiros;
  }

  const loadParceiroOptions = (inputValue, callback) => {
    clearTimeout(timerParceiro.current);
    timerParceiro.current = setTimeout(async () => {
      callback(await loadParceiros(inputValue));
    }, 600);
  };

  const handleChangeParceiro = (selectedOptions) => {
    setParceirolId(selectedOptions.value);
  };

  /**
   * Programas de Fidelidade
   */
  async function loadProgramasFidel(filter) {
    const response = await api.get('programas-fidel', {
      params: { q: filter },
    });

    const programasFidel = response.data.map((programaFidel) => {
      return { value: programaFidel.id, label: programaFidel.nome };
    });

    return programasFidel;
  }

  const loadProgramaFidelOptions = (inputValue, callback) => {
    clearTimeout(timerProgramaFidel.current);
    timerProgramaFidel.current = setTimeout(async () => {
      callback(await loadProgramasFidel(inputValue));
    }, 600);
  };

  const handleChangeProgramaFidel = (selectedOptions) => {
    setProgramaFidelId(selectedOptions.value);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save() {
    const payload = {
      data,
      programa_fidel_id: programaFidelId,
      parceiro_id: parceiroId,
      pontos_clube: pontosClube,
      pontos_geral: pontosGeral,
    };

    try {
      if (promocao) {
        await api.put(`promocoes/${promocao.id}`, payload); // Edit
      } else {
        await api.post('promocoes', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/promocoes');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = () => {
    if (!isEditing && !checkPermission(funcao, 'promocoes:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'promocoes:edit')) {
      return;
    }

    save();
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form onSubmit={handleSubmit} initialData={{}}>
          <FormHeader>
            <Title>Cadastro de Promoção</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'promocoes:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field flex="none">
              <label htmlFor="data">Data</label>
              <Input
                name="data"
                type="date"
                autoComplete="off"
                value={data || ''}
                onChange={(e) => setData(e.target.value)}
                required
                autoFocus
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="programa_fidel">Programa de Fidelidade</label>
              <AsyncSelect
                name="programa_fidel"
                placeholder="Selecione o programa de fidelidade..."
                cacheOptions
                loadOptions={loadProgramaFidelOptions}
                defaultOptions
                onChange={handleChangeProgramaFidel}
                defaultValue={
                  promocao &&
                  promocao.programa_fidel && {
                    value: promocao.programa_fidel.id,
                    label: promocao.programa_fidel.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="parceiro">Parceiro</label>
              <AsyncSelect
                name="parceiro"
                placeholder="Selecione o parceiro..."
                cacheOptions
                loadOptions={loadParceiroOptions}
                defaultOptions
                onChange={handleChangeParceiro}
                defaultValue={
                  promocao &&
                  promocao.parceiro && {
                    value: promocao.parceiro.id,
                    label: promocao.parceiro.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <IntegerInput
                name="pontos_clube"
                label="Pontos para Assinantes do Clube"
                value={pontosClube}
                onChange={setPontosClube}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <IntegerInput
                name="pontos"
                label="Pontos para demais clientes"
                value={pontosGeral}
                onChange={setPontosGeral}
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

PromocaoForm.propTypes = {
  location: PropTypes.shape(),
};

PromocaoForm.defaultProps = {
  location: null,
};

export default PromocaoForm;
