import styled from 'styled-components';
import colors from '~/styles/colors';

export const VideoGrid = styled.div`
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;

export const VideoWrapper = styled.div`
  /* position: relative; // Adicione um posicionamento relativo para que o ícone "play" fique relativo a este contêiner */
  border: 0;
  transition: opacity 0.2s;
  :hover {
    opacity: 0.7;
  }
`;

export const ThumbnailContainer = styled.button`
  /* position: relative; // Adicione um posicionamento relativo para que o ícone "play" fique relativo a este contêiner */
  background: transparent;
  width: 100%;
  border: 0;

  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.1);

    /* Extra small devices (phones, 600px and down) */
    /* screen width <= 600 pixels  */
    @media only screen and (max-width: 600px) {
      border-radius: 0;
    }
  }
`;

export const InfoContainer = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;

  strong {
    font-size: 1.125rem;
    color: ${colors.gray300};
    line-height: 1.4;
  }

  span {
    font-size: 0.875rem;
    color: ${colors.gray500};
    line-height: 1.6;
  }
`;
