import { takeLatest, call, put, all } from 'redux-saga/effects';
import { showError } from '~/lib/toastHelper';

// import history from '~/services/history';
import api from '~/services/api';

import { refreshFuncaoSuccess } from './actions';
import { signOut } from '../auth/actions';

export function* refreshFuncao() {
  try {
    const response = yield call(api.get, 'usuarios/profile');

    const { funcao } = response.data;

    yield put(refreshFuncaoSuccess(funcao));
  } catch (err) {
    if (err.message === 'Network Error') {
      showError(
        'Não foi possível conectar aos nossos servidores. Verifique seu acesso à internet e tente novamente.'
      );
      yield put(signOut());
    }
    if (err.response && err.response.status === 401) {
      showError('Sessão expirada. Faça login novamente.', err);
      yield put(signOut());
    }
  }
}

export default all([
  takeLatest('@funcao/REFRESH_FUNCAO_REQUEST', refreshFuncao),
]);
