import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import { LinkButton } from '~/components/LinkButton';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import UsuarioStatus from './UsuarioStatus';

import { Header } from './styles';

function UsuarioList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'nome', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadUsuarios(f) {
      try {
        setLoading(true);
        const response = await api.get('usuarios', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: (order.asc ? 'ASC' : 'DESC').concat(
              order.field === 'ultimo_login' && !order.asc
                ? ' NULLS LAST'
                : ' NULLS FIRST'
            ),
          },
        });

        const data = response.data.map((u) => {
          return {
            ...u,
            funcaoFormatted:
              u.funcao.charAt(0).toUpperCase() + u.funcao.slice(1),
            ultimo_login_formatted:
              u.ultimo_login &&
              format(parseISO(u.ultimo_login), 'dd/MM/yyyy HH:mm'),
            created_at_formatted:
              u.createdAt && format(parseISO(u.createdAt), 'dd/MM/yyyy HH:mm'),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setUsuarios(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'usuarios:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadUsuarios(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/usuarios/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleEdit(id) {
    const usuarioToEdit = usuarios.find((d) => d.id === id);
    if (usuarioToEdit) {
      history.push({
        pathname: '/usuarios/edit',
        usuario: usuarioToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo inativar o usuário?')) {
      return;
    }

    try {
      await api.delete(`usuarios/${id}`);
      toast.success('Usuário inativado com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao inativar usuário! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Usuários {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome, e-mail, perfil"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="usuarios:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="E-Mail"
                showOrder={order.field === 'email'}
                asc={order.asc}
                onClick={() => handleChangeOrder('email')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Perfil"
                showOrder={order.field === 'funcao'}
                asc={order.asc}
                onClick={() => handleChangeOrder('funcao')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Status"
                showOrder={order.field === 'ativo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('ativo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Nº de logins"
                showOrder={order.field === 'qtde_login'}
                asc={order.asc}
                onClick={() => handleChangeOrder('qtde_login')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Último login"
                showOrder={order.field === 'ultimo_login'}
                asc={order.asc}
                onClick={() => handleChangeOrder('ultimo_login')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Criado por"
                showOrder={order.field === 'created_by'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_by')}
              />
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.id}>
              {/* <td>
                <Avatar url={usuario.avatar && usuario.avatar.url} name={usuario.nome} />
              </td> */}
              <td>{usuario.nome}</td>
              <td>
                <Can
                  funcao={funcao}
                  perform="usuarios:edit"
                  yes={() => (
                    <LinkButton onClick={() => handleEdit(usuario.id)}>
                      {usuario.email}
                    </LinkButton>
                  )}
                  no={() => <>{usuario.email}</>}
                />
              </td>
              <td>{usuario.funcaoFormatted}</td>
              <td>
                <UsuarioStatus usuario={usuario} />
              </td>
              <td>{usuario.qtde_login || '0'}</td>
              <td>{usuario.ultimo_login_formatted || 'Nunca fez login'}</td>
              <td>{usuario.created_at_formatted}</td>
              <td>{usuario.createdBy && usuario.createdBy.nome}</td>
              <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="usuarios:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(usuario.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(usuario.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="usuarios:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(usuario.id)}>
                          Inativar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Inativar
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && usuarios.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default UsuarioList;
