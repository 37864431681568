import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  background: ${colors.gray800};
  /* background: lightcoral; */
  border-radius: 10px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 8px;
  /* width: 100%; */
  /* height: 300px; */
`;

export const ButtonContainer = styled.div`
  /* background: lightcoral; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.h1`
  font-size: 16px;
  color: ${colors.gray300};
`;
