import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../Input';

const integerFormatter = new Intl.NumberFormat('pt-BR', { style: 'decimal' });

const IntegerInput = ({
  value,
  onChange,
  name,
  label,
  maxLength,
  disabled,
  infoTooltip,
}) => {
  // const [isFocused, setIsFocused] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    integerFormatter.format(value)
  );

  // Permite alterar value fora do componente
  useEffect(() => {
    setDisplayValue(integerFormatter.format(value));
  }, [value]);

  const handleChange = (rawValue) => {
    const parsedValue = Number(rawValue.replace(/[^\d]/g, ''));
    const formattedValue = integerFormatter.format(parsedValue);

    setDisplayValue(formattedValue);
    onChange(parsedValue);
  };

  return (
    <Input
      type="text"
      name={name}
      label={label}
      maxLength={maxLength}
      autoComplete="off"
      // onFocus={() => setIsFocused(true)}
      // onBlur={() => setIsFocused(false)}
      value={displayValue}
      onChange={(e) => handleChange(e.target.value)}
      disabled={disabled}
      infoTooltip={infoTooltip}
    />
  );
};

IntegerInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  infoTooltip: PropTypes.element,
};

IntegerInput.defaultProps = {
  value: 0,
  label: '',
  maxLength: 18,
  disabled: false,
  infoTooltip: null,
};

export default IntegerInput;
