import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Logo from '~/components/Logo';
import Can from '~/components/Can';

import { signOut } from '~/store/modules/auth/actions';
import {
  Container,
  Nav,
  NavList,
  Menu,
  MenuLine1,
  MenuLine2,
  MenuLine3,

  // Profile,
  HeaderLink,
  LogoWrapper,
  DropdownMenu,
  DropdownContent,
  Profile,
} from './styles';

const menusCustoMedio = [
  {
    label: 'Titulares',
    route: '/titulares',
    perform: 'titulares:visit',
  },
  {
    label: 'Operações',
    route: '/operacoes',
    perform: 'operacoes:visit',
  },
  {
    label: 'Saldos',
    route: '/pontos-saldo',
    perform: 'pontos-saldo:visit',
  },
  {
    label: 'Saldos de CPF',
    route: '/saldo-cpf',
    perform: 'pontos-saldo:visit',
  },
  {
    label: 'Histórico',
    route: '/pontos-mov',
    perform: 'pontos-mov:visit',
  },
  {
    label: 'Pendências',
    route: '/pontos-mov-prev',
    perform: 'pontos-mov:visit',
  },
];

const menusCadastros = [
  {
    label: 'Aeroportos',
    route: '/aeroportos',
    perform: 'aeroportos:visit',
  },
  {
    label: 'Companhias Aéreas',
    route: '/cias-aereas',
    perform: 'cias-aereas:visit',
  },
  {
    label: 'Cidades',
    route: '/cidades',
    perform: 'cidades:visit',
  },
  {
    label: 'Emissões',
    route: '/emissoes',
    perform: 'emissoes:visit',
  },
  {
    label: 'Estados',
    route: '/estados',
    perform: 'estados:visit',
  },
  {
    label: 'Países',
    route: '/paises',
    perform: 'paises:visit',
  },
  {
    label: 'Parceiros',
    route: '/parceiros',
    perform: 'parceiros:visit',
  },
  {
    label: 'Programas de Fidelidade',
    route: '/programas-fidel',
    perform: 'programas-fidel:visit',
  },
  {
    label: 'Promoções em Parceiros',
    route: '/promocoes',
    perform: 'promocoes:visit',
  },
  {
    label: 'Regiões',
    route: '/regioes',
    perform: 'regioes:visit',
  },
  {
    label: 'Usuários',
    route: '/usuarios',
    perform: 'usuarios:visit',
  },
];

function Header() {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario.profile);
  const funcao = useSelector((state) => state.funcao.funcao);
  const [currentRoute, setCurrentRoute] = useState('timeline');

  const [isShowingMenu, setIsShowingMenu] = useState(false);

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleToggleMenu = () => {
    setIsShowingMenu((prevState) => !prevState);
  };

  const handleNavigate = (route) => {
    setIsShowingMenu(false);
    setCurrentRoute(route);
  };

  return (
    <Container>
      <Nav>
        <Link to="/">
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>

        <Menu onClick={handleToggleMenu}>
          <MenuLine1 active={isShowingMenu} />
          <MenuLine2 active={isShowingMenu} />
          <MenuLine3 active={isShowingMenu} />
        </Menu>

        <NavList visible={isShowingMenu}>
          <Can
            funcao={funcao}
            perform="timeline:visit"
            yes={() => (
              <li>
                <HeaderLink active={currentRoute === 'timeline'}>
                  <Link
                    to="/timeline"
                    onClick={() => handleNavigate('timeline')}>
                    Timeline
                  </Link>
                </HeaderLink>
              </li>
            )}
          />

          <Can
            funcao={funcao}
            perform="promocoes:visit"
            yes={() => (
              <li>
                <HeaderLink active={currentRoute === 'promocoes-historico'}>
                  <Link
                    to="/promocoes/historico"
                    onClick={() => handleNavigate('promocoes-historico')}>
                    Histórico de Promoções
                  </Link>
                </HeaderLink>
              </li>
            )}
          />

          <Can
            funcao={funcao}
            perform="timeline:visit"
            yes={() => (
              <li>
                <DropdownMenu>
                  <HeaderLink
                    active={menusCustoMedio
                      .map((menu) => menu.route)
                      .includes(currentRoute)}>
                    Custo Médio
                  </HeaderLink>

                  <DropdownContent>
                    {menusCustoMedio.map(({ label, perform, route }) => (
                      <Can
                        key={`${label}_${route}`}
                        funcao={funcao}
                        perform={perform}
                        yes={() => (
                          <HeaderLink active={currentRoute === route}>
                            <Link
                              to={route}
                              onClick={() => handleNavigate(route)}>
                              {label}
                            </Link>
                          </HeaderLink>
                        )}
                      />
                    ))}
                  </DropdownContent>
                </DropdownMenu>
              </li>
            )}
          />

          <Can
            funcao={funcao}
            perform="curadoria:visit"
            yes={() => (
              <li>
                <HeaderLink active={currentRoute === 'curadoria'}>
                  <Link
                    to="/curadoria"
                    onClick={() => handleNavigate('curadoria')}>
                    Curadoria
                  </Link>
                </HeaderLink>
              </li>
            )}
          />

          <li>
            <HeaderLink active={currentRoute === 'ajuda'}>
              <Link to="/ajuda" onClick={() => handleNavigate('ajuda')}>
                Ajuda
              </Link>
            </HeaderLink>
          </li>

          <Can
            funcao={funcao}
            perform="cadastros:visit"
            yes={() => (
              <li>
                <DropdownMenu>
                  <HeaderLink
                    active={menusCadastros
                      .map((menu) => menu.route)
                      .includes(currentRoute)}>
                    Administrativo
                  </HeaderLink>

                  <DropdownContent>
                    {menusCadastros.map(({ label, perform, route }) => (
                      <Can
                        key={`${label}_${route}`}
                        funcao={funcao}
                        perform={perform}
                        yes={() => (
                          <HeaderLink active={currentRoute === route}>
                            <Link
                              to={route}
                              onClick={() => handleNavigate(route)}>
                              {label}
                            </Link>
                          </HeaderLink>
                        )}
                      />
                    ))}
                  </DropdownContent>
                </DropdownMenu>
              </li>
            )}
          />

          {usuario && (
            <li>
              <Profile>
                <HeaderLink active={currentRoute === 'profile'}>
                  <Link
                    to="/profile/edit"
                    onClick={() => handleNavigate('profile')}>
                    {usuario.nome}
                  </Link>
                </HeaderLink>
              </Profile>
            </li>
          )}

          <li>
            <HeaderLink>
              <Link to="/" onClick={handleSignOut}>
                Sair
              </Link>
            </HeaderLink>
          </li>
        </NavList>
      </Nav>

      {/* <aside>
          <VersionContainer>v1.0.2</VersionContainer>
          <Profile>
            <div>
              <Link to="/profile/edit">
                <strong>{usuario && usuario.nome}</strong>
              </Link>
              <Link to="/" onClick={handleSignOut}>
                Sair
              </Link>
            </div>
          </Profile>
        </aside> */}
      {/* </Content> */}
    </Container>
  );
}

export default Header;
