import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Form } from '@unform/web';
import { MdDone, MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { theme, styles } from '~/config/reactSelect';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { MainContainer } from '~/components/MainContainer';
import CurrencyInput from '~/components/Inputs/CurrencyInput';
import IntegerInput from '~/components/Inputs/IntegerInput';
import Input from '~/components/Input';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import { showError, showSuccess } from '~/lib/toastHelper';
import { format, parseISO } from 'date-fns';

// import { } from './styles';

function PontosMovForm({ location }) {
  const [mov] = useState(location.mov);
  const [isPrevisao] = useState(location.isPrevisao);
  const [route] = useState(
    location.isPrevisao ? 'pontos-mov-prev' : 'pontos-mov'
  );

  const isEditing = history.location.pathname === `/${route}/edit`;

  const timerTitular = useRef(null);
  const timerProgramaFidel = useRef(null);
  const [titularId, setTitularId] = useState(mov && mov.titular_id);
  const [programaFidelId, setProgramaFidelId] = useState(
    mov && mov.programa_fidel_id
  );
  const [tipoMov, setTipoMov] = useState(mov ? mov.tipo : 1);
  const [dataMov, setDataMov] = useState(
    mov && mov.data
      ? format(parseISO(mov.data), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  );
  const [custo, setCusto] = useState(mov ? mov.custo : 0);
  const [qtde, setQtde] = useState(mov ? mov.qtde : 0);

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'pontos-mov:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !mov) {
      history.goBack();
    }
  }, [mov, isEditing, denied]);

  /**
   * Async Select - Titulares
   */
  async function loadTitulares(filter) {
    const response = await api.get('titulares', {
      params: { q: filter },
    });

    const titulares = response.data.map((titular) => {
      return { value: titular.id, label: titular.nome };
    });

    return titulares;
  }

  const loadTitularOptions = (inputValue, callback) => {
    clearTimeout(timerTitular.current);
    timerTitular.current = setTimeout(async () => {
      callback(await loadTitulares(inputValue));
    }, 600);
  };

  const handleChangeTitular = (selectedOptions) => {
    setTitularId(selectedOptions.value);
  };

  /**
   * Programas de Fidelidade
   */
  async function loadProgramasFidel(filter) {
    const response = await api.get('programas-fidel', {
      params: { q: filter },
    });

    const programasFidel = response.data.map((programaFidel) => {
      return { value: programaFidel.id, label: programaFidel.nome };
    });

    return programasFidel;
  }

  const loadProgramaFidelOptions = (inputValue, callback) => {
    clearTimeout(timerProgramaFidel.current);
    timerProgramaFidel.current = setTimeout(async () => {
      callback(await loadProgramasFidel(inputValue));
    }, 600);
  };

  const handleChangeProgramaFidel = (selectedOptions) => {
    setProgramaFidelId(selectedOptions.value);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ titular_id, programa_fidel_id, data, tipo, resumo }) {
    const payload = {
      titular_id,
      programa_fidel_id,
      data: data || null,
      tipo: tipo || null,
      qtde,
      custo,
      resumo: resumo || null,
    };

    try {
      if (mov) {
        await api.put(`${route}/${mov.id}`, payload);
      } else {
        await api.post(route, payload);
      }

      showSuccess(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      history.push(`/${route}`);
    } catch (err) {
      showError('Falha ao salvar!', err);
    }
  }

  const handleSubmit = ({ resumo }) => {
    if (!isEditing && !checkPermission(funcao, 'pontos-mov:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'pontos-mov:edit')) {
      return;
    }

    save({
      titular_id: titularId,
      programa_fidel_id: programaFidelId,
      data: dataMov,
      tipo: tipoMov,
      qtde,
      resumo,
    });
  };

  const handleChangeTipo = (selectedOptions) => {
    setTipoMov(selectedOptions.value);

    // Não permite informar custo em saídas
    if (selectedOptions.value === -1) {
      setCusto(0);
      setQtde(0);
    }
  };

  return (
    <MainContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            resumo: mov && mov.resumo,
          }}>
          <FormHeader>
            {isPrevisao ? (
              <Title>Cadastro de Pendência</Title>
            ) : (
              <Title>Cadastro de Histórico</Title>
            )}

            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'pontos-mov:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="titular">Titular</label>
              <AsyncSelect
                autoFocus
                name="titular"
                placeholder="Selecione o titular..."
                cacheOptions
                loadOptions={loadTitularOptions}
                defaultOptions
                onChange={handleChangeTitular}
                defaultValue={
                  mov &&
                  mov.titular && {
                    value: mov.titular.id,
                    label: mov.titular.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="programa_fidel">Programa de Fidelidade</label>
              <AsyncSelect
                name="programa_fidel"
                placeholder="Selecione o programa de fidelidade..."
                cacheOptions
                loadOptions={loadProgramaFidelOptions}
                defaultOptions
                onChange={handleChangeProgramaFidel}
                defaultValue={
                  mov &&
                  mov.programa_fidel && {
                    value: mov.programa_fidel.id,
                    label: mov.programa_fidel.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="tipo">Tipo de Movimento</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={{ value: 1, label: 'Entrada' }}
                name="tipo"
                options={[
                  { value: 1, label: 'Entrada' },
                  ...(isPrevisao ? [] : [{ value: -1, label: 'Saída' }]),
                ]}
                onChange={handleChangeTipo}
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <Input
                name="data"
                label="Data"
                type="date"
                autoComplete="off"
                value={dataMov || ''}
                onChange={(e) => setDataMov(e.target.value)}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <IntegerInput
                name="qtde"
                label="Pontos"
                value={qtde}
                onChange={setQtde}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <CurrencyInput
                name="custo"
                label="Custo"
                value={custo}
                onChange={setCusto}
                disabled={tipoMov === -1}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <Input
                name="resumo"
                label="Observação"
                type="text"
                maxLength="255"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </MainContainer>
  );
}

PontosMovForm.propTypes = {
  location: PropTypes.shape(),
};

PontosMovForm.defaultProps = {
  location: null,
};

export default PontosMovForm;
