import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import ResetPassword from '../pages/ResetPassword';
import Dashboard from '../pages/Dashboard';
import UsuarioList from '../pages/Usuarios/UsuarioList';
import UsuarioForm from '../pages/Usuarios/UsuarioForm';
import ProfileForm from '../pages/Profile/ProfileForm';
import Denied from '../pages/Denied';
import EmissaoList from '../pages/Emissoes/EmissaoList';
import EmissaoForm from '../pages/Emissoes/EmissaoForm';
import AeroportoList from '../pages/Aeroportos/AeroportoList';
import AeroportoForm from '../pages/Aeroportos/AeroportoForm';
import RegiaoList from '../pages/Regioes/RegiaoList';
import RegiaoForm from '../pages/Regioes/RegiaoForm';
import EstadoList from '../pages/Estados/EstadoList';
import EstadoForm from '../pages/Estados/EstadoForm';
import CidadeList from '../pages/Cidades/CidadeList';
import CidadeForm from '../pages/Cidades/CidadeForm';
import PaisList from '../pages/Paises/PaisList';
import CiaAereaList from '../pages/CiasAereas/CiaAereaList';
import CiaAereaForm from '../pages/CiasAereas/CiaAereaForm';
import ProgramaFidelList from '../pages/ProgramasFidel/ProgramaFidelList';
import ProgramaFidelForm from '../pages/ProgramasFidel/ProgramaFidelForm';
import TimelineDashboard from '../pages/Timeline/TimelineDashboard';
import TimelinePorOrigem from '../pages/Timeline/TimelinePorOrigem';
import TimelinePorDestino from '../pages/Timeline/TimelinePorDestino';
import TimelinePorProgramaFidel from '../pages/Timeline/TimelinePorProgramaFidel';
import TimelinePorSaldoOportunidade from '../pages/Timeline/TimelinePorSaldoOportunidade';
import Curadoria from '../pages/Curadoria';
import ExibirVideo from '../pages/Curadoria/ExibirVideo';
import Ajuda from '../pages/Ajuda';
import TitularList from '../pages/Titulares/TitularList';
import TitularForm from '../pages/Titulares/TitularForm';
import PontosMovList from '../pages/PontosMov/PontosMovList';
import PontosMovForm from '../pages/PontosMov/PontosMovForm';
import PontosSaldoList from '../pages/PontosSaldo/PontosSaldoList';
import PontosMovPrevList from '../pages/PontosMovPrev/PontosMovPrevList';
import OperacoesList from '../pages/Operacoes/OperacoesList';
import OperacoesForm from '../pages/Operacoes/OperacoesForm';
import ParceiroList from '../pages/Parceiros/ParceiroList';
import ParceiroForm from '../pages/Parceiros/ParceiroForm';
import SaldoCpfList from '../pages/SaldoCpf/SaldoCpfList';
import PromocaoList from '../pages/Promocoes/PromocaoList';
import PromocaoForm from '../pages/Promocoes/PromocaoForm';
import PromocaoHistorico from '../pages/Promocoes/PromocaoHistorico';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/reset-password" exact component={ResetPassword} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/denied" exact component={Denied} isPrivate />

      {/* Timeline */}
      <Route path="/timeline" exact component={TimelineDashboard} isPrivate />
      <Route
        path="/timeline/por-origem"
        exact
        component={TimelinePorOrigem}
        isPrivate
      />
      <Route
        path="/timeline/por-destino"
        exact
        component={TimelinePorDestino}
        isPrivate
      />
      <Route
        path="/timeline/por-programa-fidel"
        exact
        component={TimelinePorProgramaFidel}
        isPrivate
      />
      <Route
        path="/timeline/por-saldo-oportunidade"
        exact
        component={TimelinePorSaldoOportunidade}
        isPrivate
      />

      {/* Curadoria */}
      <Route path="/curadoria" exact component={Curadoria} isPrivate />
      <Route path="/curadoria/video" exact component={ExibirVideo} isPrivate />

      {/* Ajuda */}
      <Route path="/ajuda" exact component={Ajuda} isPrivate />

      {/* Usuarios */}
      <Route path="/usuarios" exact component={UsuarioList} isPrivate />
      <Route path="/usuarios/create" exact component={UsuarioForm} isPrivate />
      <Route path="/usuarios/edit" exact component={UsuarioForm} isPrivate />

      {/* Emissoes */}
      <Route path="/emissoes" exact component={EmissaoList} isPrivate />
      <Route path="/emissoes/create" exact component={EmissaoForm} isPrivate />
      <Route path="/emissoes/edit" exact component={EmissaoForm} isPrivate />

      {/* Promoções */}
      <Route path="/promocoes" exact component={PromocaoList} isPrivate />
      <Route
        path="/promocoes/create"
        exact
        component={PromocaoForm}
        isPrivate
      />
      <Route path="/promocoes/edit" exact component={PromocaoForm} isPrivate />
      <Route
        path="/promocoes/historico"
        exact
        component={PromocaoHistorico}
        isPrivate
      />

      {/* Aeroportos */}
      <Route path="/aeroportos" exact component={AeroportoList} isPrivate />
      <Route
        path="/aeroportos/create"
        exact
        component={AeroportoForm}
        isPrivate
      />
      <Route
        path="/aeroportos/edit"
        exact
        component={AeroportoForm}
        isPrivate
      />

      {/* Países */}
      <Route path="/paises" exact component={PaisList} isPrivate />

      {/* Regições */}
      <Route path="/regioes" exact component={RegiaoList} isPrivate />
      <Route path="/regioes/create" exact component={RegiaoForm} isPrivate />
      <Route path="/regioes/edit" exact component={RegiaoForm} isPrivate />

      {/* Estados */}
      <Route path="/estados" exact component={EstadoList} isPrivate />
      <Route path="/estados/create" exact component={EstadoForm} isPrivate />
      <Route path="/estados/edit" exact component={EstadoForm} isPrivate />

      {/* Cidades */}
      <Route path="/cidades" exact component={CidadeList} isPrivate />
      <Route path="/cidades/create" exact component={CidadeForm} isPrivate />
      <Route path="/cidades/edit" exact component={CidadeForm} isPrivate />

      {/* Cias Aéreas */}
      <Route path="/cias-aereas" exact component={CiaAereaList} isPrivate />
      <Route
        path="/cias-aereas/create"
        exact
        component={CiaAereaForm}
        isPrivate
      />
      <Route
        path="/cias-aereas/edit"
        exact
        component={CiaAereaForm}
        isPrivate
      />

      {/* Programas Fidel */}
      <Route
        path="/programas-fidel"
        exact
        component={ProgramaFidelList}
        isPrivate
      />
      <Route
        path="/programas-fidel/create"
        exact
        component={ProgramaFidelForm}
        isPrivate
      />
      <Route
        path="/programas-fidel/edit"
        exact
        component={ProgramaFidelForm}
        isPrivate
      />

      <Route path="/profile/edit" exact component={ProfileForm} isPrivate />

      {/* Parceiros */}
      <Route path="/parceiros" exact component={ParceiroList} isPrivate />
      <Route
        path="/parceiros/create"
        exact
        component={ParceiroForm}
        isPrivate
      />
      <Route path="/parceiros/edit" exact component={ParceiroForm} isPrivate />

      {/* Titulares */}
      <Route path="/titulares" exact component={TitularList} isPrivate />
      <Route path="/titulares/create" exact component={TitularForm} isPrivate />
      <Route path="/titulares/edit" exact component={TitularForm} isPrivate />

      {/* Operações */}
      <Route path="/operacoes" exact component={OperacoesList} isPrivate />
      <Route
        path="/operacoes/create"
        exact
        component={OperacoesForm}
        isPrivate
      />
      {/* <Route path="/operacoes/edit" exact component={OperacoesForm} isPrivate /> */}

      {/* Pontos Mov */}
      <Route path="/pontos-mov" exact component={PontosMovList} isPrivate />
      <Route
        path="/pontos-mov/create"
        exact
        component={PontosMovForm}
        isPrivate
      />
      <Route
        path="/pontos-mov/edit"
        exact
        component={PontosMovForm}
        isPrivate
      />

      {/* Pontos Mov Previstos */}
      <Route
        path="/pontos-mov-prev"
        exact
        component={PontosMovPrevList}
        isPrivate
      />
      <Route
        path="/pontos-mov-prev/create"
        exact
        component={PontosMovForm}
        isPrivate
      />
      <Route
        path="/pontos-mov-prev/edit"
        exact
        component={PontosMovForm}
        isPrivate
      />

      {/* Pontos Saldo */}
      <Route path="/pontos-saldo" exact component={PontosSaldoList} isPrivate />

      {/* Saldo de CPFs */}
      <Route path="/saldo-cpf" exact component={SaldoCpfList} isPrivate />
    </Switch>
  );
}
