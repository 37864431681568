import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div``;

export const LinkContainer = styled.div`
  padding: 10px;
  margin-top: 10px;

  a {
    color: ${colors.gray500};
  }
`;
