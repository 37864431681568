import React, { useState, useEffect, useRef } from 'react';
import {
  MdAdd,
  MdEdit,
  MdVisibility,
  MdDeleteForever,
  MdRefresh,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { BaseContainer } from '~/components/BaseContainer';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';

import { Header } from './styles';

function RegiaoList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [regioes, setRegioes] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'nome', asc: true });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadRegioes(f) {
      try {
        setLoading(true);
        const response = await api.get('regioes', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((regiao) => {
          return {
            ...regiao,
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setRegioes(data);
        setLoading(false);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'regioes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadRegioes(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/regioes/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  function handleEdit(id) {
    const regiaoToEdit = regioes.find((regiao) => regiao.id === id);
    if (regiaoToEdit) {
      history.push({
        pathname: '/regioes/edit',
        regiao: regiaoToEdit,
      });
    }
  }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir a região?')) {
      return;
    }

    try {
      await api.delete(`regioes/${id}`);
      toast.success('Região excluída com sucesso!');
      reload();
    } catch (err) {
      toast.error(
        <div>
          Falha ao excluir região! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Regiões {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="nome"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          <Can
            funcao={funcao}
            perform="regioes:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          />
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Nome"
                showOrder={order.field === 'nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('nome')}
              />
            </th>
            <th>País</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {regioes.map((regiao) => (
            <tr key={regiao.id}>
              <td>{regiao.nome}</td>
              <td>{regiao.pais && regiao.pais.nome}</td>
              <td>
                <Actions>
                  <Can
                    funcao={funcao}
                    perform="regioes:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(regiao.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(regiao.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  />

                  <Can
                    funcao={funcao}
                    perform="regioes:delete"
                    yes={() => (
                      <li>
                        <MdDeleteForever color="#DE3B3B" size={16} />
                        <button
                          type="button"
                          onClick={() => handleDelete(regiao.id)}>
                          Excluir
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdDeleteForever
                          color={colors.textDisabled}
                          size={16}
                        />
                        <button type="button" disabled>
                          Excluir
                        </button>
                      </li>
                    )}
                  />
                </Actions>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && regioes.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default RegiaoList;
