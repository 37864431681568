const formatarNumero = (numero, zeros_direita, usar_virgula) => {
  const novoNumero = parseFloat(`${numero}`);
  if (usar_virgula) {
    return novoNumero.toFixed(zeros_direita).replace('.', ',');
  }
  return novoNumero.toFixed(zeros_direita);
};

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormatterPrecision4 = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

const integerFormatter = new Intl.NumberFormat('pt-BR', { style: 'decimal' });

const currencyToString = (value) => currencyFormatter.format(value);
const currencyPrecision4ToString = (value) =>
  currencyFormatterPrecision4.format(value);

const integerToString = (value) => integerFormatter.format(value);

export {
  formatarNumero,
  currencyFormatter,
  currencyToString,
  integerToString,
  currencyPrecision4ToString,
};
