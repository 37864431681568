import React from 'react';
import { toast } from 'react-toastify';
import getErrorMessage from './getErrorMessage';
import colors from '../styles/colors';

const toastDefaultStyle = {
  borderRadius: 16,
  padding: 24,
};

export function showError(message, err) {
  toast.error(
    <div>
      <strong>{message || 'Falhou... :('}</strong>

      {err && (
        <>
          <br />
          <br />
          {getErrorMessage(err)}
        </>
      )}
    </div>,
    {
      style: {
        ...toastDefaultStyle,
        backgroundColor: colors.red500,
      },
    }
  );
}

export function showSuccess(message) {
  toast.success(
    <div>
      <strong>{message || 'Sucesso!'}</strong>
    </div>,

    {
      style: {
        ...toastDefaultStyle,
        backgroundColor: colors.green500,
      },
    }
  );
}
