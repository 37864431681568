import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  color: ${(props) => {
    switch (props.active) {
      case true:
        return colors.green500;
      case false:
        return colors.red500;
      default:
        return colors.gray500;
    }
  }};

  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  display: inline-block;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;

    background: ${(props) => {
      switch (props.active) {
        case true:
          return colors.green500;
        case false:
          return colors.red500;
        default:
          return colors.gray500;
      }
    }};
  }
`;
