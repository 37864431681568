import React, { useState, useRef, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { classesVooArray } from '~/config/classesVoo';
import { FieldGroup, Field } from '~/components/Forms';
import { Button } from '~/components/Button';
// import { Title } from '~/components/Title';
import { theme, styles } from '../../config/reactSelect';

import { Container, ButtonContainer, Title } from './styles';

const iniFilter = {
  origem_pais_id: undefined,
  origem_regiao_id: undefined,
  origem_estado_id: undefined,
  origem_cidade_id: undefined,
  destino_pais_id: undefined,
  destino_regiao_id: undefined,
  destino_estado_id: undefined,
  destino_cidade_id: undefined,
  cia_aerea_id: undefined,
  programa_fidel_id: undefined,
  classe: undefined,
  internacional: undefined,
};

function FilterPanelEmissoes({
  onFilterChange,
  showPaisOrigem,
  showRegiaoOrigem,
  showEstadoOrigem,
  showCidadeOrigem,
  showPaisDestino,
  showRegiaoDestino,
  showEstadoDestino,
  showCidadeDestino,
  showCiaAerea,
  showProgramaFidel,
  showClasse,
  showInternacional,
}) {
  const refPaisOrigem = useRef(null);
  const refRegiaoOrigem = useRef(null);
  const refEstadoOrigem = useRef(null);
  const refCidadeOrigem = useRef(null);
  const refPaisDestino = useRef(null);
  const refRegiaoDestino = useRef(null);
  const refEstadoDestino = useRef(null);
  const refCidadeDestino = useRef(null);
  const refCiaAerea = useRef(null);
  const refProgramaFidel = useRef(null);
  const refClasse = useRef(null);
  const refInternacional = useRef(null);

  const timerPaisOrigem = useRef(null);
  const timerRegiaoOrigem = useRef(null);
  const timerEstadoOrigem = useRef(null);
  const timerCidadeOrigem = useRef(null);
  const timerPaisDestino = useRef(null);
  const timerRegiaoDestino = useRef(null);
  const timerEstadoDestino = useRef(null);
  const timerCidadeDestino = useRef(null);
  const timerCiaAerea = useRef(null);
  const timerProgramaFidel = useRef(null);

  const [filter, setFilter] = useState({ ...iniFilter });

  const classesOptions = classesVooArray().map((classeVoo) => ({
    value: classeVoo.codigo,
    label: classeVoo.nomeCurto,
  }));

  const buttonDisabled = useMemo(() => {
    return JSON.stringify(filter) === JSON.stringify(iniFilter);
  }, [filter]);

  /**
   * Async Select - Países
   */
  async function loadPaises(filterText) {
    const response = await api.get('paises', {
      params: { q: filterText },
    });

    const paises = response.data.map((pais) => {
      return { value: pais.id, label: pais.nome };
    });

    return paises;
  }

  const loadPaisOrigemOptions = (inputValue, callback) => {
    clearTimeout(timerPaisOrigem.current);
    timerPaisOrigem.current = setTimeout(async () => {
      callback(await loadPaises(inputValue));
    }, 600);
  };

  const loadPaisDestinoOptions = (inputValue, callback) => {
    clearTimeout(timerPaisDestino.current);
    timerPaisDestino.current = setTimeout(async () => {
      callback(await loadPaises(inputValue));
    }, 600);
  };

  const handleChangePaisOrigem = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        origem_pais_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  const handleChangePaisDestino = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        destino_pais_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Async Select - Regiões
   */
  async function loadRegioes(filterText) {
    const response = await api.get('regioes', {
      params: { q: filterText },
    });

    const regioes = response.data.map((regiao) => {
      return { value: regiao.id, label: regiao.nome };
    });

    return regioes;
  }

  const loadRegiaoOrigemOptions = (inputValue, callback) => {
    clearTimeout(timerRegiaoOrigem.current);
    timerRegiaoOrigem.current = setTimeout(async () => {
      callback(await loadRegioes(inputValue));
    }, 600);
  };

  const loadRegiaoDestinoOptions = (inputValue, callback) => {
    clearTimeout(timerRegiaoDestino.current);
    timerRegiaoDestino.current = setTimeout(async () => {
      callback(await loadRegioes(inputValue));
    }, 600);
  };

  const handleChangeRegiaoOrigem = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        origem_regiao_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  const handleChangeRegiaoDestino = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        destino_regiao_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Async Select - Estados
   */
  async function loadEstados(filterText) {
    const response = await api.get('estados', {
      params: { q: filterText },
    });

    const estados = response.data.map((estado) => {
      return { value: estado.id, label: estado.nome };
    });

    return estados;
  }

  const loadEstadoOrigemOptions = (inputValue, callback) => {
    clearTimeout(timerEstadoOrigem.current);
    timerEstadoOrigem.current = setTimeout(async () => {
      callback(await loadEstados(inputValue));
    }, 600);
  };

  const loadEstadoDestinoOptions = (inputValue, callback) => {
    clearTimeout(timerEstadoDestino.current);
    timerEstadoDestino.current = setTimeout(async () => {
      callback(await loadEstados(inputValue));
    }, 600);
  };

  const handleChangeEstadoOrigem = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        origem_estado_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  const handleChangeEstadoDestino = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        destino_estado_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Async Select - Cidades
   */
  async function loadCidades(filterText) {
    const response = await api.get('cidades', {
      params: { q: filterText },
    });

    const cidades = response.data.map((cidade) => {
      return { value: cidade.id, label: cidade.nome };
    });

    return cidades;
  }

  const loadCidadeOrigemOptions = (inputValue, callback) => {
    clearTimeout(timerCidadeOrigem.current);
    timerCidadeOrigem.current = setTimeout(async () => {
      callback(await loadCidades(inputValue));
    }, 600);
  };

  const loadCidadeDestinoOptions = (inputValue, callback) => {
    clearTimeout(timerCidadeDestino.current);
    timerCidadeDestino.current = setTimeout(async () => {
      callback(await loadCidades(inputValue));
    }, 600);
  };

  const handleChangeCidadeOrigem = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        origem_cidade_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  const handleChangeCidadeDestino = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        destino_cidade_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Async Select - Cias Aéreas
   */
  async function loadCiasAereas(filterText) {
    const response = await api.get('cias-aereas', {
      params: { q: filterText },
    });

    const ciasAereas = response.data.map((ciaAerea) => {
      return { value: ciaAerea.id, label: ciaAerea.nome };
    });

    return ciasAereas;
  }

  const loadCiaAereaOptions = (inputValue, callback) => {
    clearTimeout(timerCiaAerea.current);
    timerCiaAerea.current = setTimeout(async () => {
      callback(await loadCiasAereas(inputValue));
    }, 600);
  };

  const handleChangeCiaAerea = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        cia_aerea_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Async Select - Programas de Fidelidade
   */
  async function loadProgramasFidel(filterText) {
    const response = await api.get('programas-fidel', {
      params: { q: filterText },
    });

    const programasFidel = response.data.map((programaFidel) => {
      return { value: programaFidel.id, label: programaFidel.nome };
    });

    return programasFidel;
  }

  const loadProgramaFidelOptions = (inputValue, callback) => {
    clearTimeout(timerProgramaFidel.current);
    timerProgramaFidel.current = setTimeout(async () => {
      callback(await loadProgramasFidel(inputValue));
    }, 600);
  };

  const handleChangeProgramaFidel = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        programa_fidel_id: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Select - Classe
   */
  const handleChangeClasse = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        classe: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  /**
   * Select - Internacional
   */
  const handleInternacionalClasse = (selectedOptions) => {
    setFilter((prev) => {
      const newFilter = {
        ...prev,
        internacional: selectedOptions ? selectedOptions.value : undefined,
      };

      if (onFilterChange) {
        onFilterChange(newFilter);
      }

      return newFilter;
    });
  };

  const handleLimparFiltros = () => {
    setFilter(() => {
      if (onFilterChange) {
        onFilterChange({ ...iniFilter });
      }

      return { ...iniFilter };
    });

    if (refPaisOrigem && refPaisOrigem.current) {
      refPaisOrigem.current.select.select.clearValue();
    }
    if (refRegiaoOrigem && refRegiaoOrigem.current) {
      refRegiaoOrigem.current.select.select.clearValue();
    }
    if (refEstadoOrigem && refEstadoOrigem.current) {
      refEstadoOrigem.current.select.select.clearValue();
    }
    if (refCidadeOrigem && refCidadeOrigem.current) {
      refCidadeOrigem.current.select.select.clearValue();
    }
    if (refPaisDestino && refPaisDestino.current) {
      refPaisDestino.current.select.select.clearValue();
    }
    if (refRegiaoDestino && refRegiaoDestino.current) {
      refRegiaoDestino.current.select.select.clearValue();
    }
    if (refEstadoDestino && refEstadoDestino.current) {
      refEstadoDestino.current.select.select.clearValue();
    }
    if (refCidadeDestino && refCidadeDestino.current) {
      refCidadeDestino.current.select.select.clearValue();
    }
    if (refCiaAerea && refCiaAerea.current) {
      refCiaAerea.current.select.select.clearValue();
    }
    if (refProgramaFidel && refProgramaFidel.current) {
      refProgramaFidel.current.select.select.clearValue();
    }

    // Selects (simples)
    if (refClasse && refClasse.current) {
      refClasse.current.select.clearValue();
    }
    if (refInternacional && refInternacional.current) {
      refInternacional.current.select.clearValue();
    }
  };

  return (
    <Container>
      <FieldGroup>
        <Field>
          <Title>Filtros</Title>
        </Field>
      </FieldGroup>

      <FieldGroup>
        {showPaisOrigem ? (
          <Field flex={1}>
            <AsyncSelect
              // autoFocus
              ref={refPaisOrigem}
              isClearable
              name="pais_origem"
              placeholder="País de Origem"
              cacheOptions
              loadOptions={loadPaisOrigemOptions}
              defaultOptions
              onChange={handleChangePaisOrigem}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showRegiaoOrigem ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refRegiaoOrigem}
              isClearable
              name="regiao_origem"
              placeholder="Região de Origem"
              cacheOptions
              loadOptions={loadRegiaoOrigemOptions}
              defaultOptions
              onChange={handleChangeRegiaoOrigem}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showEstadoOrigem ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refEstadoOrigem}
              isClearable
              name="estado_origem"
              placeholder="Estado de Origem"
              cacheOptions
              loadOptions={loadEstadoOrigemOptions}
              defaultOptions
              onChange={handleChangeEstadoOrigem}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showCidadeOrigem ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refCidadeOrigem}
              isClearable
              name="cidade_origem"
              placeholder="Cidade de Origem"
              cacheOptions
              loadOptions={loadCidadeOrigemOptions}
              defaultOptions
              onChange={handleChangeCidadeOrigem}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}
      </FieldGroup>

      <FieldGroup>
        {showPaisDestino ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refPaisDestino}
              isClearable
              name="pais_destino"
              placeholder="País de Destino"
              cacheOptions
              loadOptions={loadPaisDestinoOptions}
              defaultOptions
              onChange={handleChangePaisDestino}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showRegiaoDestino ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refRegiaoDestino}
              isClearable
              name="regiao_destino"
              placeholder="Região de Destino"
              cacheOptions
              loadOptions={loadRegiaoDestinoOptions}
              defaultOptions
              onChange={handleChangeRegiaoDestino}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showEstadoDestino ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refEstadoDestino}
              isClearable
              name="estado_destino"
              placeholder="Estado de Destino"
              cacheOptions
              loadOptions={loadEstadoDestinoOptions}
              defaultOptions
              onChange={handleChangeEstadoDestino}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showCidadeDestino ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refCidadeDestino}
              isClearable
              name="cidade_destino"
              placeholder="Cidade de Destino"
              cacheOptions
              loadOptions={loadCidadeDestinoOptions}
              defaultOptions
              onChange={handleChangeCidadeDestino}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}
      </FieldGroup>

      <FieldGroup>
        {showClasse ? (
          <Field>
            <Select
              ref={refClasse}
              name="classe"
              isClearable
              className="basic-single"
              classNamePrefix="select"
              placeholder="Classe"
              // defaultValue={{
              //   value: classe,
              //   label: classesVoo[classe].nomeCurto,
              // }}
              options={classesOptions}
              onChange={handleChangeClasse}
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showInternacional ? (
          <Field>
            <Select
              ref={refInternacional}
              name="internacional"
              isClearable
              className="basic-single"
              classNamePrefix="select"
              placeholder="Tipo"
              // defaultValue={{ value: '', label: '' }}
              options={[
                { value: true, label: 'Internacional' },
                { value: false, label: 'Nacional' },
              ]}
              onChange={handleInternacionalClasse}
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showCiaAerea ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refCiaAerea}
              isClearable
              name="cia_aerea"
              placeholder="Companhia Aérea"
              cacheOptions
              loadOptions={loadCiaAereaOptions}
              defaultOptions
              onChange={handleChangeCiaAerea}
              required
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}

        {showProgramaFidel ? (
          <Field flex={1}>
            <AsyncSelect
              ref={refProgramaFidel}
              isClearable
              name="programa_fidel"
              placeholder="Programa de Fidelidade"
              cacheOptions
              loadOptions={loadProgramaFidelOptions}
              defaultOptions
              onChange={handleChangeProgramaFidel}
              required
              clearValue={handleLimparFiltros}
              theme={theme}
              styles={styles}
            />
          </Field>
        ) : null}
      </FieldGroup>

      <ButtonContainer>
        <Field flex="none">
          <Button
            type="button"
            disabled={buttonDisabled}
            onClick={handleLimparFiltros}>
            {/* <MdAdd size={24} color={colors.iconLight} /> */}
            <span>Limpar</span>
          </Button>
        </Field>
      </ButtonContainer>
    </Container>
  );
}

FilterPanelEmissoes.defaultProps = {
  showPaisOrigem: true,
  showRegiaoOrigem: true,
  showEstadoOrigem: true,
  showCidadeOrigem: true,
  showPaisDestino: true,
  showRegiaoDestino: true,
  showEstadoDestino: true,
  showCidadeDestino: true,
  showCiaAerea: true,
  showProgramaFidel: true,
  showClasse: true,
  showInternacional: true,
};

FilterPanelEmissoes.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  showPaisOrigem: PropTypes.bool,
  showRegiaoOrigem: PropTypes.bool,
  showEstadoOrigem: PropTypes.bool,
  showCidadeOrigem: PropTypes.bool,
  showPaisDestino: PropTypes.bool,
  showRegiaoDestino: PropTypes.bool,
  showEstadoDestino: PropTypes.bool,
  showCidadeDestino: PropTypes.bool,
  showCiaAerea: PropTypes.bool,
  showProgramaFidel: PropTypes.bool,
  showClasse: PropTypes.bool,
  showInternacional: PropTypes.bool,
};

export { FilterPanelEmissoes };
