import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
};

export default function usuario(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        // draft.profile = action.payload.usuario;
        draft.profile = {
          id: action.payload.usuario.id,
          nome: action.payload.usuario.nome,
          email: action.payload.usuario.email,
        };
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }

      case '@usuario/UPDATE_PROFILE_SUCCESS': {
        draft.profile = action.payload.profile;
        break;
      }

      default:
    }
  });
}
