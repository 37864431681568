import React, { useState } from 'react';
import PropTypes from 'prop-types';

import history from '~/services/history';
import { Video } from '../../../components/Video';
import { Container } from './styles';

function ExibirVideo({ location }) {
  const [video] = useState(location.video);

  if (!video) {
    history.push('/curadoria');
    return null;
  }

  return (
    <Container>
      <Video video={video} />
    </Container>
  );
}

ExibirVideo.propTypes = {
  location: PropTypes.shape(),
};

ExibirVideo.defaultProps = {
  location: null,
};

export default ExibirVideo;
