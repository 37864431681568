export function refreshFuncaoRequest(usuario) {
  return {
    type: '@funcao/REFRESH_FUNCAO_REQUEST',
    payload: { usuario },
  };
}

export function refreshFuncaoSuccess(funcao) {
  return {
    type: '@funcao/REFRESH_FUNCAO_SUCCESS',
    payload: { funcao },
  };
}
