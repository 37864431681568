import React, { useState, useEffect, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import {
  MdAdd,
  MdDeleteForever,
  MdLockOutline,
  MdRefresh,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import checkPermission from '~/lib/checkPermission';

import SearchInput from '~/components/SearchInput';
import Pagination from '~/components/Pagination';
import Actions from '~/components/Actions';
import { FieldGroup } from '~/components/Forms';
import { Button } from '~/components/Button';
import Can from '~/components/Can';
import { Table } from '~/components/Table';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import ColumnHeader from '~/components/ColumnHeader';
import { BaseContainer } from '../../../components/BaseContainer';

import { Header, TooltipContainer } from './styles';
import PontosMovTipo from './PontosMovTipo';
import {
  currencyPrecision4ToString,
  currencyToString,
  integerToString,
} from '../../../lib/formataNumero';
import { showError, showSuccess } from '../../../lib/toastHelper';

function PontosMovList() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [filter, setFilter] = useState('');
  const [pontosMov, setPontosMov] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({ field: 'data', asc: false });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function loadPontosMov(f) {
      try {
        setLoading(true);
        const response = await api.get('pontos-mov', {
          params: {
            q: f,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
          },
        });

        const data = response.data.map((mov) => {
          return {
            ...mov,
            dataFormatted: mov.data && format(parseISO(mov.data), 'dd/MM/yyyy'),
            custoFormatted: currencyToString(mov.custo),
            custoUnitarioFormatted: currencyPrecision4ToString(
              mov.custoUnitario
            ),
            custoMilheiroFormatted: currencyToString(mov.custoUnitario * 1000),
            qtdeFormatted: integerToString(mov.qtde),
          };
        });

        setTotalPages(Number(response.headers['x-api-totalpages']));
        setTotal(Number(response.headers['x-api-total']));
        setPontosMov(data);
        setLoading(false);
      } catch (err) {
        showError('Falha ao carregar dados!', err);
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'pontos-mov:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadPontosMov(filter);
    }, 600);
  }, [filter, page, pageLimit, order, funcao, refresh]);

  function handleFilterChange(value) {
    setPage(1);
    setFilter(value);
  }

  const handleCreate = () => {
    history.push('/pontos-mov/create');
  };

  function reload() {
    setFilter(' ');
    setFilter('');
  }

  // function handleEdit(id) {
  //   const movToEdit = pontosMov.find((mov) => mov.id === id);
  //   if (movToEdit) {
  //     history.push({
  //       pathname: '/pontos-mov/edit',
  //       mov: movToEdit,
  //     });
  //   }
  // }

  async function handleDelete(id) {
    if (!window.confirm('Deseja mesmo excluir o movimento?')) {
      return;
    }

    try {
      await api.delete(`pontos-mov/${id}`);
      showSuccess('Movimento excluído com sucesso!');
      reload();
    } catch (err) {
      showError('Falha ao excluir movimento!', err);
    }
  }

  const handleChangeOrder = (field) => {
    setPage(1);
    setOrder({ field, asc: field === order.field ? !order.asc : true });
  };

  const handleRefresh = () => {
    setLoading(true);
    setRefresh(!refresh);
  };

  return (
    <BaseContainer>
      <Title>Histórico {total > 0 && `(${total})`}</Title>

      <Header>
        <SearchInput
          placeholder="titular, programa, obs"
          value={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
        />

        <FieldGroup>
          <Button
            type="button"
            padding="6px"
            disabled={loading}
            onClick={handleRefresh}>
            <MdRefresh size={24} color={colors.iconLight} />
          </Button>

          {/* <Can
            funcao={funcao}
            perform="pontos-mov:create"
            yes={() => (
              <Button primary type="button" onClick={handleCreate}>
                <MdAdd size={24} color={colors.iconLight} />
                <span>Cadastrar</span>
              </Button>
            )}
          /> */}
        </FieldGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <th>
              <ColumnHeader
                label="Data"
                showOrder={order.field === 'data'}
                asc={order.asc}
                onClick={() => handleChangeOrder('data')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Titular"
                showOrder={order.field === 'titular.nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('titular.nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Programa"
                showOrder={order.field === 'programa_fidel.nome'}
                asc={order.asc}
                onClick={() => handleChangeOrder('programa_fidel.nome')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Tipo"
                showOrder={order.field === 'tipo'}
                asc={!order.asc}
                onClick={() => handleChangeOrder('tipo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Pontos"
                showOrder={order.field === 'qtde'}
                asc={order.asc}
                onClick={() => handleChangeOrder('qtde')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Custo Total"
                showOrder={order.field === 'custo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('custo')}
              />
            </th>
            <th>
              <ColumnHeader
                label="Custo Médio (milheiro)"
                showOrder={order.field === 'custo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('custo')}
              />
            </th>

            <th>
              <ColumnHeader
                label="Observação"
                showOrder={order.field === 'resumo'}
                asc={order.asc}
                onClick={() => handleChangeOrder('resumo')}
              />
            </th>
            <th />
            {/* <th>
              <ColumnHeader
                label="Criado em"
                showOrder={order.field === 'created_at'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_at')}
              />
            </th> */}
            {/* <th>
              <ColumnHeader
                label="Criado por"
                showOrder={order.field === 'created_by'}
                asc={order.asc}
                onClick={() => handleChangeOrder('created_by')}
              />
            </th> */}
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {pontosMov.map((mov) => (
            <tr key={mov.id}>
              <td>{mov.dataFormatted}</td>
              <td>{mov.titular && mov.titular.nome}</td>
              <td>{mov.programa_fidel && mov.programa_fidel.nome}</td>
              <td>
                <PontosMovTipo tipo={mov.tipo} />
              </td>
              <td>{mov.qtdeFormatted}</td>
              <td>{mov.custoFormatted}</td>
              <td>{mov.custoMilheiroFormatted}</td>
              <td>{mov.resumo}</td>
              <td>
                {mov.operacao_id ? (
                  <>
                    <MdLockOutline
                      color={colors.gray300}
                      size={22}
                      data-tip
                      data-for={`mov_locked_${mov.id}`}
                    />
                    <ReactTooltip
                      id={`mov_locked_${mov.id}`}
                      textColor={colors.gray100}
                      backgroundColor={colors.gray700}>
                      <TooltipContainer>
                        Este movimento não pode ser alterado diretamente porque
                        foi gerado a partir de uma <strong>Operação</strong>.
                        {mov.pontos_mov_prev_id ? (
                          <>
                            <br />
                            <br />
                            Você pode Excluir para torná-lo uma previsão
                            novamente.
                          </>
                        ) : null}
                      </TooltipContainer>
                    </ReactTooltip>
                  </>
                ) : null}
              </td>

              <td>
                {!mov.operacao_id || mov.pontos_mov_prev_id ? (
                  <Actions>
                    {/* <Can
                    funcao={funcao}
                    perform="pontos-mov:edit"
                    yes={() => (
                      <li>
                        <MdEdit color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(mov.id)}>
                          Editar
                        </button>
                      </li>
                    )}
                    no={() => (
                      <li>
                        <MdVisibility color="#4D85EE" size={16} />
                        <button
                          type="button"
                          onClick={() => handleEdit(mov.id)}>
                          Visualizar
                        </button>
                      </li>
                    )}
                  /> */}

                    <Can
                      funcao={funcao}
                      perform="pontos-mov:delete"
                      yes={() => (
                        <li>
                          <MdDeleteForever color="#DE3B3B" size={16} />
                          <button
                            type="button"
                            onClick={() => handleDelete(mov.id)}>
                            Excluir
                          </button>
                        </li>
                      )}
                      no={() => (
                        <li>
                          <MdDeleteForever
                            color={colors.textDisabled}
                            size={16}
                          />
                          <button type="button" disabled>
                            Excluir
                          </button>
                        </li>
                      )}
                    />
                  </Actions>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!loading && pontosMov.length === 0 ? (
        <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
      ) : (
        <Pagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
        />
      )}
    </BaseContainer>
  );
}

export default PontosMovList;
