import React from 'react';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';
import PropTypes from 'prop-types';

import { Container, IconContainer, TooltipContainer } from './styles';

import colors from '../../styles/colors';

function InfoTooltip({ id, text, title }) {
  return (
    <Container key={id}>
      <IconContainer>
        <MdInfoOutline
          color={colors.gray300}
          size={22}
          data-tip
          data-for={id}
          // data-event="click focus"
        />
      </IconContainer>

      <ReactTooltip
        id={id}
        // globalEventOff="click"
        textColor={colors.gray100}
        backgroundColor={colors.gray700}>
        <TooltipContainer>
          {title && (
            <>
              <strong>{title}</strong>
              <br />
              <br />
            </>
          )}

          {text}
        </TooltipContainer>
      </ReactTooltip>
    </Container>
  );
}

InfoTooltip.defaultProps = {
  title: undefined,
};

InfoTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
};

export default InfoTooltip;
