import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  /* background: ${(props) => {
    switch (props.active) {
      case true:
        return '#DFF0DF';
      case false:
        return '#FAB0B0';
      default:
        break;
    }
  }}; */

  color: ${(props) => {
    switch (props.entrada) {
      case true:
        return colors.green500;
      case false:
        return colors.red500;
      default:
        break;
    }
    return colors.textDark;
  }};

  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  display: inline-block;

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;

    background: ${(props) => {
      switch (props.entrada) {
        case true:
          return colors.green500;
        case false:
          return colors.red500;
        default:
          break;
      }
      return colors.textDark;
    }};
  }
`;
