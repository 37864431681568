import React from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md';
import colors from '~/styles/colors';
import { ColHeader } from './styles';

function ColumnHeader({ label, showOrder, asc, onClick }) {
  return (
    <ColHeader onClick={onClick}>
      <div>
        <span>{label}</span>

        {showOrder &&
          (asc ? (
            <MdArrowDropUp size={28} color={colors.gray300} />
          ) : (
            <MdArrowDropDown size={28} color={colors.gray300} />
          ))}
      </div>
    </ColHeader>
  );
}

ColumnHeader.propTypes = {
  label: PropTypes.string.isRequired,
  showOrder: PropTypes.bool,
  asc: PropTypes.bool,
  onClick: PropTypes.func,
};

ColumnHeader.defaultProps = {
  showOrder: false,
  asc: true,
  onClick: null,
};

export default ColumnHeader;
