import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import PropTypes from 'prop-types';
import Select from 'react-select';

import colors from '~/styles/colors';
import { theme, styles } from '../../config/reactSelect';

import {
  Container,
  PageLimitContainer,
  SelectContainer,
  PageNavigateText,
  PageNavigateContainer,
  PageNavigateButton,
} from './styles';

export default function Pagination({
  totalPages,
  page,
  pageLimit,
  setPage,
  setPageLimit,
}) {
  async function handlePage(action) {
    setPage(action === 'back' ? page - 1 : page + 1);
  }

  const pageOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const handleChange = (selected) => {
    if (!setPageLimit) return;

    setPageLimit(selected.value);
  };

  return (
    <Container>
      {setPageLimit && pageLimit && (
        <PageLimitContainer>
          <PageNavigateText>Exibindo</PageNavigateText>
          <SelectContainer>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="color"
              menuPlacement="auto"
              components={{
                IndicatorSeparator: () => null,
              }}
              defaultValue={pageOptions.find(
                (item) => item.value === String(pageLimit)
              )}
              isDisabled={!(setPageLimit && pageLimit)}
              // isLoading={isLoading}
              // isClearable={isClearable}
              // isRtl={isRtl}
              // isSearchable={isSearchable}
              options={pageOptions}
              onChange={handleChange}
              theme={theme}
              styles={styles}
            />
          </SelectContainer>
        </PageLimitContainer>
      )}

      <PageNavigateContainer>
        <PageNavigateButton
          type="button"
          disabled={page < 2}
          onClick={() => handlePage('back')}>
          <MdChevronLeft
            size={36}
            color={page < 2 ? colors.gray300 : colors.blue500}
          />
        </PageNavigateButton>

        <PageNavigateText>
          Página {page} de {totalPages}
        </PageNavigateText>

        <PageNavigateButton
          type="button"
          disabled={page === totalPages}
          onClick={() => handlePage('next')}>
          <MdChevronRight
            size={36}
            color={page === totalPages ? colors.gray300 : colors.blue500}
          />
        </PageNavigateButton>
      </PageNavigateContainer>
    </Container>
  );
}

Pagination.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  pageLimit: PropTypes.number,
  setPage: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  setPageLimit: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

Pagination.defaultProps = {
  totalPages: 1,
  page: 1,
  pageLimit: 10,
};
