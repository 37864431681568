import { lighten } from 'polished';
import styled from 'styled-components';
import colors from '~/styles/colors';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  padding: 1rem 0;
  margin: 0 1rem;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  /* @media only screen and (max-width: 600px) {
  } */

  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* screen width >= 600 pixels  */
  /* @media only screen and (min-width: 600px) {
  } */

  /* Medium devices (landscape tablets, 768px and up) */
  /* screen width >= 768 pixels  */
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  /* Large devices (laptops/desktops, 992px and up) */
  /* screen width >= 992 pixels  */
  /* @media only screen and (min-width: 992px) {
  } */

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* screen width >= 1200 pixels  */
  /* @media only screen and (min-width: 1200px) {
  } */
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  a {
    color: ${colors.blue500};
  }
`;

export const PageTitle = styled.div`
  font-size: 1.2rem;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
  padding: 8px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
  padding: 8px;
  margin-bottom: 8px;
`;

export const VisionContainer = styled.div`
  /* background: ${colors.gray800}; */
  background: linear-gradient(
    45deg,
    ${lighten(0.01, colors.gray800)},
    ${colors.gray800}
  );

  border-radius: 16px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border: 2px solid ${colors.gray800};

  transition: all 0.2s ease-in;

  :hover {
    /* background: linear-gradient(
      45deg,
      ${lighten(0.03, colors.gray800)},
      ${colors.gray800}
    ); */
    border: 2px solid ${colors.gray400};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: lightgreen; */
  padding-top: 16px;
  padding-bottom: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: lightgreen; */
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: lightblue; */
  padding: 24px 0;
`;

export const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
`;

export const VisionTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: ${colors.gray300};
  color: ${colors.orange500};
  padding: 5px;
  text-align: center;
`;

export const VisionLegend = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
  line-height: 22px;
`;

export const Content = styled.div`
  color: ${colors.gray300};
`;
