import styled from 'styled-components';
import colors from '../../../styles/colors';

export const LineSeparator = styled.hr`
  border: 1px solid ${colors.gray700};
  margin: 24px 0;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
`;
