import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;

export const TooltipContainer = styled.div`
  padding: 8px 0;
`;
