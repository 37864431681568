import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
// import AsyncCreatableSelect from 'react-select/async-creatable';

import PropTypes from 'prop-types';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import {
  MdDone,
  MdArrowBack,
  // MdVisibility,
  // MdVisibilityOff,
} from 'react-icons/md';
import { toast } from 'react-toastify';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';
import { theme, styles } from '~/config/reactSelect';

// import HistoryList from '~/components/HistoryList';
import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import {
  FormWrapper,
  FieldGroup,
  Field,
  // FieldInlineWrapper,
} from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';

import { classesVoo, classesVooArray } from '~/config/classesVoo';

// import { } from './styles';

function EmissaoForm({ location }) {
  const timerOrigem = useRef(null);
  const timerDestino = useRef(null);
  const timerCiaAerea = useRef(null);
  const timerProgramaFidel = useRef(null);

  const [emissao] = useState(location.emissao);

  const [origemAeroportoId, setOrigemAeroportoId] = useState(
    emissao && emissao.origem_aeroporto_id
  );
  const [destinoAeroportoId, setDestinoAeroportoId] = useState(
    emissao && emissao.destino_aeroporto_id
  );
  const [ciaAereaId, setCiaAereaId] = useState(emissao && emissao.cia_aerea_id);
  const [programaFidelId, setProgramaFidelId] = useState(
    emissao && emissao.programa_fidel_id
  );

  const [dataDivulgacao, setDataDivulgacao] = useState(
    // emissao ? emissao.startDateEn : format(new Date(), 'yyyy-MM-dd')
    emissao && emissao.data_divulgacao
      ? format(parseISO(emissao.data_divulgacao), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  );
  const [dataVoo, setDataVoo] = useState(
    emissao && emissao.data_voo
      ? format(parseISO(emissao.data_voo), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd')
  );

  const classesOptions = classesVooArray().map((classeVoo) => ({
    value: classeVoo.codigo,
    label: classeVoo.nomeCurto,
  }));

  const [classe, setClasse] = useState(
    emissao ? emissao.classe : classesVoo.ec.codigo
  );

  const isEditing = history.location.pathname === '/emissoes/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'emissoes:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !emissao) {
      history.goBack();
    }
  }, [emissao, isEditing, denied]);

  /**
   * Async Select - Aeroportos
   */
  async function loadAeroportos(filter) {
    const response = await api.get('aeroportos', {
      params: { q: filter },
    });

    const aeroportos = response.data.map((aeroporto) => {
      return { value: aeroporto.id, label: aeroporto.codigo_iata };
    });

    return aeroportos;
  }

  const loadAeroportoOrigemOptions = (inputValue, callback) => {
    clearTimeout(timerOrigem.current);
    timerOrigem.current = setTimeout(async () => {
      callback(await loadAeroportos(inputValue));
    }, 600);
  };

  const handleChangeAeroportoOrigem = (selectedOptions) => {
    setOrigemAeroportoId(selectedOptions.value);
  };

  const loadAeroportoDestinoOptions = (inputValue, callback) => {
    clearTimeout(timerDestino.current);
    timerDestino.current = setTimeout(async () => {
      callback(await loadAeroportos(inputValue));
    }, 600);
  };

  const handleChangeAeroportoDestino = (selectedOptions) => {
    setDestinoAeroportoId(selectedOptions.value);
  };

  /**
   * Async Select - Cias Aéreas
   */
  async function loadCiasAereas(filter) {
    const response = await api.get('cias-aereas', {
      params: { q: filter },
    });

    const ciasAereas = response.data.map((ciaAerea) => {
      return { value: ciaAerea.id, label: ciaAerea.nome };
    });

    // setIsLoading(false);
    return ciasAereas;
  }

  const loadCiaAereaOptions = (inputValue, callback) => {
    clearTimeout(timerCiaAerea.current);
    timerCiaAerea.current = setTimeout(async () => {
      callback(await loadCiasAereas(inputValue));
    }, 600);
  };

  const handleChangeCiaAerea = (selectedOptions) => {
    setCiaAereaId(selectedOptions.value);
  };

  /**
   * Programas de Fidelidade
   */
  async function loadProgramasFidel(filter) {
    const response = await api.get('programas-fidel', {
      params: { q: filter },
    });

    const programasFidel = response.data.map((programaFidel) => {
      return { value: programaFidel.id, label: programaFidel.nome };
    });

    return programasFidel;
  }

  const loadProgramaFidelOptions = (inputValue, callback) => {
    clearTimeout(timerProgramaFidel.current);
    timerProgramaFidel.current = setTimeout(async () => {
      callback(await loadProgramasFidel(inputValue));
    }, 600);
  };

  const handleChangeProgramaFidel = (selectedOptions) => {
    setProgramaFidelId(selectedOptions.value);
  };

  const handleChangeClasse = (selectedOptions) => {
    setClasse(selectedOptions.value);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({
    origem_aeroporto_id,
    destino_aeroporto_id,
    cia_aerea_id,
    programa_fidel_id,
    numero_voo,
    data_divulgacao,
    data_voo,
    milhas_trecho,
    classe,
  }) {
    const payload = {
      origem_aeroporto_id,
      destino_aeroporto_id,
      cia_aerea_id,
      programa_fidel_id,
      numero_voo,
      data_divulgacao,
      data_voo,
      milhas_trecho,
      classe,
    };

    try {
      if (emissao) {
        await api.put(`emissoes/${emissao.id}`, payload); // Edit
      } else {
        await api.post('emissoes', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/emissoes');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = (data) => {
    if (!isEditing && !checkPermission(funcao, 'emissoes:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'emissoes:edit')) {
      return;
    }

    const { numero_voo, data_divulgacao, data_voo, milhas_trecho } = data;

    save({
      origem_aeroporto_id: origemAeroportoId,
      destino_aeroporto_id: destinoAeroportoId,
      cia_aerea_id: ciaAereaId,
      programa_fidel_id: programaFidelId,
      numero_voo,
      data_divulgacao,
      data_voo,
      milhas_trecho,
      classe,
    });
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            numero_voo: emissao && emissao.numero_voo,
            milhas_trecho: emissao && emissao.milhas_trecho,
          }}>
          <FormHeader>
            <Title>Cadastro de Emissão</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'emissoes:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="aeroporto_origem">Origem</label>
              <AsyncSelect
                autoFocus
                name="aeroporto_origem"
                placeholder="Selecione o aeroporto de origem..."
                cacheOptions
                loadOptions={loadAeroportoOrigemOptions}
                defaultOptions
                onChange={handleChangeAeroportoOrigem}
                defaultValue={
                  emissao &&
                  emissao.origem_aeroporto && {
                    value: emissao.origem_aeroporto.id,
                    label: emissao.origem_aeroporto.codigo_iata,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="aeroporto_destino">Destino</label>
              <AsyncSelect
                name="aeroporto_destino"
                placeholder="Selecione o aeroporto de destino..."
                cacheOptions
                loadOptions={loadAeroportoDestinoOptions}
                defaultOptions
                onChange={handleChangeAeroportoDestino}
                defaultValue={
                  emissao &&
                  emissao.destino_aeroporto && {
                    value: emissao.destino_aeroporto.id,
                    label: emissao.destino_aeroporto.codigo_iata,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="cia_aerea">Companhia Aérea</label>
              <AsyncSelect
                name="cia_aerea"
                placeholder="Selecione a companhia aérea..."
                cacheOptions
                loadOptions={loadCiaAereaOptions}
                defaultOptions
                onChange={handleChangeCiaAerea}
                defaultValue={
                  emissao &&
                  emissao.cia_aerea && {
                    value: emissao.cia_aerea.id,
                    label: emissao.cia_aerea.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="programa_fidel">Programa de Fidelidade</label>
              <AsyncSelect
                name="programa_fidel"
                placeholder="Selecione o programa de fidelidade..."
                cacheOptions
                loadOptions={loadProgramaFidelOptions}
                defaultOptions
                onChange={handleChangeProgramaFidel}
                defaultValue={
                  emissao &&
                  emissao.programa_fidel && {
                    value: emissao.programa_fidel.id,
                    label: emissao.programa_fidel.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="numero_voo">Número do Voo</label>
              <Input
                name="numero_voo"
                type="text"
                maxLength="10"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <label htmlFor="data_divulgacao">Data de Divulgação</label>
              <Input
                name="data_divulgacao"
                type="date"
                autoComplete="off"
                value={dataDivulgacao || ''}
                onChange={(e) => setDataDivulgacao(e.target.value)}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field flex="none">
              <label htmlFor="data_voo">Data do Voo</label>
              <Input
                name="data_voo"
                type="date"
                autoComplete="off"
                value={dataVoo || ''}
                onChange={(e) => setDataVoo(e.target.value)}
                required
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="milhas_trecho">Milhas por Trecho</label>
              <Input
                name="milhas_trecho"
                type="number"
                maxLength="10"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="classe">Classe</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={{
                  value: classe,
                  label: classesVoo[classe].nomeCurto,
                }}
                name="classe"
                options={classesOptions}
                onChange={handleChangeClasse}
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          {/* <FieldGroup>
              <Field flex="none">
                <label htmlFor="ativo">Ativo</label>
                <Check name="ativo" />
              </Field>
            </FieldGroup> */}
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

EmissaoForm.propTypes = {
  location: PropTypes.shape(),
};

EmissaoForm.defaultProps = {
  location: null,
};

export default EmissaoForm;
