import styled from 'styled-components';
import colors from '../../styles/colors';

export const VideoContainer = styled.div`
  padding: 0 100px;
  margin-bottom: 24px;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PageTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  color: ${colors.gray300};
  text-align: center;
  padding: 8px;
  margin-bottom: 16px;
`;
