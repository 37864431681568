import styled, { css } from 'styled-components';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.header``;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
`;

export const LogoWrapper = styled.div``;

export const NavList = styled.ul`
  list-style: none;
  display: flex;
  z-index: 999;

  ${(props) =>
    props.visible
      ? css`
          /* transition: transform 0.2s ease-in;
          transform: translateX(0); */
        `
      : css`
          /* transition: transform 0.2s ease-in;
          transform: translateX(0); */
        `}

  li {
    letter-spacing: 1px;
    margin-left: 26px;
  }

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 8vh;
    right: 0;
    /* width: 50vw; */
    height: 92vh;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
    /* transform: translateX(100%); */
    padding: 2rem;
    gap: 2rem;

    ${(props) =>
      props.visible
        ? css`
            background: ${colors.gray800}};
            display: block;

            /* transition: transform 0.2s ease-in; */
            /* transform: translateX(0); */
            /* width: 50vw; */
          `
        : css`
            /* width: 0; */
            transform: translateX(100%);
            display: none;

            /* transition: transform 0.2s ease-in, width 0.2s ease-in; */
            /* transition: all 0.2s ease-in; */
          `}

    li {
      margin-left: 0;
      /* opacity: 0; */
    }
  }

  @media only screen and (min-width: 601px) {
    display: flex;
  }
`;

export const Menu = styled.div`
  cursor: pointer;
  display: none;

  > div {
    width: 32px;
    height: 2px;
    background: ${colors.gray500};
    margin: 8px;
    transition: 0.3s;
  }

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

export const MenuLine1 = styled.div`
  transform: ${({ active }) =>
    active ? 'rotate(-45deg) translate(-8px, 8px)' : 'none'};
`;
export const MenuLine2 = styled.div`
  opacity: ${({ active }) => (active ? '0' : '1')};
`;
export const MenuLine3 = styled.div`
  transform: ${({ active }) =>
    active ? 'rotate(45deg) translate(-5px, -7px)' : 'none'};
`;

// export const Content = styled.div`
//   background: lightblue;
//   height: 70px;
//   max-width: 85%;
//   margin: 0 auto;
//   padding: 0 30px;

//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;

//   nav {
//     display: flex;
//     align-items: center;
//   }

//   aside {
//     display: flex;
//     align-items: center;
//   }
// `;

export const HeaderLink = styled.div`
  padding: 10px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  text-transform: uppercase;
  color: ${(props) => (props.active ? colors.gray100 : colors.gray400)};
  cursor: default;

  a {
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    color: ${(props) => (props.active ? colors.gray100 : colors.gray400)};
    transition: opacity 0.3s;
  }

  a:hover {
    opacity: 0.7;
  }
`;

export const Profile = styled.div`
  /* padding-left: 26px; */
  /* border-left: 1px solid ${colors.gray700}; */
`;

export const VersionContainer = styled.div`
  margin-left: 20px;
  color: ${colors.gray300};
`;

/**
 * Menu
 */

// export const DropdownBtn = styled.button`
//   /* background: #04aa6d; */
//   /* color: white; */
//   /* padding: 16px; */
//   /* font-size: 16px; */
//   border: none;
// `;

export const DropdownContent = styled.div`
  background: ${colors.gray800};
  display: none;
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  div {
    padding: 20px;

    /* Change color of dropdown links on hover */
    &:hover {
      background: ${darken(0.03, colors.gray800)};
    }
  }
`;

export const DropdownMenu = styled.div`
  position: relative;
  display: inline-block;

  /* Show the dropdown menu on hover */
  &:hover ${DropdownContent} {
    display: block;
  }

  /* &:hover {
    background: ${darken(0.03, colors.gray800)};
  } */
`;
