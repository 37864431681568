import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div``;

export const BlackContainer = styled.div`
  background: black;
`;

export const VideoWrapper = styled.div`
  max-width: 90rem;
  margin: auto;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  /* border-radius: 8px; */
  overflow: hidden;

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  /* box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.1); */
`;

export const InfoContainer = styled.div`
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 90rem;
  margin: auto;

  /* Extra small devices (phones, 600px and down) */
  /* screen width <= 600 pixels  */
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* screen width >= 600 pixels  */
  /* @media only screen and (min-width: 600px) {
  } */

  /* Medium devices (landscape tablets, 768px and up) */
  /* screen width >= 768 pixels  */
  /* @media only screen and (min-width: 768px) {
  } */

  /* Large devices (laptops/desktops, 992px and up) */
  /* screen width >= 992 pixels  */
  /* @media only screen and (min-width: 992px) {
  } */

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* screen width >= 1200 pixels  */
  /* @media only screen and (min-width: 1200px) {
  } */

  strong {
    font-size: 1.125rem;
    color: ${colors.gray300};
    line-height: 1.4;
  }

  span {
    font-size: 0.875rem;
    color: ${colors.gray500};
    line-height: 1.6;
  }
`;
