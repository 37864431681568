import React, { useState, useEffect, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { ThreeDots } from 'react-loader-spinner';
import { Form } from '@unform/web';
import { differenceInDays, format, isAfter, parseISO } from 'date-fns';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import getErrorMessage from '~/lib/getErrorMessage';
import checkPermission from '~/lib/checkPermission';
import { integerToString } from '~/lib/formataNumero';

import { classesVoo, classesVooArray } from '~/config/classesVoo';
import { theme, styles } from '~/config/reactSelect';

import {
  Field,
  FieldGroup,
  FormWrapper,
  InlineField,
} from '~/components/Forms';
import { Button } from '~/components/Button';
import Pagination from '~/components/Pagination';
import { MainContainer } from '~/components/MainContainer';
import { Title } from '~/components/Title';
import { NoInfoToShow } from '~/components/NoInfoToShow';
import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';

import EsclarecimentoDadosTimeline from '../EsclarecimentoDadosTimeline';

import {
  getSugestaoPesquisaFormatted,
  getSugestaoPesquisaTooltip,
} from '../utils';

import {
  Header,
  NoDataTextContainer,
  NoDataText,
  GroupRow,
  GroupCell,
  Row,
  Cell,
  HorizontalConteiner,
  CellHeader,
  ResultTitle,
  PreventSelectionContainer,
} from '../styles';

function TimelinePorProgramaFidel() {
  const funcao = useSelector((state) => state.funcao.funcao);

  const [emissoes, setEmissoes] = useState([]);
  const [emissoesPorDestino, setEmissoesPorOrigem] = useState([]);
  const time = useRef(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  // const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [order] = useState({ field: 'cidade_destino', asc: false });
  const [refresh, setRefresh] = useState(true);
  const [consultasRealizadas, setConsultasRealizadas] = useState(0);
  const [totalEmissoes, setTotalEmissoes] = useState(0);

  const classesOptions = classesVooArray().map((classeVoo) => ({
    value: classeVoo.codigo,
    label: classeVoo.nomeCurto,
  }));

  const [internacional, setInternacional] = useState();
  const [classe, setClasse] = useState();
  const [continenteDestino, setContinenteDestino] = useState();
  const [periodoEmMeses] = useState(6);

  const [showDataViagem, setShowDataViagem] = useState(false);

  const [dataViagemIni, setDataViagemIni] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [dataViagemFim, setDataViagemFim] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );

  const diasViagemIni = useMemo(() => {
    return differenceInDays(parseISO(dataViagemIni), new Date()) + 1;
  }, [dataViagemIni]);

  const diasViagemFim = useMemo(() => {
    return differenceInDays(parseISO(dataViagemFim), new Date()) + 1;
  }, [dataViagemFim]);

  const consultarEnabled = useMemo(() => {
    if (showDataViagem) {
      return (
        internacional !== undefined &&
        !!classe &&
        !!continenteDestino &&
        !!periodoEmMeses &&
        !!dataViagemIni &&
        !!dataViagemFim
      );
    }

    return (
      internacional !== undefined &&
      !!classe &&
      !!continenteDestino &&
      !!periodoEmMeses
    );
  }, [
    internacional,
    classe,
    continenteDestino,
    periodoEmMeses,
    showDataViagem,
    dataViagemIni,
    dataViagemFim,
  ]);

  /**
   * Ajusta a data final automaticamente caso a data inicial ultrapasse
   */
  useEffect(() => {
    if (isAfter(parseISO(dataViagemIni), parseISO(dataViagemFim))) {
      setDataViagemFim(dataViagemIni);
    }
  }, [dataViagemIni, dataViagemFim]);

  /**
   * Impede o menu de contexto
   */
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener('contextmenu', handleContextMenu);
    return () => document.removeEventListener('contextmenu', handleContextMenu);
  }, []);

  /**
   * Impede atalho para copiar
   */
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'c' || e.key === 'x')) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Função para agrupar os dados pela coluna 'group'
  const groupData = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      if (!groupedData[item.programa_fidel]) {
        groupedData[item.programa_fidel] = [];
      }
      groupedData[item.programa_fidel].push(item);
    });
    return groupedData;
  };

  useEffect(() => {
    async function loadEmissoes() {
      if (internacional === undefined || !classe || !continenteDestino) {
        return false;
      }

      try {
        setLoading(true);
        const response = await api.get('timeline/visao', {
          params: {
            visao: 'por-programa-fidel',
            internacional,
            classe,
            continente_destino: continenteDestino,
            periodoEmMeses,
            page,
            pageLimit,
            order: order.field,
            direction: order.asc ? 'ASC' : 'DESC',
            diasViagemIni: showDataViagem ? diasViagemIni : undefined,
            diasViagemFim: showDataViagem ? diasViagemFim : undefined,
          },
        });

        const { docs, dadosAdicionais } = response.data;
        const { countEmissoes } = dadosAdicionais;

        const data = docs.map((emissao, i) => {
          return {
            ...emissao,
            cidade_origem_formatted:
              i > 0 && docs[i - 1].cidade_origem === emissao.cidade_origem
                ? ''
                : emissao.cidade_origem,
            tipo_formatted: emissao.internacional
              ? 'Internacional'
              : 'Nacional',
            milhas_trecho_formatted: integerToString(emissao.milhas_trecho),
            classe_formatted: classesVoo[emissao.classe].nomeCurto,
            sugestaoPesquisaFormatted: getSugestaoPesquisaFormatted(
              emissao.dias_divulg_voo
            ),
            key: i,
          };
        });

        const groupedData = groupData(data);

        setTotalPages(Number(response.headers['x-api-totalpages']));
        // setTotal(Number(response.headers['x-api-total']));
        setEmissoes(data);
        setEmissoesPorOrigem(groupedData);
        setLoading(false);
        setConsultasRealizadas((prev) => prev + 1);
        setTotalEmissoes(countEmissoes);
      } catch (err) {
        toast.error(
          <div>
            Falha ao carregar dados! <br /> <br />
            {getErrorMessage(err)}
          </div>
        );
      }
      return true;
    }

    /**
     * Check permissions
     */
    if (funcao && !checkPermission(funcao, 'emissoes:visit')) {
      history.push('/denied');
      return;
    }

    clearTimeout(time.current);
    time.current = setTimeout(() => {
      loadEmissoes();
    }, 600);
  }, [page, pageLimit, order, funcao, refresh]);

  // function reload() {
  //   setFilter({ q: ' ', pais_id: undefined });
  //   setFilter({ q: '', pais_id: undefined });
  // }

  // const handleChangeOrder = (field) => {
  //   setPage(1);
  //   setOrder({ field, asc: field === order.field ? !order.asc : true });
  // };

  // const handleRefresh = () => {
  //   setLoading(true);
  //   setRefresh(!refresh);
  // };

  const handleExecFilter = () => {
    if (internacional === undefined || !classe || !continenteDestino) {
      toast.warn('Por favor, preencha todos os filtros.');
      return;
    }
    setLoading(true);

    setRefresh(!refresh);
  };

  const renderList = () => {
    return Object.keys(emissoesPorDestino).map((programaFidel) => {
      return (
        <GroupRow key={programaFidel}>
          <HorizontalConteiner>
            <GroupCell>
              <CellHeader style={{ padding: '4px 0', margin: 0 }}>
                Programa de Fidelidade
              </CellHeader>
              {programaFidel}
            </GroupCell>

            <Row style={{ background: colors.gray800 }}>
              <CellHeader style={{ flex: 0.3 }}>Origem</CellHeader>
              <CellHeader style={{ flex: 0.3 }}>Programa</CellHeader>
              <CellHeader style={{ flex: 0.2 }}>Milhas trecho</CellHeader>
              <CellHeader style={{ flex: 0.2 }}>Janela Op. (meses)</CellHeader>
              <CellHeader style={{ flex: 0.2 }}>Emissões</CellHeader>
            </Row>

            {emissoesPorDestino[programaFidel].map((item, index) => (
              <Row
                key={`${item.cidade_origem}-${item.programa_fidel}`}
                zebrado={index % 2 === 0}>
                <Cell style={{ flex: 0.3 }}>{item.cidade_origem}</Cell>
                <Cell style={{ flex: 0.3 }}>{item.programa_fidel}</Cell>
                <Cell style={{ flex: 0.2 }}>
                  {item.milhas_trecho_formatted}
                </Cell>
                <Cell style={{ flex: 0.2 }}>
                  {getSugestaoPesquisaTooltip({
                    meses_divulg_voo: item.meses_divulg_voo,
                    sugestaoPesquisaFormatted: item.sugestaoPesquisaFormatted,
                    key: item.key,
                  })}
                </Cell>
                <Cell style={{ flex: 0.2 }}>{item.count_emissoes}</Cell>
              </Row>
            ))}
          </HorizontalConteiner>
        </GroupRow>
      );
    });
  };

  return (
    <MainContainer>
      <PreventSelectionContainer>
        <EsclarecimentoDadosTimeline />

        <Title>Timeline - Por Programa de Fidelidade</Title>

        <Header>
          <FormWrapper style={{ flex: 1 }}>
            <Form>
              <FieldGroup>
                <Field flex="none">
                  <Title>Opções de busca</Title>
                </Field>
              </FieldGroup>

              <FieldGroup style={{ flex: 1 }}>
                <Field>
                  {/* <label htmlFor="internacional">Tipo de emissão</label> */}
                  <Select
                    // ref={refInternacional}
                    name="internacional"
                    // isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Tipo"
                    // defaultValue={{ value: '', label: '' }}
                    options={[
                      { value: true, label: 'Internacional' },
                      { value: false, label: 'Nacional' },
                    ]}
                    onChange={(selectedOption) =>
                      setInternacional(
                        selectedOption ? selectedOption.value : undefined
                      )
                    }
                    theme={theme}
                    styles={styles}
                  />
                </Field>

                <Field>
                  <Select
                    // ref={refClasse}
                    name="classe"
                    // isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Classe"
                    // defaultValue={{
                    //   value: classe,
                    //   label: classesVoo[classe].nomeCurto,
                    // }}
                    options={classesOptions}
                    onChange={(selectedOption) =>
                      setClasse(
                        selectedOption ? selectedOption.value : undefined
                      )
                    }
                    theme={theme}
                    styles={styles}
                  />
                </Field>

                <Field>
                  <Select
                    // ref={refClasse}
                    name="destino_continente"
                    // isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Continente de destino"
                    // defaultValue={{
                    //   value: classe,
                    //   label: classesVoo[classe].nomeCurto,
                    // }}
                    options={[
                      { value: 'África', label: 'África' },
                      { value: 'América do Sul', label: 'América do Sul' },
                      { value: 'América do Norte', label: 'América do Norte' },
                      { value: 'Antártida', label: 'Antártida' },
                      { value: 'Ásia', label: 'Ásia' },
                      { value: 'Europa', label: 'Europa' },
                      { value: 'Oceania', label: 'Oceania' },
                    ]}
                    onChange={(selectedOption) =>
                      setContinenteDestino(
                        selectedOption ? selectedOption.value : undefined
                      )
                    }
                    theme={theme}
                    styles={styles}
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <InlineField flex="none">
                  <Checkbox
                    name="ativo"
                    label="Quando você quer viajar?"
                    onChange={() => setShowDataViagem((prev) => !prev)}
                  />
                </InlineField>
              </FieldGroup>

              {showDataViagem ? (
                <FieldGroup>
                  <Field flex="none">
                    <label htmlFor="data_viagem_ini">A partir de</label>
                    <Input
                      name="data_viagem_ini"
                      type="date"
                      autoComplete="off"
                      value={dataViagemIni || ''}
                      onChange={(e) => setDataViagemIni(e.target.value)}
                    />
                  </Field>

                  <Field flex="none">
                    <label htmlFor="data_viagem_fim">Até</label>
                    <Input
                      name="data_viagem_fim"
                      type="date"
                      autoComplete="off"
                      value={dataViagemFim || ''}
                      onChange={(e) => setDataViagemFim(e.target.value)}
                    />
                  </Field>
                </FieldGroup>
              ) : null}

              <FieldGroup style={{ justifyContent: 'center', marginTop: 16 }}>
                <Field flex="none">
                  <Button
                    primary
                    type="button"
                    onClick={handleExecFilter}
                    disabled={!consultarEnabled || loading}>
                    {loading ? (
                      <ThreeDots
                        height="22"
                        width="22"
                        radius="9"
                        color={colors.textLight}
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible
                      />
                    ) : (
                      <MagnifyingGlass
                        color={colors.textLight}
                        weight="light"
                        size={22}
                      />
                    )}
                    {/* <span>{loading ? 'Consultando...' : 'Consultar'}</span> */}
                    <span>Consultar</span>
                  </Button>
                </Field>
              </FieldGroup>
            </Form>
          </FormWrapper>
        </Header>

        {emissoes.length === 0 ? (
          // <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
          <NoDataTextContainer>
            {consultasRealizadas > 0 ? (
              <NoInfoToShow>Nenhuma informação a exibir.</NoInfoToShow>
            ) : (
              <NoDataText>
                Preencha os filtros acima para consultar os dados.
              </NoDataText>
            )}
          </NoDataTextContainer>
        ) : (
          <>
            {totalEmissoes && (
              <ResultTitle>{`Emissões: ${totalEmissoes}`}</ResultTitle>
            )}

            {renderList()}

            <Pagination
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
            />
          </>
        )}
      </PreventSelectionContainer>
    </MainContainer>
  );
}

export default TimelinePorProgramaFidel;
