import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  VideoContainer,
  InfoContainer,
  VideoWrapper,
  BlackContainer,
} from './styles';

function Video({ video, showVideoInfo }) {
  const { title, description, video_external_id, created_at_formatted } = video;

  return (
    <Container>
      <BlackContainer>
        <VideoWrapper>
          <VideoContainer>
            <iframe
              title={title}
              id={`panda-${video_external_id}`}
              src={`https://player-vz-6625ada8-3c4.tv.pandavideo.com.br/embed/?v=${video_external_id}&playOpensFullscreenNative=false`}
              style={{
                border: 'none',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              // allow="accelerometer;gyroscope;encrypted-media;picture-in-picture"
              // allowfullscreen="true"
              allowFullScreen
              width="100%"
              height="100%"
              // fetchpriority="high"
            />
          </VideoContainer>
        </VideoWrapper>
      </BlackContainer>

      {showVideoInfo && (
        <InfoContainer>
          <strong>{description || title}</strong>
          {created_at_formatted && (
            <span>Publicado em {created_at_formatted}</span>
          )}
        </InfoContainer>
      )}
    </Container>
  );
}

Video.propTypes = {
  video: PropTypes.shape().isRequired,
  showVideoInfo: PropTypes.bool,
};

Video.defaultProps = {
  showVideoInfo: true,
};

export { Video };
