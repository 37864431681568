import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { Form } from '@unform/web';
import Input from '~/components/Input';

import { MdDone, MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';
import 'react-tabs/style/react-tabs.css';
import { useSelector } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import colors from '~/styles/colors';

import { BaseContainer } from '~/components/BaseContainer';
import { FormHeader } from '~/components/FormHeader';
import { Button } from '~/components/Button';
import { Title } from '~/components/Title';
import { FormWrapper, FieldGroup, Field } from '~/components/Forms';

import checkPermission from '~/lib/checkPermission';
import getErrorMessage from '~/lib/getErrorMessage';
import { theme, styles } from '../../../config/reactSelect';

// import { } from './styles';

function CidadeForm({ location }) {
  const [cidade] = useState(location.cidade);
  const timerPais = useRef(null);
  const timerEstado = useRef(null);

  const [paisId, setPaisId] = useState(cidade && cidade.pais_id);
  const [estadoId, setEstadoId] = useState(cidade && cidade.estado_id);

  const isEditing = history.location.pathname === '/cidades/edit';

  /**
   * Permissions
   */
  const funcao = useSelector((state) => state.funcao.funcao);
  const denied = !isEditing && !checkPermission(funcao, 'cidades:create');

  useEffect(() => {
    /**
     * Redirect if permission denied
     */
    if (denied) {
      history.push('/denied');
      return;
    }

    /**
     * Go back on page refresh
     */
    if (isEditing && !cidade) {
      history.goBack();
    }
  }, [cidade, isEditing, denied]);

  /**
   * Async Select - Países
   */
  async function loadPaises(filter) {
    const response = await api.get('paises', {
      params: { q: filter },
    });

    const paises = response.data.map((pais) => {
      return { value: pais.id, label: pais.nome };
    });

    return paises;
  }

  const loadPaisOptions = (inputValue, callback) => {
    clearTimeout(timerPais.current);
    timerPais.current = setTimeout(async () => {
      callback(await loadPaises(inputValue));
    }, 600);
  };

  const handleChangePais = (selectedOptions) => {
    setPaisId(selectedOptions.value);
  };

  /**
   * Async Select - Estados
   */
  async function loadEstados(filter) {
    const response = await api.get('estados', {
      params: { q: filter },
    });

    const estados = response.data.map((estado) => {
      return { value: estado.id, label: estado.nome };
    });

    return estados;
  }

  const loadEstadoOptions = (inputValue, callback) => {
    clearTimeout(timerEstado.current);
    timerEstado.current = setTimeout(async () => {
      callback(await loadEstados(inputValue));
    }, 600);
  };

  const handleChangeEstado = (selectedOptions) => {
    setEstadoId(selectedOptions ? selectedOptions.value : null);
  };

  /**
   * Form
   */
  const handleClickBack = () => {
    history.goBack();
  };

  async function save({ pais_id, estado_id, nome }) {
    const payload = { pais_id, estado_id, nome };

    try {
      if (cidade) {
        await api.put(`cidades/${cidade.id}`, payload); // Edit
      } else {
        await api.post('cidades', payload); // Create
      }

      toast.success(
        `Cadastro ${isEditing ? 'alterado' : 'realizado'} com sucesso!`
      );

      return history.push('/cidades');
    } catch (err) {
      return toast.error(
        <div>
          Falha ao salvar! <br /> <br />
          {getErrorMessage(err)}
        </div>
      );
    }
  }

  const handleSubmit = ({ nome }) => {
    if (!isEditing && !checkPermission(funcao, 'cidades:create')) {
      return;
    }
    if (isEditing && !checkPermission(funcao, 'cidades:edit')) {
      return;
    }

    save({ pais_id: paisId, estado_id: estadoId, nome });
  };

  return (
    <BaseContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          initialData={{
            nome: cidade && cidade.nome,
          }}>
          <FormHeader>
            <Title>Cadastro de Cidade</Title>
            <div>
              <Button type="button" onClick={handleClickBack}>
                <MdArrowBack size={24} color={colors.iconLight} />
                <span>Voltar</span>
              </Button>
              <Button
                primary
                type="submit"
                disabled={
                  isEditing && !checkPermission(funcao, 'cidades:edit')
                }>
                <MdDone size={24} color={colors.iconLight} />
                <span>Salvar</span>
              </Button>
            </div>
          </FormHeader>

          <FieldGroup>
            <Field>
              <label htmlFor="pais">País</label>
              <AsyncSelect
                autoFocus
                name="pais"
                placeholder="Selecione o país..."
                cacheOptions
                loadOptions={loadPaisOptions}
                defaultOptions
                onChange={handleChangePais}
                defaultValue={
                  cidade &&
                  cidade.pais && {
                    value: cidade.pais.id,
                    label: cidade.pais.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="estado">Estado</label>
              <AsyncSelect
                isClearable
                name="estado"
                placeholder="Selecione o estado..."
                cacheOptions
                loadOptions={loadEstadoOptions}
                defaultOptions
                onChange={handleChangeEstado}
                defaultValue={
                  cidade &&
                  cidade.estado && {
                    value: cidade.estado.id,
                    label: cidade.estado.nome,
                  }
                }
                required
                theme={theme}
                styles={styles}
              />
            </Field>
          </FieldGroup>

          <FieldGroup>
            <Field>
              <label htmlFor="nome">Nome</label>
              <Input
                name="nome"
                type="text"
                maxLength="100"
                autoComplete="off"
              />
            </Field>
          </FieldGroup>
        </Form>
      </FormWrapper>
    </BaseContainer>
  );
}

CidadeForm.propTypes = {
  location: PropTypes.shape(),
};

CidadeForm.defaultProps = {
  location: null,
};

export default CidadeForm;
